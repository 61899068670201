import React, { useEffect, useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from 'react-bootstrap'

// Localization
import { useIntl } from 'react-intl'

const Form3 = (props) => {
  const { submitForm, success } = props
  const formikRef = useRef()
  const intl = useIntl()

  useEffect(() => {
    if (success) {
      formikRef.current.resetForm()
    }
  }, [success])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, intl.formatMessage({ id: 'contact_page.name_too_short' }))
      .max(100, intl.formatMessage({ id: 'contact_page.name_too_long' }))
      .required(intl.formatMessage({ id: 'contact_page.name_required' })),
    company: Yup.string()
      .min(2, intl.formatMessage({ id: 'contact_page.company_too_short' }))
      .max(100, intl.formatMessage({ id: 'contact_page.company_too_long' })),
    address: Yup.string()
      .min(5, intl.formatMessage({ id: 'contact_page.address_too_short' }))
      .max(50, intl.formatMessage({ id: 'contact_page.address_too_long' })),
    zip: Yup.string()
      .min(5, intl.formatMessage({ id: 'contact_page.zip_code_too_short' }))
      .max(5, intl.formatMessage({ id: 'contact_page.zip_code_too_long' }))
      .matches(/^[0-9]*$/, intl.formatMessage({ id: 'contact_page.zip_code_specials' })),
    city: Yup.string()
      .min(2, intl.formatMessage({ id: 'contact_page.town_too_short' }))
      .max(50, intl.formatMessage({ id: 'contact_page.town_too_long' }))
      .required(intl.formatMessage({ id: 'contact_page.name_required' })),
    phone: Yup.string()
      .min(6, intl.formatMessage({ id: 'contact_page.phone_number_too_short' }))
      .max(13, intl.formatMessage({ id: 'contact_page.phone_number_too_long' }))
      .matches(/^[+]?[0-9]*$/, intl.formatMessage({ id: 'contact_page.phone_number_specials' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'contact_page.email_invalid' }))
      .required(intl.formatMessage({ id: 'contact_page.required' })),
    retailer: Yup.string()
      .min(2, intl.formatMessage({ id: 'contact_page.retailer_too_short' }))
      .max(50, intl.formatMessage({ id: 'contact_page.retailer_too_long' })),
  })

  return (
    <div className='contactForm'>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          company: '',
          address: '',
          zip: '',
          city: '',
          phone: '',
          email: '',
          retailer: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(values)
        }}>
        {({ errors, touched }) => (
          <Form>
            <p style={{ margin: '30px auto 0 auto', maxWidth: '500px' }}>
              {intl.formatMessage({ id: 'contact_page.want_to_buy' })}
            </p>

            <label htmlFor='name'>{intl.formatMessage({ id: 'contact_page.name' })} *</label>
            <Field id='name' name='name' placeholder='...' />
            {errors.name && touched.name ? <p className='validation-error'>{errors.name}</p> : null}

            <label htmlFor='company'>{intl.formatMessage({ id: 'contact_page.company' })}</label>
            <Field id='company' name='company' placeholder='...' />
            {errors.company && touched.company ? (
              <p className='validation-error'>{errors.company}</p>
            ) : null}

            <label htmlFor='address'>{intl.formatMessage({ id: 'contact_page.address' })}</label>
            <Field id='address' name='address' placeholder='...' />
            {errors.address && touched.address ? (
              <p className='validation-error'>{errors.address}</p>
            ) : null}

            <label htmlFor='zip'>{intl.formatMessage({ id: 'contact_page.zip_code' })}</label>
            <Field id='zip' name='zip' placeholder='...' />
            {errors.zip && touched.zip ? <p className='validation-error'>{errors.zip}</p> : null}

            <label htmlFor='city'>{intl.formatMessage({ id: 'contact_page.city' })} *</label>
            <Field id='city' name='city' placeholder='...' />
            {errors.city && touched.city ? <p className='validation-error'>{errors.city}</p> : null}

            <label htmlFor='phone'>{intl.formatMessage({ id: 'contact_page.phone' })}</label>
            <Field id='phone' name='phone' placeholder='...' type='tel' />
            {errors.phone && touched.phone ? (
              <p className='validation-error'>{errors.phone}</p>
            ) : null}

            <label htmlFor='email'>{intl.formatMessage({ id: 'contact_page.email' })} *</label>
            <Field id='email' name='email' placeholder='esimerkki@esimerkki.fi' type='email' />
            {errors.email && touched.email ? (
              <p className='validation-error'>{errors.email}</p>
            ) : null}

            <p style={{ margin: '24px auto 16px auto' }}>
              {intl.formatMessage({ id: 'contact_page.i_already_have_retailer' })}
            </p>

            <label htmlFor='retailer'>{intl.formatMessage({ id: 'contact_page.retailer' })}</label>
            <Field id='retailer' name='retailer' placeholder='...' />
            {errors.retailer && touched.retailer ? (
              <p className='validation-error'>{errors.retailer}</p>
            ) : null}

            <p>{intl.formatMessage({ id: 'contact_page.required_fields' })}</p>

            <Button type='submit' variant='dark'>
              {intl.formatMessage({ id: 'contact_page.send' })}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Form3
