// React & Routing
import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'
import { actionProductSelect } from '../../redux/actions/action.product'

// HTML to PDF
import html2pdf from 'html2pdf.js'

// Template
import CardTemplate from './CardTemplate/CardTemplate'

// Bootstrap
import { Button } from 'react-bootstrap'
import { Spinner } from 'react-bootstrap'

// Utils
import { getBrandLogo } from '../../utils/getFunctions'
import proxy from '../../utils/proxy'

// Styling
import './CardEditor.scss'

const CardEditor = ({ getColor, getColorGroup, actionProductSelect, product: { selected, selectedLoadingDone }, utils}) => {
    const { productID, urlLang } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    // For measurement table
    const upperRef = useRef(null)
    const lowerRef = useRef(null)

    // For product colors
    const leftSectionRef = useRef(null)
    const rightSectionRef = useRef(null)
    const colorsRef = useRef(null)

    const [productState, setProductState] = useState({
      loading: true,
      id: '',
      tuoteKoodi: '',
      tuoteNimi: '',
      brandi: '',
      pakkauskoko: '',
      tuoteRyhma: '',
      alaRyhma: '',
      malli: '',
      genre: '',
      kaulaAukko: '',
      materiaali: '',
      istutus: '',
      painoLuokka: '',
      samankaltaiset: '',
      tuoteperhe: '',
      yksikko: '',
      kuvaus: '',
      kevytKuvaus: '',
      kayttovinkki: undefined,
      aktiivinen: undefined,
      variRyhmat: [],
      variNimet: {},
      variaatiot: [],
      liitteet: [],
      kuvat: [],
    })

    const [logoURL, setLogoURL] = useState('')
    const [colorStrings, setColorStrings] = useState(undefined)
    const [colorThumbs, setColorThumbs] = useState(null)
    const [measurementURL, setMeasurementURL] = useState('')
    const [downloadedAtLeastOnce, setDownloadedAtLeastOnce] = useState(false)

    const { loading, tuoteKoodi, brandi, kuvaus } = productState

    const getUpperHeight = () => {
      if (upperRef.current) {
        const height = upperRef.current.offsetHeight
        return height
      }
    }

    const getLowerHeight = () => {
      if (lowerRef.current) {
        const height = lowerRef.current.offsetHeight
        return height
      }
    }

    // const getLeftSectionHeight = () => {
    //   if (leftSectionRef.current) {
    //     const height = leftSectionRef.current.offsetHeight
    //     return height
    //   }
    // }

    // const getRightSectionHeight = () => {
    //   if (rightSectionRef.current) {
    //     const height = rightSectionRef.current.offsetHeight
    //     return height
    //   }
    // }

    const getLeftProductImagesHeight = () => {
      const pi0 = document.getElementById('pi0')
      const pi1 = document.getElementById('pi1')
    
      let height = 0
    
      if (pi0) {
        height += pi0.offsetHeight
      }
    
      if (pi1) {
        height += pi1.offsetHeight
      }
    
      return height
    }

    const getColorsHeight = () => {
      if (colorsRef.current) {
        const height = colorsRef.current.offsetHeight
        return height
      }
    }

    const splitTooltip = (string) => {
      const part2 = string.slice(string.indexOf('('))
      const part1 = string.slice(0, string.indexOf('(')).trim()
      return [part1, part2]
    }

    const resetPageCutOffChanges = () => {
      // Mittataulukko
      const upperElement = document.getElementById('upper-half')
      if (upperElement) upperElement.style.marginBottom = 0
      
      // Editable content fields
      const editableTitle = document.querySelector('#editable-title-div')
      if (editableTitle) editableTitle.style.marginTop = 0

      // Right col extra image
      const imageElement = document.getElementById('pi2')
      if (imageElement) imageElement.style.marginTop = 0

      // Color buttons
      const colorButtons = document.getElementsByClassName('color-thumb')
      colorButtons.forEach((el) => el.style.marginTop = 0)
      const continueTexts = document.querySelectorAll('.continue-text')
      continueTexts.forEach((el) => {
        el.parentNode.removeChild(el)
      })
    }

    const checkPageCutOffForMeasurementTable = () => {
      const templateUpperHeight = getUpperHeight()
      const templateLowerHeight = getLowerHeight()
      const contentHeight = templateUpperHeight + templateLowerHeight
      const a4_height = 1138 - 20 /* 20 pixels removed for margins */

      const contentFitsOnePage = contentHeight < a4_height
      const contentAlreadyOnPageTwo = templateUpperHeight > a4_height

      const contentFitsTwoPages = contentHeight < (a4_height * 2)
      const contentAlreadyOnPageThree = templateUpperHeight > (a4_height * 2)

      if (!contentFitsTwoPages && !contentAlreadyOnPageThree) {
        /* Mittataulukko menee osittain tokalle ja osittain kolmannelle sivulle.
        Siirretään se kokonaan kolmannelle sivulle marginaalilla. */
        const extraMarginNeeded = (a4_height * 2) - templateUpperHeight + 16
        const upperElement = document.getElementById('upper-half')
        upperElement.style.marginBottom = `${extraMarginNeeded}px`
      } else if (!contentFitsOnePage && !contentAlreadyOnPageTwo) {
        /* Mittataulukko menee osittain ekalle ja osittain tokalle sivulle.
        Siirretään se kokonaan toiselle sivulle marginaalilla. */
        const extraMarginNeeded = a4_height - templateUpperHeight + 16
        const upperElement = document.getElementById('upper-half')
        upperElement.style.marginBottom = `${extraMarginNeeded}px`
      }
    }

    const checkPageCutOffForEditableContent = () => {
      const productImagesHeightOnTheLeft = getLeftProductImagesHeight()
      const editableTitle = document.querySelector('#editable-title-div')
      const editableTextArea = document.querySelector('#editable-content-div')
      const editableContentsHeight = editableTextArea.offsetHeight + editableTitle.offsetHeight
      const a4_height = 1138 - 20 /* 20 pixels removed for margins */
      const contentFitsOnePage = productImagesHeightOnTheLeft + editableContentsHeight < a4_height

      if (!contentFitsOnePage) {
        /* Muokattavat kentät menee osittain ekalle ja osittain tokalle sivulle.
        Siirretään ne kokonaan toiselle sivulle marginaalilla. */
        const extraMarginNeeded = a4_height - productImagesHeightOnTheLeft + 16
        editableTitle.style.marginTop = `${extraMarginNeeded}px`
      }
    }

    const checkPageCutOffForRightExtraImage = () => {
      const imageElement = document.getElementById('pi2')
      if (!imageElement) {
        return
      }

      const imageHeight = imageElement.offsetHeight
      const imageSiblings = Array.from(imageElement.parentNode.children).filter(child => child !== imageElement)
      const productInfoHeight = imageSiblings.reduce((sum, child) => sum + child.offsetHeight, 0)
      const a4_height = 1138 - 20 /* 20 pixels removed for margins */
      const contentFitsOnePage = productInfoHeight + imageHeight < a4_height

      if (!contentFitsOnePage) {
        /* Ekstrakuva menee osittain ekalle ja osittain tokalle sivulle.
        Siirretään kuva kokonaan toiselle sivulle marginaalilla. */
        const extraMarginNeeded = a4_height - productInfoHeight + 16
        imageElement.style.marginTop = `${extraMarginNeeded}px`
      }
    }

    const checkPageCutOffForColors = () => {
      const colorThumbsEl = document.getElementById('color-thumbs')
      const colorsHeight = getColorsHeight()
      const imageSiblings = Array.from(colorThumbsEl.parentNode.children).filter(child => child !== colorThumbsEl)
      const productInfoHeight = imageSiblings.reduce((sum, child) => sum + child.offsetHeight, 0)
      const sectionHeight = colorsHeight + productInfoHeight
      const a4_height = 1138 - 20 /* 20 pixels removed for margins */
      const contentFitsOnePage = sectionHeight < a4_height
      const contentAlreadyOnMultiplePages = productInfoHeight > a4_height

      if (!contentFitsOnePage && !contentAlreadyOnMultiplePages) {
        /* Värikuvat menee osittain ekalle ja osittain tokalle sivulle.
        Siirretään viimeisen kokonaisen rivin jälkeen loput toiselle sivulle marginaalilla. */
        const colorAmount = colorStrings?.length
        if (colorAmount && !isNaN(colorAmount)) {
          const itemsPerRow = 5
          const colorRows = Math.ceil(colorAmount / itemsPerRow)
          const averageRowHeight = colorsHeight / colorRows
          const spaceForColorsOnFirstPage = a4_height - productInfoHeight
          const rowsForFirstPage = Math.floor(spaceForColorsOnFirstPage / averageRowHeight) - 1

          const lastFiveElementsOnFirstPage = []
          for (let i = (rowsForFirstPage + 1) * itemsPerRow; i >= rowsForFirstPage * itemsPerRow + 1; i--) {
            lastFiveElementsOnFirstPage.push(`color-thumb-${i}`)
          }

          let firstPageColorRowsHeightSum = 0
          for (let i = 1; i <= rowsForFirstPage * itemsPerRow; i += itemsPerRow) {
            const element = document.getElementById(`color-thumb-${i}`)
            if (element) {
              const elementHeight = parseInt(element.offsetHeight)
              firstPageColorRowsHeightSum += Math.ceil(elementHeight)
            }
          }

          for (let colorThumbId of lastFiveElementsOnFirstPage) {
            const marginTopNeeded = spaceForColorsOnFirstPage - firstPageColorRowsHeightSum - 20 + 24
            // Käytettävissä olevan tilan ja ekan sivun väririvien erotus,
            // sekä erotetaan "Jatkuu seuraavalla sivulla" elementin korkeus (20px) ja lisätään sivureunus (24px)
            // Vanha toteutus marginille = averageRowHeight * 2 + 20
            const element = document.getElementById(colorThumbId)
            if (element) {
              element.style.marginTop = `${marginTopNeeded}px`
            }
          }

          const targetElementId = lastFiveElementsOnFirstPage[lastFiveElementsOnFirstPage.length - 1]
          const targetElement = document.getElementById(targetElementId)
          const continueElement = document.createElement('span')
          continueElement.textContent = 'Jatkuu seuraavalla sivulla'
          continueElement.classList.add('continue-text')
          if (targetElement) {
            targetElement.insertAdjacentElement('beforebegin', continueElement)
          }
        }
      
      }
    }

    const generatePDF = async () => {

      const editableTitleDiv = document.querySelector('#editable-title-div')
      const editableContentDiv = document.querySelector('#editable-content-div')
      const defaultContent = 'Muokkaa klikkaamalla...'

      if (editableTitleDiv.innerHTML === defaultContent) {
        editableTitleDiv.textContent = ''
        editableTitleDiv.style.height = '0'
        editableTitleDiv.style.margin = '0'
        editableTitleDiv.style.padding = '0'
      }
      if (editableContentDiv.innerHTML === defaultContent) {
        editableContentDiv.textContent = ''
        editableContentDiv.style.height = '0'
        editableContentDiv.style.margin = '0'
        editableContentDiv.style.padding = '0'
      }

      const pageDivider = document.getElementById('page-divider')
      pageDivider.style.display = 'none'

      if (downloadedAtLeastOnce) {
        resetPageCutOffChanges()
      }
      if (usingEditorImages && imageSourceExtraRight) {
        checkPageCutOffForRightExtraImage()
      }
      if (!usingEditorImages) {
        checkPageCutOffForColors()
      }
      checkPageCutOffForEditableContent()
      checkPageCutOffForMeasurementTable()

      const element = document.getElementById('card-template')

      const opt = {
        margin: 0,
        filename: `Tuotekortti_${productID}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 5, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      }

      const images = element.querySelectorAll('img')
      images.forEach(img => {
        const image = new Image()
        image.crossOrigin = 'anonymous'
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const context = canvas.getContext('2d')
          context.drawImage(img, 0, 0, img.width, img.height)
          const base64 = canvas.toDataURL('image/jpeg')
          image.src = base64
        }
      })

      // Wait for images to load (optional, depending on your content)
      await Promise.all(Array.from(images).map(img => img.decode()))
      await html2pdf().set(opt).from(element).save()
      setDownloadedAtLeastOnce(true)
      pageDivider.style.display = 'block'
    }

  useEffect(() => {
    if (!selectedLoadingDone) {
        actionProductSelect(productID)
    }

    if (selectedLoadingDone && selected) {
        let filteredVariaatiot = []
        if (location.search === '?outlet=true') {
          filteredVariaatiot = selected.variaatiot.filter((el) => el.tagit.outlet)
        } else {
          filteredVariaatiot = selected.variaatiot.filter((el) => !el.tagit.outlet)
        }

        setProductState({
            ...productState,
            loading: false,
            id: selected._id,
            tuoteKoodi: selected.tuoteKoodi,
            tuoteNimi: selected.tuoteNimi,
            brandi: selected.brandi,
            pakkauskoko: selected.pakkauskoko,
            tuoteRyhma: selected.tuoteRyhma,
            alaRyhma: selected.alaRyhma,
            malli: selected.malli,
            genre: selected.genre,
            kaulaAukko: selected.kaulaAukko,
            materiaali: selected.materiaali,
            istutus: selected.istutus,
            painoLuokka: selected.painoLuokka,
            samankaltaiset: selected.samankaltaiset,
            tuoteperhe: selected.tuoteperhe,
            yksikko: selected.yksikko,
            kuvaus: selected.kuvaus,
            kevytKuvaus: selected.kevytKuvaus,
            kayttovinkki: selected.kayttovinkki,
            aktiivinen: selected.aktiivinen,
            variRyhmat: selected.variRyhmat,
            variNimet: selected.variNimet,
            variaatiot: filteredVariaatiot,
            liitteet: selected.liitteet,
            kuvat: selected.kuvat,
        })

        const activeVariants = filteredVariaatiot.filter((el) => el.aktiivinen)
        const colors = activeVariants.map((el) => el.vari)
        const colorsUnique = [...new Set(colors)]

        setColorStrings(colorsUnique)
        setLogoURL(getBrandLogo(selected.brandi))
        setMeasurementURL(`${proxy}/images/${selected.tuoteKoodi}/${selected.tuoteKoodi}-mittataulukko.jpg`)
    }

    if (selectedLoadingDone && !selected) {
      navigate(`/${urlLang}/404`, { replace: true })
    }
    // eslint-disable-next-line
  }, [selectedLoadingDone])

  useEffect(() => {
    if (colorStrings) {
      const colorObjects = colorStrings.map((el) => ({
        code: el,
        tooltip: splitTooltip(getColor(el, brandi)),
        group: getColorGroup(el, brandi),
      }))

      const colorOrder = [
        'Musta',
        'Harmaa',
        'Valkoinen',
        'Ruskea',
        'Keltainen',
        'Oranssi',
        'Punainen',
        'Vaaleanpunainen',
        'Violetti',
        'Sininen',
        'Vihreä',
        'Moniväri',
      ]

      const sortedColors = colorObjects.sort((a, b) => {
        return colorOrder.indexOf(a.group) - colorOrder.indexOf(b.group)
      })

      const colorThumbs = sortedColors.map((el, index) => (
          <div id={`color-thumb-${index + 1}`} key={el.code} className='color-thumb'>
            <img
              src={`${proxy}/images/${tuoteKoodi}/${tuoteKoodi}-${el.code.replace(
                '/',
                '_'
              )}-thumbnail.jpg`}
              alt={el.tooltip[0]}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null //estä loop
                currentTarget.src = `${proxy}/images/placeholder.jpg`
              }}></img>
              <div className='span-container'>
                <span>{el.tooltip[0]}</span>
                <span>{el.tooltip[1]}</span>
              </div>
          </div>
      ))
      setColorThumbs(colorThumbs)
    }
    // eslint-disable-next-line
  }, [colorStrings, utils])

  const [imageSource, setImageSource] = useState(`${proxy}/images/${productID}/${productID}-paakuva.jpg`)
  const [imageSourceExtraLeft, setImageSourceExtraLeft] = useState(null)
  const [imageSourceExtraRight, setImageSourceExtraRight] = useState(null)
  const [usingEditorImages, setUsingEditorImages] = useState(false)

  useEffect(() => {
    if (location.hash && (location.hash !== '#main' && location.hash !== '#main-outlet')) {
      const proxyToUse = proxy.includes('localhost') ? 'http://localhost' : proxy
      const images = location.hash.slice(1).split(',').map(image => `${proxyToUse}/images/editor/${image}`)
      setImageSource(images[0] || `${proxy}/images/${productID}/${productID}-paakuva.jpg`)
      setImageSourceExtraLeft(images[1] || null)
      setImageSourceExtraRight(images[2] || null)
      setUsingEditorImages(true)
    }
    // eslint-disable-next-line
  }, [location])

  const checkImageExists = async (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(url)
    image.onerror = () => resolve(null)
    image.src = url
  })
}

useEffect(() => {
  const checkMeasurementURL = async () => {
    const exists = await checkImageExists(measurementURL)
    if (!exists) {
      setMeasurementURL('')
    }
  }

  checkMeasurementURL()
}, [measurementURL])

  return (
    <div className='card-editor'>
        <Helmet>
            <title>Tuotekortti {productID} | dc-collection</title>
        </Helmet>
        {loading ?
        <Spinner animation='border' id='customSpinner' /> :
        <>
            <CardTemplate
            id={productID}
            imageURL={imageSource}
            extraImageLeftURL={imageSourceExtraLeft}
            extraImageRightURL={imageSourceExtraRight}
            logoURL={logoURL}
            measurementURL={measurementURL}
            colorThumbs={colorThumbs}
            description={kuvaus}
            upperRef={upperRef}
            lowerRef={lowerRef}
            leftSectionRef={leftSectionRef}
            rightSectionRef={rightSectionRef}
            colorsRef={colorsRef}
            usingEditorImages={usingEditorImages}
            />
            <div className='button-container'>
                <Button className='save-pdf' variant='primary' onClick={generatePDF}>
                Lataa PDF
                </Button>
            </div>
        </>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  product: state.product,
  utils: state.utils,
})

const reduxActions = {
  actionProductSelect,
}

export default connect(mapStateToProps, reduxActions)(CardEditor)
