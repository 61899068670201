import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import './CookieAlert.scss'

// Localization
import { FormattedMessage } from 'react-intl'

const CookieAlert = (props) => {

  const acceptCookies = () => {
    localStorage.setItem('cookies-accepted', true)
    props.setHideCookieAlert(true)
  }

  return (
    <div className='CookieAlert'>
      <p>
        <FormattedMessage id='general.cookieNotice' />
        {' '}
        <Link to='/data-protection'>
          <FormattedMessage id='general.cookiesReadMore' />
        </Link>
      </p>
      <Button variant='primary' onClick={acceptCookies}>
        OK
      </Button>
    </div>
  )
}

export default CookieAlert
