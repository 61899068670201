const getTranslation = (key, subKey, langCode, propText) => {
  const validLangCode = langCode && langCode.length === 2 ? langCode : 'fi'

  const languageIndexes = {
    fi: 0,
    en: 1,
  }

  const translations = {
    auth: {
        passwordLinkSent: [`Salasanan nollaus linkki on lähetetty sähköpostiisi.`, `Password reset link has been sent to your email.`],
        passwordsDoNotMatch: [`Uudet salasanat eivät täsmää.`, `New passwords do not match.`],
        passwordTooShort: [`Salasana on liian lyhyt.`, `Password is too short.`],
        passwordChanged: [`Salasana vaihdettu onnistuneesti.`, `Password changed successfully.`],
        favoritesAdd: [`Tuote ${propText} lisätty suosikkeihin.`, `Product ${propText} added to favorites.`],
        favoritesRemove: [`Tuote ${propText} poistettu suosikeista.`, `Product ${propText} removed from favorites.`],
        addressAdded: [`Osoite lisätty osoiteluetteloon.`, `Address added to address list.`],
        addressRemoved: [`Osoite poistettu osoiteluettelosta.`, `Address removed from address list.`],
    },
    cart: {
        loadError: [`Ostoskorin noudossa tapahtui virhe.`, `Error occurred in cart.`],
        cartWasNotLatest: [`Huom! Ostoskorin sisältö on muuttunut.`, `Attention! Cart content has changed.`],
        cartUpdated: [`Ostoskori päivitetty.`, `Cart updated.`],
        allIdsAreInvalid: [`Tiedoston kaikki tuotekoodit (id) ovat virheellisiä tai poistuneita tuotteita.`, `All product codes (id) in the file are invalid or removed products.`],
        confirmRemoveProduct: [`Poistetaanko tuote "${propText}" ostoskorista?`, `Remove product "${propText}" from cart?`],
      },
    contact: {
        thanks: [`Kiitos yhteydenotostasi! Olemme yhteydessä mahdollisimman pian.`, `Thank you for contacting us! We will contact you as soon as possible.`],
        error: [`Tapahtui virhe. Yritä myöhemmin uudelleen.`, `An error occurred. Please try again later.`],
    },
    order: {
        sendError: [`Tilauksen lähetyksessä tapahtui virhe. Yritä uudelleen.`, `An error occurred while placing the order. Please try again.`],
    },
    search: {
      searchError: [`Hakusi ei tuottanut tuloksia. Yritä uudelleen.`, `Your search did not return any results. Please try again.`],
    },
    checkout: {
      cartFileTooLarge: [`Tiedosto on liian suuri. Valitse tiedosto, joka on enintään 5MB.`, `The file is too large. Select a file that is 5MB or less.`],
      wrongFileType: [`Väärä tiedostotyyppi: ${propText}. Sallittuja tiedostotyyppejä ovat CSV ja JSON.`, `Wrong file type: ${propText}. Allowed file types are CSV and JSON.`],
      fileTypeNotSupported: [`Tiedoston tyyppi ei ole tuettu. Sallittuja tiedostotyyppejä ovat CSV ja JSON.`, `File type not supported. Allowed file types are CSV and JSON.`],
      fileReadError: [`Tiedoston lukeminen epäonnistui. Varmista, että tiedosto on oikeassa muodossa.`, `File reading failed. Make sure the file is in the correct format.`],
      fileNotArray: [`Tiedoston sisältö ei ole taulukko. Varmista, että tiedosto on oikeassa muodossa.`, `File content is not an array. Make sure the file is in the correct format.`],
      fileIdNotString: [`Tiedoston sisältö ei ole oikeassa muodossa. "id"-kentän tulee olla merkkijono.`, `File content is not in the correct format. "id" field must be a string.`],
      fileQtyNotNumber: [`Tiedoston sisältö ei ole oikeassa muodossa. "qty"-kentän tulee olla positiivinen numero.`, `File content is not in the correct format. "qty" field must be a positive number.`],
    }
  }

  const translation = translations[key]?.[subKey]?.[languageIndexes[validLangCode]]

  return translation ? translation : ''
}

export default getTranslation
