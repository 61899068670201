// React & Routing
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'

// Bootstrap
import Carousel from 'react-bootstrap/Carousel'

// Styling
import './Carousel.scss'

// Utils
import proxy from '../../utils/proxy'

const ControlledCarousel = ({ campaign: { all, allLoadingDone } }) => {
  const [index, setIndex] = useState(0)
  const [carouselItems, setCarouselItems] = useState(undefined)
  const navigate = useNavigate()
  const { urlLang } = useParams()

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  const goTo = (url) => {
    navigate(url)
  }

  useEffect(() => {
    if (allLoadingDone && all.length > 0) {
      const items = all
        .filter((x) => x.image_frontpage === true)
        .sort((a, b) => (a.line > b.line ? 1 : -1))
        .map((el) => (
          <Carousel.Item key={el.cid}>
            <img
              className='d-block w-100'
              src={`${proxy}/images/campaigns/campaign_${el.cid}_frontpage.jpg`}
              onClick={() => goTo(`/${urlLang}/campaign/${el.cid}`)}
              alt=''
            />
          </Carousel.Item>
        ))
      setCarouselItems(items)
    }
    // eslint-disable-next-line
  }, [all, allLoadingDone])

  return (
    <Carousel id='mainBannerCarousel' activeIndex={index} onSelect={handleSelect}>
      {carouselItems ? carouselItems : null}
    </Carousel>
  )
}

const mapStateToProps = (state) => ({
  campaign: state.campaign,
})

export default connect(mapStateToProps)(ControlledCarousel)
