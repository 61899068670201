// React & Routing
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'

// Bootstrap
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

// Components
import LanguageToggle from '../LanguageToggle/LanguageToggle'

// Localization
import { FormattedMessage } from 'react-intl'

//Utils
import proxy from '../../utils/proxy'
import close from '../../images/close-solid.svg'

// Styling
import './MobileMenu.scss'

const MobileMenu = (props) => {
  const { isAuthenticated, menuOpen, setMenuOpen, isMobile, popoverLogin, actionLogout, languages, lang, setLang } = props
  const filterQuery = '?page=1&sort=Nimi (A-Ö)&price=0,0&filter={}'

  const [open1000, setOpen1000] = useState(false)
  const [open1100, setOpen1100] = useState(false)
  const [open1200, setOpen1200] = useState(false)
  const [open1300, setOpen1300] = useState(false)
  const [open1400, setOpen1400] = useState(false)
  const [open1500, setOpen1500] = useState(false)
  const [open1600, setOpen1600] = useState(false)

  const [open2000, setOpen2000] = useState(false)
  const [open2100, setOpen2100] = useState(false)
  const [open2200, setOpen2200] = useState(false)
  const [open2300, setOpen2300] = useState(false)
  const [open2400, setOpen2400] = useState(false)
  const [open2500, setOpen2500] = useState(false)
  const [open2600, setOpen2600] = useState(false)

  const [open3000, setOpen3000] = useState(false)
  const [open3100, setOpen3100] = useState(false)
  const [open3200, setOpen3200] = useState(false)
  const [open3300, setOpen3300] = useState(false)
  const [open3400, setOpen3400] = useState(false)
  const [open3500, setOpen3500] = useState(false)
  const [open3600, setOpen3600] = useState(false)

  const [open4000, setOpen4000] = useState(false)

  const [open5000, setOpen5000] = useState(false)
  const [open5100, setOpen5100] = useState(false)
  const [open5200, setOpen5200] = useState(false)
  const [open5300, setOpen5300] = useState(false)
  const [open5400, setOpen5400] = useState(false)
  const [open5500, setOpen5500] = useState(false)

  const [open6000, setOpen6000] = useState(false)

  const [open0000, setOpen0000] = useState(false)
  const [open0100, setOpen0100] = useState(false)
  const [open0200, setOpen0200] = useState(false)
  const [open0300, setOpen0300] = useState(false)
  const [open0400, setOpen0400] = useState(false)
  const [open0500, setOpen0500] = useState(false)
  const [open0600, setOpen0600] = useState(false)

  useEffect(() => {
    if (!menuOpen) {
      setOpen1000(false)
      setOpen2000(false)
      setOpen3000(false)
      setOpen4000(false)
      setOpen5000(false)
      setOpen6000(false)
      setOpen0000(false)
    }
  }, [menuOpen])

  useEffect(() => {
    if (!open1000) {
      setOpen1100(false)
      setOpen1200(false)
      setOpen1300(false)
      setOpen1400(false)
      setOpen1500(false)
      setOpen1600(false)
    }
    if (!open2000) {
      setOpen2100(false)
      setOpen2200(false)
      setOpen2300(false)
      setOpen2400(false)
      setOpen2500(false)
      setOpen2600(false)
    }
    if (!open3000) {
      setOpen3100(false)
      setOpen3200(false)
      setOpen3300(false)
      setOpen3400(false)
      setOpen3500(false)
      setOpen3600(false)
    }
    if (!open5000) {
      setOpen5100(false)
      setOpen5200(false)
      setOpen5300(false)
      setOpen5400(false)
      setOpen5500(false)
    }
    if (!open0000) {
      setOpen0100(false)
      setOpen0200(false)
      setOpen0300(false)
      setOpen0400(false)
      setOpen0500(false)
      setOpen0600(false)
    }
  }, [open1000, open2000, open3000, open5000, open0000])

  const closeMenuAndLogout = () => {
    setMenuOpen(false)
    actionLogout()
  }

  const menuNaiset = (
    <>
      <div className='mNavRow mLevel1'>
        <Link to={`/browse/1000${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.women" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen1000(!open1000)
            setOpen2000(false)
            setOpen3000(false)
            setOpen4000(false)
            setOpen5000(false)
            setOpen6000(false)
            setOpen0000(false)
          }}>
          <p>
            <strong>{open1000 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel2' hidden={!open1000}>
        <Link to={`/browse/1100${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shirts" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen1100(!open1100)
            setOpen1200(false)
            setOpen1300(false)
            setOpen1400(false)
            setOpen1500(false)
            setOpen1600(false)
          }}>
          <p>
            <strong>{open1100 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1100}>
        <Link to={`/browse/1101${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.t_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1100}>
        <Link to={`/browse/1102${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.polo_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1100}>
        <Link to={`/browse/1103${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.dress_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1100}>
        <Link to={`/browse/1104${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.knitwear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open1000}>
        <Link to={`/browse/1200${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_shirts_short" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen1200(!open1200)
            setOpen1100(false)
            setOpen1300(false)
            setOpen1400(false)
            setOpen1500(false)
            setOpen1600(false)
          }}>
          <p>
            <strong>{open1200 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1200}>
        <Link to={`/browse/1201${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_shirts_long" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1200}>
        <Link to={`/browse/1202${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_hoodies" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1200}>
        <Link to={`/browse/1203${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.zip_hoodies" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1200}>
        <Link to={`/browse/1204${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open1000}>
        <Link to={`/browse/1300${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.jackets" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen1300(!open1300)
            setOpen1100(false)
            setOpen1200(false)
            setOpen1400(false)
            setOpen1500(false)
            setOpen1600(false)
          }}>
          <p>
            <strong>{open1300 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1300}>
        <Link to={`/browse/1301${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.fleece_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1300}>
        <Link to={`/browse/1302${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.softshell" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1300}>
        <Link to={`/browse/1303${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.wind_and_shell_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1300}>
        <Link to={`/browse/1304${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.quilted_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1300}>
        <Link to={`/browse/1305${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.combination_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1300}>
        <Link to={`/browse/1306${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.business_and_citycoats" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1300}>
        <Link to={`/browse/1307${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.vests" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open1000}>
        <Link to={`/browse/1400${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bottoms" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen1400(!open1400)
            setOpen1100(false)
            setOpen1200(false)
            setOpen1300(false)
            setOpen1500(false)
            setOpen1600(false)
          }}>
          <p>
            <strong>{open1400 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1400}>
        <Link to={`/browse/1401${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.pants" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1400}>
        <Link to={`/browse/1402${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shorts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1400}>
        <Link to={`/browse/1403${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweatpants_and_tights" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1400}>
        <Link to={`/browse/1404${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.skirts_and_dresses" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1400}>
        <Link to={`/browse/1405${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.underwear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open1000}>
        <Link to={`/browse/1500${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen1500(!open1500)
            setOpen1100(false)
            setOpen1200(false)
            setOpen1300(false)
            setOpen1400(false)
            setOpen1600(false)
          }}>
          <p>
            <strong>{open1500 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1500}>
        <Link to={`/browse/1501${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_t_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1500}>
        <Link to={`/browse/1502${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_polo_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1500}>
        <Link to={`/browse/1503${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_shorts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1500}>
        <Link to={`/browse/1504${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_pants" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1500}>
        <Link to={`/browse/1505${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1500}>
        <Link to={`/browse/1506${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_accessories" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1500}>
        <Link to={`/browse/1507${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open1000}>
        <Link to={`/browse/1600${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.accessories" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen1600(!open1600)
            setOpen1100(false)
            setOpen1200(false)
            setOpen1300(false)
            setOpen1400(false)
            setOpen1500(false)
          }}>
          <p>
            <strong>{open1600 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1601${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.headgear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1602${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.gloves" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1603${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.scarfs" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1604${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.belts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1605${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.socks" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1606${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.aprons" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1607${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.rain_equipment" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1608${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bath_items" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1609${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.blankets_and_other_sidelines" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open1600}>
        <Link to={`/browse/1610${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shoes" /></p>
        </Link>
      </div>
    </>
  )

  const menuMiehet = (
    <>
      <div className='mNavRow mLevel1'>
        <Link to={`/browse/2000${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.men" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen2000(!open2000)
            setOpen1000(false)
            setOpen3000(false)
            setOpen4000(false)
            setOpen5000(false)
            setOpen6000(false)
            setOpen0000(false)
          }}>
          <p>
            <strong>{open2000 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel2' hidden={!open2000}>
        <Link to={`/browse/2100${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shirts" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen2100(!open2100)
            setOpen2200(false)
            setOpen2300(false)
            setOpen2400(false)
            setOpen2500(false)
            setOpen2600(false)
          }}>
          <p>
            <strong>{open2100 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2100}>
        <Link to={`/browse/2101${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.t_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2100}>
        <Link to={`/browse/2102${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.polo_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2100}>
        <Link to={`/browse/2103${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.dress_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2100}>
        <Link to={`/browse/2104${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.knitwear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open2000}>
        <Link to={`/browse/2200${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_shirts_short" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen2200(!open2200)
            setOpen2100(false)
            setOpen2300(false)
            setOpen2400(false)
            setOpen2500(false)
            setOpen2600(false)
          }}>
          <p>
            <strong>{open2200 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2200}>
        <Link to={`/browse/2201${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_shirts_long" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2200}>
        <Link to={`/browse/2202${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_hoodies" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2200}>
        <Link to={`/browse/2203${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.zip_hoodies" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2200}>
        <Link to={`/browse/2204${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open2000}>
        <Link to={`/browse/2300${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.jackets" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen2300(!open2300)
            setOpen2100(false)
            setOpen2200(false)
            setOpen2400(false)
            setOpen2500(false)
            setOpen2600(false)
          }}>
          <p>
            <strong>{open2300 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2300}>
        <Link to={`/browse/2301${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.fleece_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2300}>
        <Link to={`/browse/2302${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.softshell" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2300}>
        <Link to={`/browse/2303${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.wind_and_shell_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2300}>
        <Link to={`/browse/2304${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.quilted_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2300}>
        <Link to={`/browse/2305${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.combination_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2300}>
        <Link to={`/browse/2306${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.business_and_citycoats" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2300}>
        <Link to={`/browse/2307${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.vests" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open2000}>
        <Link to={`/browse/2400${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bottoms" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen2400(!open2400)
            setOpen2100(false)
            setOpen2200(false)
            setOpen2300(false)
            setOpen2500(false)
            setOpen2600(false)
          }}>
          <p>
            <strong>{open2400 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2400}>
        <Link to={`/browse/2401${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.pants" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2400}>
        <Link to={`/browse/2402${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shorts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2400}>
        <Link to={`/browse/2403${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweatpants_and_tights" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2400}>
        <Link to={`/browse/2404${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.skirts_and_dresses" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2400}>
        <Link to={`/browse/2405${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.underwear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open2000}>
        <Link to={`/browse/2500${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen2500(!open2500)
            setOpen2100(false)
            setOpen2200(false)
            setOpen2300(false)
            setOpen2400(false)
            setOpen2600(false)
          }}>
          <p>
            <strong>{open2500 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2500}>
        <Link to={`/browse/2501${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_t_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2500}>
        <Link to={`/browse/2502${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_polo_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2500}>
        <Link to={`/browse/2503${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_shorts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2500}>
        <Link to={`/browse/2504${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_pants" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2500}>
        <Link to={`/browse/2505${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2500}>
        <Link to={`/browse/2506${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_accessories" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2500}>
        <Link to={`/browse/2507${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open2000}>
        <Link to={`/browse/2600${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.accessories" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen2600(!open2600)
            setOpen2100(false)
            setOpen2200(false)
            setOpen2300(false)
            setOpen2400(false)
            setOpen2500(false)
          }}>
          <p>
            <strong>{open2600 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2601${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.headgear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2602${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.gloves" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2603${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.scarfs" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2604${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.belts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2605${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.socks" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2606${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.aprons" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2607${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.rain_equipment" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2608${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bath_items" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2609${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.blankets_and_other_sidelines" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open2600}>
        <Link to={`/browse/2610${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shoes" /></p>
        </Link>
      </div>
    </>
  )

  const menuLapset = (
    <>
      <div className='mNavRow mLevel1'>
        <Link to={`/browse/3000${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.kids" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen3000(!open3000)
            setOpen1000(false)
            setOpen2000(false)
            setOpen4000(false)
            setOpen5000(false)
            setOpen6000(false)
            setOpen0000(false)
          }}>
          <p>
            <strong>{open3000 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel2' hidden={!open3000}>
        <Link to={`/browse/3100${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shirts" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen3100(!open3100)
            setOpen3200(false)
            setOpen3300(false)
            setOpen3400(false)
            setOpen3500(false)
            setOpen3600(false)
          }}>
          <p>
            <strong>{open3100 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3100}>
        <Link to={`/browse/3101${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.t_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3100}>
        <Link to={`/browse/3102${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.polo_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3100}>
        <Link to={`/browse/3103${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.dress_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3100}>
        <Link to={`/browse/3104${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.knitwear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open3000}>
        <Link to={`/browse/3200${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_shirts_short" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen3200(!open3200)
            setOpen3100(false)
            setOpen3300(false)
            setOpen3400(false)
            setOpen3500(false)
            setOpen3600(false)
          }}>
          <p>
            <strong>{open3200 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3200}>
        <Link to={`/browse/3201${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_shirts_long" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3200}>
        <Link to={`/browse/3202${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_hoodies" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3200}>
        <Link to={`/browse/3203${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.zip_hoodies" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3200}>
        <Link to={`/browse/3204${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open3000}>
        <Link to={`/browse/3300${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.jackets" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen3300(!open3300)
            setOpen3100(false)
            setOpen3200(false)
            setOpen3400(false)
            setOpen3500(false)
            setOpen3600(false)
          }}>
          <p>
            <strong>{open3300 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3300}>
        <Link to={`/browse/3301${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.fleece_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3300}>
        <Link to={`/browse/3302${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.softshell" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3300}>
        <Link to={`/browse/3303${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.wind_and_shell_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3300}>
        <Link to={`/browse/3304${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.quilted_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3300}>
        <Link to={`/browse/3305${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.combination_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3300}>
        <Link to={`/browse/3306${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.business_and_citycoats" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3300}>
        <Link to={`/browse/3307${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.vests" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open3000}>
        <Link to={`/browse/3400${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bottoms" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen3400(!open3400)
            setOpen3100(false)
            setOpen3200(false)
            setOpen3300(false)
            setOpen3500(false)
            setOpen3600(false)
          }}>
          <p>
            <strong>{open3400 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3400}>
        <Link to={`/browse/3401${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.pants" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3400}>
        <Link to={`/browse/3402${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shorts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3400}>
        <Link to={`/browse/3403${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweatpants_and_tights" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3400}>
        <Link to={`/browse/3404${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.skirts_and_dresses" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3400}>
        <Link to={`/browse/3405${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.underwear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open3000}>
        <Link to={`/browse/3500${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen3500(!open3500)
            setOpen3100(false)
            setOpen3200(false)
            setOpen3300(false)
            setOpen3400(false)
            setOpen3600(false)
          }}>
          <p>
            <strong>{open3500 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3500}>
        <Link to={`/browse/3501${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_t_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3500}>
        <Link to={`/browse/3502${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_polo_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3500}>
        <Link to={`/browse/3503${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_shorts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3500}>
        <Link to={`/browse/3504${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_pants" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3500}>
        <Link to={`/browse/3505${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3500}>
        <Link to={`/browse/3506${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_accessories" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3500}>
        <Link to={`/browse/3507${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open3000}>
        <Link to={`/browse/3600${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.accessories" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen3600(!open3600)
            setOpen3100(false)
            setOpen3200(false)
            setOpen3300(false)
            setOpen3400(false)
            setOpen3500(false)
          }}>
          <p>
            <strong>{open3600 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3601${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.headgear" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3602${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.gloves" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3603${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.scarfs" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3604${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.belts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3605${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.socks" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3606${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.aprons" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3607${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.rain_equipment" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3608${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bath_items" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3609${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.blankets_and_other_sidelines" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open3600}>
        <Link to={`/browse/3610${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shoes" /></p>
        </Link>
      </div>
    </>
  )

  const menuLaukut = (
    <>
      <div className='mNavRow mLevel1'>
        <Link to={`/browse/4000${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bags" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen4000(!open4000)
            setOpen1000(false)
            setOpen2000(false)
            setOpen3000(false)
            setOpen5000(false)
            setOpen6000(false)
            setOpen0000(false)
          }}>
          <p>
            <strong>{open4000 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4001${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shopping_bags" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4002${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.purses_and_giftbags" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4003${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.document_and_brochurebags" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4004${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.computer_bags" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4005${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shoulder_and_fanny_pack" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4006${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.backpacks" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4007${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sport_and_travel_bags" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4008${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.portmanteaus" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4009${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.makeup_and_toiletbags" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4010${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.coolers" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open4000}>
        <Link to={`/browse/4011${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.big_bags" /></p>
        </Link>
      </div>
    </>
  )

  const menuTyovaatteetNew = (
    <>
      <div className='mNavRow mLevel1'>
        <Link to={`/browse/5000${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.working_clothes" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen5000(!open5000)
            setOpen1000(false)
            setOpen2000(false)
            setOpen3000(false)
            setOpen4000(false)
            setOpen6000(false)
            setOpen0000(false)
          }}>
          <p>
            <strong>{open5000 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel2' hidden={!open5000}>
        <Link to={`/browse/5100${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.hi_vis" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen5100(!open5100)
            setOpen5200(false)
            setOpen5300(false)
            setOpen5400(false)
            setOpen5500(false)
          }}>
          <p>
            <strong>{open5100 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5100}>
        <Link to={`/browse/5101${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5100}>
        <Link to={`/browse/5105${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_shirts_short" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5100}>
        <Link to={`/browse/5102${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5100}>
        <Link to={`/browse/5103${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bottoms" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5100}>
        <Link to={`/browse/5104${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.headgear_and_accessories" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open5000}>
        <Link to={`/browse/5200${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.construction_and_industry" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen5200(!open5200)
            setOpen5100(false)
            setOpen5300(false)
            setOpen5400(false)
            setOpen5500(false)
          }}>
          <p>
            <strong>{open5200 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5200}>
        <Link to={`/browse/5201${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shirts" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5200}>
        <Link to={`/browse/5202${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.sweat_shirts_short" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5200}>
        <Link to={`/browse/5203${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.jackets" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5200}>
        <Link to={`/browse/5204${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bottoms" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5200}>
        <Link to={`/browse/5205${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shoes" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5200}>
        <Link to={`/browse/5206${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.headgear_and_accessories" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open5000}>
        <Link to={`/browse/5300${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.accomodation_and_restaurant" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen5300(!open5300)
            setOpen5100(false)
            setOpen5200(false)
            setOpen5400(false)
            setOpen5500(false)
          }}>
          <p>
            <strong>{open5300 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5300}>
        <Link to={`/browse/5301${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.tops" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5300}>
        <Link to={`/browse/5302${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bottoms" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5300}>
        <Link to={`/browse/5303${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.aprons" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5300}>
        <Link to={`/browse/5304${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shoes" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5300}>
        <Link to={`/browse/5305${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.headgear_and_accessories" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open5000}>
        <Link to={`/browse/5400${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.health_and_care" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen5400(!open5400)
            setOpen5100(false)
            setOpen5200(false)
            setOpen5300(false)
            setOpen5500(false)
          }}>
          <p>
            <strong>{open5400 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5400}>
        <Link to={`/browse/5401${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.tops" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5400}>
        <Link to={`/browse/5402${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.bottoms" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5400}>
        <Link to={`/browse/5403${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.aprons" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5400}>
        <Link to={`/browse/5404${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shoes" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5400}>
        <Link to={`/browse/5405${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.headgear_and_accessories" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open5000}>
        <Link to={`/browse/5500${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.classified" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen5500(!open5500)
            setOpen5100(false)
            setOpen5200(false)
            setOpen5300(false)
            setOpen5400(false)
          }}>
          <p>
            <strong>{open5500 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5500}>
        <Link to={`/browse/5501${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.visible" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5500}>
        <Link to={`/browse/5502${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.antistatic" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5500}>
        <Link to={`/browse/5503${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.fire_and_welding_protected" /></p>
        </Link>
      </div>
      
      <div className='mNavRow mLevel3' hidden={!open5500}>
        <Link to={`/browse/5504${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.cold_and_rain_protected" /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open5500}>
        <Link to={`/browse/5505${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.safety_footwear" /></p>
        </Link>
      </div>
    </>
  )

  // const menuTyövaatteet = (
  //   <>
  //     <div className='mNavRow mLevel1'>
  //       <Link to={`/browse/5000${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Työvaatteet</p>
  //       </Link>
  //       <Button
  //         variant='outline-dark'
  //         onClick={() => {
  //           setOpen5000(!open5000)
  //           setOpen1000(false)
  //           setOpen2000(false)
  //           setOpen3000(false)
  //           setOpen4000(false)
  //           setOpen6000(false)
  //           setOpen0000(false)
  //         }}>
  //         <p>
  //           <strong>{open5000 ? '-' : '+'}</strong>
  //         </p>
  //       </Button>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5001${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>T-paidat</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5002${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Pikeet</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5003${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Collegepaidat</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5004${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Collegehupparit</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5005${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Vetoketjuhupparit</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5006${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Työhousut</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5007${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Kauluspaidat</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5008${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Collegetakit</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5009${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Takit ja liivit</p>
  //       </Link>
  //     </div>

  //     <div className='mNavRow mLevel2' hidden={!open5000}>
  //       <Link to={`/browse/5010${filterQuery}`} onClick={() => setMenuOpen(false)}>
  //         <p>Oheistuotteet</p>
  //       </Link>
  //     </div>
  //   </>
  // )

  const menuTuotemerkit = (
    <>
      <div className='mNavRow mLevel1'>
        <Link to={`/browse/6000${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.brands" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen6000(!open6000)
            setOpen1000(false)
            setOpen2000(false)
            setOpen3000(false)
            setOpen4000(false)
            setOpen5000(false)
            setOpen0000(false)
          }}>
          <p>
            <strong>{open6000 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6001${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>B&C</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6002${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Bella+Canvas</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6003${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>bugOff</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6004${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Cobalt Fusion</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6005${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Cobalt Gear</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6024${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Cobalt Uusio</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6022${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>EXCD</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6006${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Gildan</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6019${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Jack&Maker</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6007${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>K-Up</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6008${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Kariban</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6020${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Kariban Premium</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6009${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Kimood</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6016${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Mukua</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2 ns-extra-class' hidden={!open6000}>
        <Link to={`/browse/6021${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Native Spirit</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6010${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Penduick</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6011${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Petrol</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6012${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Proact</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6013${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Promodoro</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6014${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Stormtech</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6017${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>V-Pro</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6018${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>Velilla</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6015${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>WK</p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open6000}>
        <Link to={`/browse/6023${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p>X.O</p>
        </Link>
      </div>
    </>
  )

  const menuTuoteryhmät = (
    <>
      <div className='mNavRow mLevel1'>
        {/* <Link to="/browse/0000" onClick={() => setMenuOpen(false)}> */}
        <p
          style={{
            textTransform: 'uppercase',
            fontWeight: '700',
            margin: '6px 0',
          }}
          onClick={() => {
            setOpen0000(!open0000)
            setOpen1000(false)
            setOpen2000(false)
            setOpen3000(false)
            setOpen4000(false)
            setOpen5000(false)
            setOpen6000(false)
          }}>
          <FormattedMessage id="categories.product_groups" />
        </p>
        {/* </Link> */}
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen0000(!open0000)
            setOpen1000(false)
            setOpen2000(false)
            setOpen3000(false)
            setOpen4000(false)
            setOpen5000(false)
            setOpen6000(false)
          }}>
          <p>
            <strong>{open0000 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel2' hidden={!open0000}>
        <Link to={`/browse/0100${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id="categories.shirts" /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen0100(!open0100)
            setOpen0200(false)
            setOpen0300(false)
            setOpen0400(false)
            setOpen0500(false)
            setOpen0600(false)
          }}>
          <p>
            <strong>{open0100 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0100}>
        <Link to={`/browse/0101${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.t_shirts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0100}>
        <Link to={`/browse/0102${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.polo_shirts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0100}>
        <Link to={`/browse/0103${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.dress_shirts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0100}>
        <Link to={`/browse/0104${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.knitwear' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open0000}>
        <Link to={`/browse/0200${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sweat_shirts_short' /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen0200(!open0200)
            setOpen0100(false)
            setOpen0300(false)
            setOpen0400(false)
            setOpen0500(false)
            setOpen0600(false)
          }}>
          <p>
            <strong>{open0200 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0200}>
        <Link to={`/browse/0201${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sweat_shirts_long' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0200}>
        <Link to={`/browse/0202${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sweat_hoodies' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0200}>
        <Link to={`/browse/0203${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.zip_hoodies' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0200}>
        <Link to={`/browse/0204${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sweat_jackets' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open0000}>
        <Link to={`/browse/0300${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.jackets' /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen0300(!open0300)
            setOpen0100(false)
            setOpen0200(false)
            setOpen0400(false)
            setOpen0500(false)
            setOpen0600(false)
          }}>
          <p>
            <strong>{open0300 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0300}>
        <Link to={`/browse/0301${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.fleece_jackets' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0300}>
        <Link to={`/browse/0302${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.softshell' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0300}>
        <Link to={`/browse/0303${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.wind_and_shell_jackets' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0300}>
        <Link to={`/browse/0304${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.quilted_jackets' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0300}>
        <Link to={`/browse/0305${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.combination_jackets' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0300}>
        <Link to={`/browse/0306${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.business_and_citycoats' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0300}>
        <Link to={`/browse/0307${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.vests' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open0000}>
        <Link to={`/browse/0400${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.bottoms' /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen0400(!open0400)
            setOpen0100(false)
            setOpen0200(false)
            setOpen0300(false)
            setOpen0500(false)
            setOpen0600(false)
          }}>
          <p>
            <strong>{open0400 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0400}>
        <Link to={`/browse/0401${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.pants' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0400}>
        <Link to={`/browse/0402${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.shorts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0400}>
        <Link to={`/browse/0403${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sweatpants_and_tights' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0400}>
        <Link to={`/browse/0404${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.skirts_and_dresses' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0400}>
        <Link to={`/browse/0405${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.underwear' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open0000}>
        <Link to={`/browse/0500${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sport' /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen0500(!open0500)
            setOpen0100(false)
            setOpen0200(false)
            setOpen0300(false)
            setOpen0400(false)
            setOpen0600(false)
          }}>
          <p>
            <strong>{open0500 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0500}>
        <Link to={`/browse/0501${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sport_t_shirts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0500}>
        <Link to={`/browse/0502${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sport_polo_shirts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0500}>
        <Link to={`/browse/0503${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sport_shorts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0500}>
        <Link to={`/browse/0504${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sport_pants' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0500}>
        <Link to={`/browse/0505${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sport_jackets' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0500}>
        <Link to={`/browse/0506${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sport_accessories' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0500}>
        <Link to={`/browse/0507${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.sport_shirts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel2' hidden={!open0000}>
        <Link to={`/browse/0600${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.accessories' /></p>
        </Link>
        <Button
          variant='outline-dark'
          onClick={() => {
            setOpen0600(!open0600)
            setOpen0100(false)
            setOpen0200(false)
            setOpen0300(false)
            setOpen0400(false)
            setOpen0500(false)
          }}>
          <p>
            <strong>{open0600 ? '-' : '+'}</strong>
          </p>
        </Button>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0601${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.headgear' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0602${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.gloves' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0603${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.scarfs' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0604${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.belts' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0605${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.socks' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0606${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.aprons' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0607${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.rain_equipment' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0608${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.bath_items' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0609${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.blankets_and_other_sidelines' /></p>
        </Link>
      </div>

      <div className='mNavRow mLevel3' hidden={!open0600}>
        <Link to={`/browse/0610${filterQuery}`} onClick={() => setMenuOpen(false)}>
          <p><FormattedMessage id='categories.shoes' /></p>
        </Link>
      </div>
    </>
  )

  const menuEco = (
    <div className='mNavRow mLevel1'>
      <Link to={`/browse/eco${filterQuery}`} onClick={() => setMenuOpen(false)}>
        <p><FormattedMessage id='categories.eco' /></p>
      </Link>
      <Button variant='outline-dark' hidden>
        <p>
          <strong>+</strong>
        </p>
      </Button>
    </div>
  )

  const menuOutlet = (
    <div className='mNavRow mLevel1'>
      <Link to={`/outlet${filterQuery}`} onClick={() => setMenuOpen(false)}>
        <p><FormattedMessage id='categories.outlet' /></p>
      </Link>
      <Button variant='outline-dark' hidden>
        <p>
          <strong>+</strong>
        </p>
      </Button>
    </div>
  )

  const menuUutuus = (
    <div className='mNavRow mLevel1'>
      <Link to={`/browse/new${filterQuery}`} onClick={() => setMenuOpen(false)}>
        <p><FormattedMessage id='categories.new' /></p>
      </Link>
      <Button variant='outline-dark' hidden>
        <p>
          <strong>+</strong>
        </p>
      </Button>
    </div>
  )

  return (
    <div className='mobileMenu' hidden={!menuOpen}>
      <div className='mobileMenuTop'>
        <Button
          className='barsButton'
          variant='link'
          hidden={!isMobile}
          onClick={() => setMenuOpen(!menuOpen)}>
          <img src={close} alt='' width='24' height='24' />
        </Button>
        <h1>
          <strong>dc-collection</strong>
        </h1>
      </div>
      <div className='mobileMenuContent'>
        <LanguageToggle languages={languages} lang={lang} setLang={setLang} />
        {isAuthenticated ? (
          <Link to={`/account`} onClick={() => setMenuOpen(false)}>
            <Button variant='link'>
              <img
                src={`${proxy}/images/icons/user-circle-solid.svg`}
                alt=''
                className='toolbarIcon'
              />
              <FormattedMessage id='toolbar.my_account' />
            </Button>
          </Link>
        ) : null}
        <Link to={`/favorites`} onClick={() => setMenuOpen(false)}>
          <Button variant='link'>
            <img src={`${proxy}/images/icons/heart-solid.svg`} alt='' className='toolbarIcon' />
            <FormattedMessage id='toolbar.favorites' />
          </Button>
        </Link>
        {isAuthenticated ? (
          <Button variant='link' onClick={() => closeMenuAndLogout()}>
            <img
              src={`${proxy}/images/icons/sign-out-alt-solid.svg`}
              alt=''
              className='toolbarIcon'
            />
            <FormattedMessage id='toolbar.logout' />
          </Button>
        ) : (
          <OverlayTrigger trigger='click' placement='bottom' overlay={popoverLogin}>
            <Button variant='link'>
              <img
                src={`${proxy}/images/icons/sign-in-alt-solid.svg`}
                alt=''
                className='toolbarIcon'
              />
              <FormattedMessage id='toolbar.login' />
            </Button>
          </OverlayTrigger>
        )}
        <hr />

        {menuNaiset}
        {menuMiehet}
        {menuLapset}
        {menuLaukut}
        {/* {menuTyövaatteet} */}
        {menuTyovaatteetNew}
        {menuTuotemerkit}
        {menuTuoteryhmät}
        {menuEco}
        {menuOutlet}
        {menuUutuus}
      </div>
    </div>
  )
}

export default connect()(MobileMenu)
