import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Pagination from 'react-bootstrap/Pagination'
import { parseQuery } from '../../utils/getFunctions'
import { FormattedMessage } from 'react-intl'

const PaginationComponent = (props) => {
  const { source, id, pages, currentPage } = props
  const navigate = useNavigate()
  const { urlLang } = useParams()
  const [paginationElements, setPaginationElements] = useState([])

  useEffect(() => {
    const changePage = (n) => {
      const q = parseQuery(window.location.search)
      q.page = n
      const validatedId = encodeURIComponent(id.replace(/%/g, '~~pct~~'))
      if (source === 'outlet')
        navigate(`/${urlLang}/${source}?page=${q.page}&sort=${q.sort}&price=${q.price}&filter=${q.filter}`)
      else
        navigate(
          `/${urlLang}/${source}/${validatedId}?page=${q.page}&sort=${q.sort}&price=${q.price}&filter=${q.filter}`
        )
    }

    const array = []
    array.unshift(
      <Pagination.Prev
        key={'prev'}
        onClick={() => changePage(currentPage - 1)}
        disabled={currentPage === 1 ? true : false}
      />
    )
    if (pages > 5) {
      array.push(
        <Pagination.Item
          key={'first'}
          active={currentPage === 1 ? true : false}
          onClick={() => changePage(1)}>
          1
        </Pagination.Item>
      )
      if (currentPage - 3 > 1) {
        array.push(
          <Pagination.Ellipsis key={'ellipsis'} onClick={() => changePage(currentPage - 3)} />
        )
      }
      for (let n = currentPage - 2; n <= currentPage + 2; n++) {
        array.push(
          <Pagination.Item
            key={n}
            active={n === currentPage ? true : false}
            onClick={() => changePage(n)}
            hidden={n < 2 || n > pages - 1 ? true : false}>
            {n}
          </Pagination.Item>
        )
      }

      if (currentPage + 3 < pages) {
        array.push(
          <Pagination.Ellipsis key={'ellipsis2'} onClick={() => changePage(currentPage + 3)} />
        )
      }
      array.push(
        <Pagination.Item
          key={pages}
          active={pages === currentPage ? true : false}
          onClick={() => changePage(pages)}>
          {pages}
        </Pagination.Item>
      )
    } else {
      for (let n = 1; n <= pages; n++) {
        array.push(
          <Pagination.Item
            key={n}
            active={n === currentPage ? true : false}
            onClick={() => changePage(n)}>
            {n}
          </Pagination.Item>
        )
      }
    }
    array.push(
      <Pagination.Next
        key={'next'}
        onClick={() => changePage(currentPage + 1)}
        disabled={currentPage === pages ? true : false}
      />
    )
    setPaginationElements(array)
  }, [pages, currentPage, id, navigate, source])

  return (
    <>
      {paginationElements && paginationElements.length ? (
        <Pagination>
          <p><FormattedMessage id='general.page' />:</p>
          {paginationElements}
        </Pagination>
      ) : null}
    </>
  )
}

export default PaginationComponent
