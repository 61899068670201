import React from 'react'
import { Link } from 'react-router-dom'

// Bootstrap
import { Button } from 'react-bootstrap'

// Localization
import { FormattedMessage } from 'react-intl'

// Utils
import proxy from '../../utils/proxy'
import { formatStormtechCode } from '../../utils/getFunctions'
import './ItemButton.scss'

export const createItemButtons = (
  products,
  favorites,
  isFavorite,
  actionFavoritesAdd,
  actionFavoritesRemove,
  isAuthenticated,
  lang
) => {

  products.forEach(product => {
    let vanhatHinnat = product.variaatiot.map(variaatio => parseFloat(variaatio.vanhaHinta.replace(',', '.')))
    vanhatHinnat = vanhatHinnat.filter(vH => !isNaN(vH))
    vanhatHinnat.sort((a, b) => a - b)
    if (vanhatHinnat?.length) {
      product.alinVanhaHinta = vanhatHinnat[0].toFixed(2).replace('.', ',')
    } else {
      product.alinVanhaHinta = undefined
    }
  })
  const itemButtons = products.map((el) => (
    <div
      className='itemBtn animated fadeInUp'
      key={!el.isOutlet ? el.tuoteKoodi : `${el.tuoteKoodi}-outlet`}
      id={el.tuoteKoodi}>
      {!isFavorite && favorites && favorites.includes(el.tuoteKoodi) ? (
        <Button className='favoriteBtn' variant='link' disabled>
          <img src={`${proxy}/images/icons/heart-solid-black.svg`} alt='' />
        </Button>
      ) : null}
      {!isFavorite && ((favorites && !favorites.includes(el.tuoteKoodi)) || !favorites) ? (
        <Button
          className='favoriteBtn'
          variant='link'
          onClick={() => actionFavoritesAdd(el.tuoteKoodi, isAuthenticated)}>
          <img src={`${proxy}/images/icons/heart-regular-black.svg`} alt='' />
        </Button>
      ) : null}
      {isFavorite ? (
        <Button
          className='deleteBtn'
          variant='link'
          onClick={() => actionFavoritesRemove(el.tuoteKoodi, isAuthenticated)}>
          <img src={`${proxy}/images/icons/delete-solid.svg`} alt='' />
        </Button>
      ) : null}
      <Link
        className='itemBtnLink'
        key={!el.isOutlet ? el.tuoteKoodi : `${el.tuoteKoodi}-outlet`}
        to={el.isOutlet ? `/outlet-product/${el.tuoteKoodi}` : `/product/${el.tuoteKoodi}`}
        onClick={() =>
          localStorage.setItem(
            'scroll-memory',
            JSON.stringify({
              body: document.body.scrollHeight,
              prevPage: window.location.href,
              y: window.scrollY,
            })
          )
        }>
        <div className={el.uutuus || el.isOutlet ? 'ecoTag ecoMoveUp' : 'ecoTag'} hidden={!el.eco}>
          <p style={{ textTransform: 'uppercase' }}>
            <FormattedMessage id='categories.eco' />
          </p>
        </div>
        <div className='newTag' hidden={!el.uutuus}>
          <p style={{ textTransform: 'uppercase' }}>
            <FormattedMessage id='categories.new' />
          </p>
        </div>
        <div className='outletTag' hidden={!el.isOutlet}>
          <p style={{ textTransform: 'uppercase' }}>
            <FormattedMessage id='categories.outlet' />
          </p>
        </div>
        <div className='itemBtnImgContainer'>
          <img
            src={`${proxy}/images/${el.tuoteKoodi}/${el.tuoteKoodi}-paakuva-thumbnail.jpg`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null //estä loop
              currentTarget.src = `${proxy}/images/placeholder.jpg`
            }}
            alt=''
          />
        </div>
        <p>{el.brandi === 'Stormtech' ? formatStormtechCode(el.tuoteKoodi) : el.tuoteKoodi}</p>
        <p style={{ fontWeight: '650' }}>{lang.id === 'fi' ? el.tuoteNimi : el.tuoteNimi_en}</p>
        <p>
        {el.alinVanhaHinta ? 
          <span className='strikethrough'>
            {el.alinVanhaHinta}{' €'}
          </span>
          : null }
          <span style={el.alinVanhaHinta ? { color: 'red', marginLeft: '5px' } : {}}>
          {el.alinVanhaHinta ? '(' : null }
            {Math.min(...el.variaatiot.map((variaatio) => variaatio.ovh))
              .toFixed(2)
              .replace('.', ',')}{' €'}
          {el.alinVanhaHinta ? ')' : null }
          </span>

        </p>
      </Link>
    </div>
  ))
  return itemButtons
}
