import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import proxy from '../../utils/proxy'
import './LanguageToggle.scss'

const LanguageToggle = ({ languages, lang, setLang }) => {
  const [dropdownTitle, setDropdownTitle] = useState('')
  const [dropdownItems, setDropdownItems] = useState(null)

  useEffect(() => {
    if (lang) {
      const titleElement = (
        <>
          <img src={`${proxy}/images/icons/globe-africa-solid.svg`} alt='' className='globeIcon' />
          <p>{lang.id}</p>
        </>
      )
      setDropdownTitle(titleElement)
    }
  }, [lang])

  useEffect(() => {
    const elementList = languages.map((lang) => (
      <Dropdown.Item key={lang.id} onClick={() => setLang(lang)}>
        {lang.name} ({lang.id.toUpperCase()})
      </Dropdown.Item>
    ))
    setDropdownItems(elementList)
    // eslint-disable-next-line
  }, [languages])

  return (
    <DropdownButton className='LanguageToggle' variant='outline-dark' title={dropdownTitle} hidden={true}>
      {dropdownItems ? dropdownItems : null}
    </DropdownButton>
  )
}

export default LanguageToggle
