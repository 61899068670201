// React & Routing
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Styling
import { Row } from 'react-bootstrap'
import dcLogo from '../../images/dc-logo.png'
import './Campaigns.scss'

// Utils
import proxy from '../../utils/proxy'
import parse from 'html-react-parser'

const Campaigns = ({ campaign: { all, allLoadingDone }, display }) => {
  const [campaignItems, setCampaignItems] = useState(null)
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (allLoadingDone && all && all.length) {
      const typeOfCampaign = all.filter((x) => x.type === 'campaign')
      if (typeOfCampaign && typeOfCampaign.length) {
        const elements = typeOfCampaign
          .sort((a, b) => (a.line > b.line ? 1 : -1))
          .map((el) => (
            <Row key={el.cid}>
              <div className='campaignButton'>
                <div id='div1'>
                  <Link to={`/campaign/${el.cid}`}>
                    {el.image_main ? (
                      <img src={`${proxy}/images/campaigns/campaign_${el.cid}_main.jpg`} alt='' />
                    ) : (
                      <img src={dcLogo} alt='' />
                    )}
                  </Link>
                </div>
                <div id='div2'>
                  <Link to={`/campaign/${el.cid}`}>
                    {' '}
                    <h2>{el.name}</h2>
                    <p>{el.desc}</p>
                  </Link>
                </div>
              </div>
              <div id='div3'>
                <h6><FormattedMessage id="campaigns_page.attachments" /></h6>
                {el.attachments.map((item) => (
                  <a
                    key={item}
                    href={`${proxy}/attachments/campaigns/${el.cid}/${item}`}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {item}
                  </a>
                ))}
              </div>
            </Row>
          ))
        setCampaignItems(elements)
      } else {
        setCampaignItems(null)
      }
    }
    // eslint-disable-next-line
  }, [all, allLoadingDone])

  return (
    <div className='Campaigns'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'campaigns_page.title' })} | dc-collection</title>
      </Helmet>
      <h4>
        <strong><FormattedMessage id="campaigns_page.title" /></strong>
      </h4>
      {display && display.campaignInfo ? parse(display.campaignInfo) : null}
      {allLoadingDone && !campaignItems ? <p><FormattedMessage id="campaigns_page.no_active_campaigns" /></p> : null}
      <div className='CampaignsContainer'>
        <div className='CampaignButtonContainer'>{campaignItems}</div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  display: state.product.display,
})

export default connect(mapStateToProps)(Campaigns)
