// React & Routing
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Redux
import { connect } from 'react-redux'
import {
  actionChangePassword,
  actionResetPasswordState,
  actionAddressAdd,
  actionAddressRemove,
  actionResetAddressDone,
} from '../../redux/actions/action.auth'
import { actionOrderGetAll } from '../../redux/actions/action.order'

// Bootstrap & Components
import { Container, Row, Col, Table, Button, Form, Spinner, Modal } from 'react-bootstrap'
import OrderPagination from '../../components/OrderPagination/OrderPagination'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Utils
import proxy from '../../utils/proxy'

// Styling
import moment from 'moment'
import './Account.scss'
import emptyBox from '../../images/emptyBox.svg'
import eye from '../../images/eye-regular.svg'
import eyeSlash from '../../images/eye-slash-regular.svg'

const Account = ({
  user,
  addressAddDone,
  addressRemoveDone,
  pwChangeDone,
  isAuthenticated,
  loading,
  order,
  actionOrderGetAll,
  actionChangePassword,
  actionResetPasswordState,
  actionAddressAdd,
  actionAddressRemove,
  actionResetAddressDone,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { urlLang } = useParams()

  const formik = useFormik({
    initialValues: {
      orderCustomer: '',
      orderCustomer2: '',
      orderAddress: '',
      orderCity: '',
    },
    validationSchema: Yup.object().shape({
      orderCustomer: Yup.string()
        .required(intl.formatMessage({ id: 'forms.required' }))
        .max(100, intl.formatMessage({ id: 'forms.max_chars_100' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
      orderCustomer2: Yup.string()
        .max(100, intl.formatMessage({ id: 'forms.max_chars_100' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
      orderAddress: Yup.string()
        .required(intl.formatMessage({ id: 'forms.required' }))
        .max(100, intl.formatMessage({ id: 'forms.max_chars_100' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
      orderCity: Yup.string()
        .required(intl.formatMessage({ id: 'forms.required' }))
        .max(100, intl.formatMessage({ id: 'forms.max_chars_100' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
    }),
    onSubmit: () => {
      setSaveInProgress(true)
      actionAddressAdd(newAddress)
    },
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [ordersPerPage] = useState(20)
  const [currentOrders, setCurrentOrders] = useState(null)
  const [totalPages, setTotalPages] = useState(1)

  // Hae aktiivisen sivun tilaukset (A-B)
  const indexOfLastOrder = currentPage * ordersPerPage
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage

  useEffect(() => {
    if (!loading && !isAuthenticated) return navigate(`/${urlLang}`)
    // eslint-disable-next-line
  }, [isAuthenticated, loading])

  const [orders, setOrders] = useState(null)
  const [hidePwChange, toggleHidePwChange] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState('')
  const [saveInProgress, setSaveInProgress] = useState(false)
  const [invalidForm, setInvalidForm] = useState(true)
  const [newAddress, setNewAddress] = useState(undefined)
  const [addressOptions, setAddressOptions] = useState(null)
  const [pwData, setPwData] = useState({
    oldpassword: '',
    newpassword: '',
    newpassword2: '',
  })

  const paginate = (pageNumber) => {
    if (pageNumber === currentPage) {
      return
    } else {
      setOrders(null)
      setCurrentOrders(null)
      setCurrentPage(pageNumber)
    }
  }

  useEffect(() => {
    if (user && user.addressList) {
      if (user.addressList.length) {
        setAddressOptions(
          user.addressList.map((el, index) => (
            <option
              key={index} value={index}>{`${el.customer}, ${el.customer2}, ${el.address}, ${el.city}`}</option>
          ))
        )
      } else {
        setAddressOptions(null)
      }
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (user?.orders && !currentOrders) {
      setCurrentOrders(user?.orders?.slice(indexOfFirstOrder, indexOfLastOrder))
      setTotalPages(Math.ceil(user?.orders?.length / ordersPerPage))
    }
    // eslint-disable-next-line
  }, [user?.orders, currentOrders, currentPage])

  useEffect(() => {
    if (currentOrders) {
      actionOrderGetAll(currentOrders)
    }
    // eslint-disable-next-line
  }, [currentOrders])

  const statusKasittelyssa = <p style={{ color: '#ffa500', fontWeight: '600' }}><FormattedMessage id="account_page.status_processing" /></p>
  const statusOsatoimitettu = <p style={{ color: '#ffa500', fontWeight: '600' }}><FormattedMessage id="account_page.status_partial_delivery" /></p>
  const statusOsalaskutettu = <p style={{ color: '#ffa500', fontWeight: '600' }}><FormattedMessage id="account_page.status_partial_invoice" /></p>
  const statusLaskutettu = <p style={{ color: '#ffa500', fontWeight: '600' }}><FormattedMessage id="account_page.status_invoiced" /></p>
  const statusToimitettu = <p style={{ color: '#74c515', fontWeight: '600' }}><FormattedMessage id="account_page.status_delivered" /></p>
  const statusEiLoytynyt = <p style={{ color: 'red', fontWeight: '600' }}><FormattedMessage id="account_page.status_not_found" /></p>
  const goToOrder = (orderNumber) => {
    navigate(`/${urlLang}/order/${orderNumber}`)
  }

  useEffect(() => {
    if (order?.all?.length) {
      const list = order.all.map((el) => (
        el.date ?
        <tr key={el.number} onClick={() => goToOrder(el.number)}>
          <td>
            <p>{moment(el.date).format('DD.MM.YYYY')}</p>
          </td>
          <td>
            <p>{el.number}</p>
          </td>
          <td>
            <p>{el.mark}</p>
          </td>
          <td>
            <p>{el.customersOrderNumber}</p>
          </td>
          <td>
            <p>{parseFloat(el.totalExcludeTax).toFixed(2).replace('.', ',')} €</p>
          </td>
          <td>
            {parseInt(el.status) === 0 ? statusKasittelyssa : null}
            {parseInt(el.status) === 64 ? statusOsatoimitettu : null}
            {parseInt(el.status) === 16384 ? statusOsalaskutettu : null}
            {parseInt(el.status) === 2 ? statusLaskutettu : null}
            {parseInt(el.status) === 128 ? statusToimitettu : null}
          </td>
        </tr>
        :
        <tr key={el.number}>
        <td>
          <p>-</p>
        </td>
        <td>
          <p>{el.number}</p>
        </td>
        <td>
          <p>-</p>
        </td>
        <td>
          <p>-</p>
        </td>
        <td>
          <p>-</p>
        </td>
        <td>
          {statusEiLoytynyt}
        </td>
      </tr>
      ))
      setOrders(list)
    }
    // eslint-disable-next-line
  }, [order.all])

  useEffect(() => {
    if (pwChangeDone) {
      toggleHidePwChange(true)
      setPwData({
        oldpassword: '',
        newpassword: '',
        newpassword2: '',
      })
      actionResetPasswordState()
    }
    // eslint-disable-next-line
  }, [pwChangeDone])

  useEffect(() => {
    if (
      !isAuthenticated ||
      saveInProgress ||
      formik.errors.orderCustomer ||
      formik.errors.orderCustomer2 ||
      formik.errors.orderAddress ||
      formik.errors.orderCity
    ) {
      setInvalidForm(true)
    } else {
      setInvalidForm(false)
    }
  }, [isAuthenticated, saveInProgress, formik.errors])

  useEffect(() => {
    setNewAddress({
      customer: formik.values.orderCustomer,
      customer2: formik.values.orderCustomer2,
      address: formik.values.orderAddress,
      city: formik.values.orderCity,
    })
  }, [
    formik.values.orderCustomer,
    formik.values.orderCustomer2,
    formik.values.orderAddress,
    formik.values.orderCity,
  ])

  useEffect(() => {
    if (addressAddDone || addressRemoveDone) {
      formik.resetForm()
      setSaveInProgress(false)
      setSelectedAddress('')
      actionResetAddressDone()
    }
    // eslint-disable-next-line
  }, [addressAddDone, addressRemoveDone])

  const onChange = (e) => setPwData({ ...pwData, [e.target.name]: e.target.value })

  const onSubmit = (e) => {
    e.preventDefault()
    const { oldpassword, newpassword, newpassword2 } = pwData
    actionChangePassword({ oldpassword, newpassword, newpassword2 })
  }

  const [hidePw1, setHidePw1] = useState(true)
  const [hidePw2, setHidePw2] = useState(true)
  const [hidePw3, setHidePw3] = useState(true)

  const changePasswordComponent = (
    <div hidden={hidePwChange}>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label><FormattedMessage id="account_page.current_password" /></Form.Label>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Control
              name='oldpassword'
              type={hidePw1 ? 'password' : 'text'}
              autoComplete='current-password'
              placeholder='...'
              onChange={onChange}
            />
            {hidePw1 ? (
              <Button
                tabIndex='-1'
                variant='link'
                className='pw-eye'
                onClick={() => setHidePw1(false)}>
                <img src={eye} alt={intl.formatMessage({ id: 'toolbar.show_password' })} />
              </Button>
            ) : (
              <Button
                tabIndex='-1'
                variant='link'
                className='pw-eye'
                onClick={() => setHidePw1(true)}>
                <img src={eyeSlash} alt={intl.formatMessage({ id: 'toolbar.hide_password' })} />
              </Button>
            )}
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label><FormattedMessage id="account_page.new_password" /></Form.Label>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Control
              name='newpassword'
              type={hidePw2 ? 'password' : 'text'}
              autoComplete='new-password'
              placeholder='...'
              onChange={onChange}
            />
            {hidePw2 ? (
              <Button
                tabIndex='-1'
                variant='link'
                className='pw-eye'
                onClick={() => setHidePw2(false)}>
                <img src={eye} alt={intl.formatMessage({ id: 'toolbar.show_password' })} />
              </Button>
            ) : (
              <Button
                tabIndex='-1'
                variant='link'
                className='pw-eye'
                onClick={() => setHidePw2(true)}>
                <img src={eyeSlash} alt={intl.formatMessage({ id: 'toolbar.hide_password' })} />
              </Button>
            )}
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label><FormattedMessage id="account_page.new_password_again" /></Form.Label>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Control
              name='newpassword2'
              type={hidePw3 ? 'password' : 'text'}
              autoComplete='new-password'
              placeholder='...'
              onChange={onChange}
            />
            {hidePw3 ? (
              <Button
                tabIndex='-1'
                variant='link'
                className='pw-eye'
                onClick={() => setHidePw3(false)}>
                <img src={eye} alt={intl.formatMessage({ id: 'toolbar.show_password' })} />
              </Button>
            ) : (
              <Button
                tabIndex='-1'
                variant='link'
                className='pw-eye'
                onClick={() => setHidePw3(true)}>
                <img src={eyeSlash} alt={intl.formatMessage({ id: 'toolbar.hide_password' })} />
              </Button>
            )}
          </div>
        </Form.Group>
        <Button type='submit'><FormattedMessage id="account_page.change_password" /></Button>
      </Form>
    </div>
  )

  const addressForm = (
    <form onSubmit={formik.handleSubmit}>
      <Form.Group>
        <label htmlFor='orderCustomer'><FormattedMessage id="forms.order_customer" /></label>
        <input
          name='orderCustomer'
          placeholder='...'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.orderCustomer}
        />
        {formik.errors.orderCustomer && formik.touched.orderCustomer ? (
          <p className='validation-error'>{formik.errors.orderCustomer}</p>
        ) : null}
        <label htmlFor='orderCustomer'><FormattedMessage id="forms.order_customer2" /></label>
        <input
          name='orderCustomer2'
          placeholder='...'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.orderCustomer2}
        />
        {formik.errors.orderCustomer2 && formik.touched.orderCustomer2 ? (
          <p className='validation-error'>{formik.errors.orderCustomer2}</p>
        ) : null}

        <label htmlFor='orderAddress'><FormattedMessage id="forms.order_address" /></label>
        <input
          name='orderAddress'
          placeholder='...'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.orderAddress}
        />
        {formik.errors.orderAddress && formik.touched.orderAddress ? (
          <p className='validation-error'>{formik.errors.orderAddress}</p>
        ) : null}

        <label htmlFor='orderCity'><FormattedMessage id="forms.order_city" /></label>
        <input
          name='orderCity'
          placeholder='...'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.orderCity}
        />
        {formik.errors.orderCity && formik.touched.orderCity ? (
          <p className='validation-error'>{formik.errors.orderCity}</p>
        ) : null}
      </Form.Group>
      <Button
        variant='primary'
        type='submit'
        className={invalidForm ? 'disabledButton' : ''}
        disabled={invalidForm}
        style={{ margin: '20px auto 10px' }}>
        {saveInProgress ? (
          <>
            <FormattedMessage id="forms.saving" />
            <Spinner animation='border' variant='light' size='sm' />
          </>
        ) : (
          <FormattedMessage id="forms.save" />
        )}
      </Button>
    </form>
  )

  const viewAddress = (
    <>
      {selectedAddress && user && user.addressList && user.addressList.length ? (
        <div className='viewAddress'>
          <p style={{ marginTop: '3px' }}>
            <strong><FormattedMessage id="forms.order_customer" /></strong>
          </p>
          <p>
            {user.addressList[selectedAddress] ? user.addressList[selectedAddress].customer : ''}
          </p>

          <p style={{ marginTop: '20px' }}>
            <strong><FormattedMessage id="forms.order_customer2" /></strong>
          </p>
          <p>
            {user.addressList[selectedAddress] ? user.addressList[selectedAddress].customer2 : ''}
          </p>

          <p style={{ marginTop: '20px' }}>
            <strong><FormattedMessage id="forms.order_address" /></strong>
          </p>
          <p>
            {user.addressList[selectedAddress] ? user.addressList[selectedAddress].address : ''}
          </p>

          <p style={{ marginTop: '20px' }}>
            <strong><FormattedMessage id="forms.order_city" /></strong>
          </p>
          <p>{user.addressList[selectedAddress] ? user.addressList[selectedAddress].city : ''}</p>

          <Button
            variant='danger'
            style={{ margin: '20px auto 10px' }}
            onClick={() => actionAddressRemove(user.addressList[selectedAddress])}>
            <FormattedMessage id="account_page.remove_address" />
          </Button>
        </div>
      ) : null}
    </>
  )

  return (
    <div className='Account'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'account_page.title' })} | dc-collection</title>
      </Helmet>
      <Modal
        size='lg'
        className='addressListModal'
        centered
        backdrop='static'
        keyboard={true}
        show={modalShow}
        onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title><FormattedMessage id="account_page.address_list" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage id="account_page.address_list_description" />
          </p>
          <Form.Group>
            <Form.Label><FormattedMessage id="forms.order_address" /></Form.Label>
            <Form.Control
              name='selectedAddress'
              type='text'
              as='select'
              style={{ margin: '0 auto 35px' }}
              value={selectedAddress}
              onChange={(e) => setSelectedAddress(e.target.value)}>
              <option value={''}><FormattedMessage id="forms.add_new_address" /></option>
              {addressOptions}
            </Form.Control>
          </Form.Group>
          {selectedAddress ? viewAddress : addressForm}
        </Modal.Body>
      </Modal>
      <h1>
        <strong><FormattedMessage id="account_page.title" /></strong>
      </h1>

      <Container className='accountContainer'>
        <Row>
          <Col>
            <h4><FormattedMessage id="account_page.user" /></h4>
            <div className='colText'>
              <p>
                <strong><FormattedMessage id="account_page.name" />:</strong> {user.name}
              </p>
              <p>
                <strong><FormattedMessage id="account_page.email" />:</strong> {user.email}
              </p>
              <p>
                <strong><FormattedMessage id="account_page.account_created" />:</strong> {moment(user.registerDate).format('DD.MM.YYYY')}
              </p>
              <br />
              <p>
                <strong><FormattedMessage id="account_page.customer_number" />:</strong> {user.customerNumber}
              </p>
              <p>
                <strong><FormattedMessage id="account_page.general_discount_percent" />:</strong> {user.discount}
              </p>
              <Button variant='link' onClick={() => toggleHidePwChange(!hidePwChange)}>
                <p><FormattedMessage id="account_page.change_password" /></p>
                <img src={`${proxy}/images/icons/cog-solid.svg`} alt='' id='accountIcon' />
              </Button>
              {changePasswordComponent}
            </div>
          </Col>
          <Col>
            <h4><FormattedMessage id="account_page.billing_information" /></h4>
            <div className='colText'>
              <p>{user.invoiceCustomer}</p>
              <p>{user.invoiceCustomer2}</p>
              <p>{user.invoiceAddress}</p>
              <p>{user.invoiceCity}</p>
            </div>
            <h4><FormattedMessage id="account_page.delivery_information" /></h4>
            <div className='colText'>
              <p>{user.customer}</p>
              <p>{user.customer2}</p>
              <p>{user.address}</p>
              <p>{user.city}</p>
            </div>
            <Button variant='link' onClick={() => setModalShow(true)}>
              <p><FormattedMessage id="account_page.address_list" /></p>
              <img src={`${proxy}/images/icons/book-solid.svg`} alt='' id='accountIcon' />
            </Button>
          </Col>
        </Row>
      </Container>

      <Container className='orderContainer'>
        <div className='orderCol'>
          <h4><FormattedMessage id="account_page.orders" /></h4>
          {!order.all ? (
            <Spinner animation='border' id='customSpinner' style={{ margin: '2rem auto' }} />
          ) : null}
          {order.all && orders ? (
            <>
            <div style={{ width: "100%", maxWidth: "1076px", margin: "0 auto 1rem auto" }}>
              <p style={{ margin: '0', textAlign: 'left' }}><FormattedMessage id="account_page.orders_shown" /> {indexOfFirstOrder+1}-{indexOfLastOrder > user?.orders?.length ? user?.orders?.length : indexOfLastOrder} / {user?.orders?.length}.</p>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th><FormattedMessage id="account_page.order_made" /></th>
                  <th><FormattedMessage id="account_page.order_number" /></th>
                  <th><FormattedMessage id="account_page.mark" /></th>
                  <th><FormattedMessage id="account_page.customer_order_number" /></th>
                  <th><FormattedMessage id="account_page.sum" /></th>
                  <th><FormattedMessage id="account_page.status" /></th>
                </tr>
              </thead>
              <tbody>{orders}</tbody>
            </Table>
            <div style={{ width: "100%", maxWidth: "1076px", margin: "0 auto 1rem auto" }}>
              <p style={{ margin: '0', textAlign: 'left' }}><FormattedMessage id="account_page.orders_shown" /> {indexOfFirstOrder+1}-{indexOfLastOrder > user?.orders?.length ? user?.orders?.length : indexOfLastOrder} / {user?.orders?.length}.</p>
            </div>
            <div style={{ width: "100%", maxWidth: "1076px", margin: "0 auto" }}>
              <OrderPagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
              />
            </div></>
          ) : null}
          {order.all && !order.all.length ? (
            <>
              <h5><FormattedMessage id="account_page.no_orders" /></h5>
              <img
                src={emptyBox}
                alt='Tilaukset'
                width='80'
                height='80'
                style={{ margin: '16px auto 16px auto' }}
              />
            </>
          ) : null}
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  pwChangeDone: state.auth.pwChangeDone,
  order: state.order,
  addressAddDone: state.auth.addressAddDone,
  addressRemoveDone: state.auth.addressRemoveDone,
})

const reduxActions = {
  actionOrderGetAll,
  actionChangePassword,
  actionResetPasswordState,
  actionAddressAdd,
  actionAddressRemove,
  actionResetAddressDone,
}

export default connect(mapStateToProps, reduxActions)(Account)
