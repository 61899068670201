// React & Routing
import React from 'react'
import { Link } from 'react-router-dom'

// Components
import MultiCarouselBrands from '../MultiCarousel/MultiCarouselBrands'

// Bootstrap
import Col from 'react-bootstrap/Col'

// Localization
import { FormattedMessage } from 'react-intl'

// Styling
import './Footer.scss'

const Footer = () => {
  return (
    <div className='Footer'>
      <MultiCarouselBrands />
      <div className='footerContainer'>
        <Col>
          <div>
          <h6><FormattedMessage id="footer.about_site" /></h6>
            <Link to='/info'>
              <p><FormattedMessage id="footer.who_we_are" /></p>
            </Link>
            <Link to='/terms'>
              <p><FormattedMessage id="footer.terms" /></p>
            </Link>
            <Link to='/data-protection'>
              <p><FormattedMessage id="footer.data_protection" /></p>
            </Link>
          </div>
        </Col>
        <Col>
          <div>
            <h6><FormattedMessage id="footer.explore_selection" /></h6>
            <Link to='/campaigns'>
              <p><FormattedMessage id="footer.campaigns" /></p>
            </Link>
            <Link to='/collections'>
              <p><FormattedMessage id="footer.catalogues" /></p>
            </Link>
            <Link to='/brands'>
              <p><FormattedMessage id="footer.brands" /></p>
            </Link>
          </div>
        </Col>
        <Col>
          <div>
            <h6><FormattedMessage id="footer.customer_service" /></h6>
            <Link to='/contact-us'>
              <p><FormattedMessage id="footer.contact_us" /></p>
            </Link>
          </div>
        </Col>
      </div>
      <p>© Desperado Clothing Oy {new Date().getFullYear()}</p>
    </div>
  )
}

export default Footer
