// React & Routing
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'

// Bootstrap
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// Localization
import { FormattedMessage } from 'react-intl'

// Styling
import './NavBar.scss'

const NavBar = () => {
  const [active, setActive] = useState(0)
  const filterQuery = '?page=1&sort=Nimi (A-Ö)&price=0,0&filter={}'

  const menuNaiset = (
    <Container
      id='nav1'
      className='NavMenu'
      onMouseEnter={() => setActive(1)}
      onMouseLeave={() => setActive(0)}
      hidden={active !== 1}>
      <Col className='NavMenuCol'>
        <Link to={`/browse/1100${filterQuery}`}>
          <h6><FormattedMessage id='categories.shirts' /> - <FormattedMessage id='categories.women' /></h6>
        </Link>
        <Link to={`/browse/1101${filterQuery}`}>
          <p><FormattedMessage id='categories.t_shirts' /></p>
        </Link>
        <Link to={`/browse/1102${filterQuery}`}>
          <p><FormattedMessage id='categories.polo_shirts' /></p>
        </Link>
        <Link to={`/browse/1103${filterQuery}`}>
          <p><FormattedMessage id='categories.dress_shirts' /></p>
        </Link>
        <Link to={`/browse/1104${filterQuery}`}>
          <p><FormattedMessage id='categories.knitwear' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/1200${filterQuery}`}>
          <h6><FormattedMessage id='categories.sweat_shirts_short' /> - <FormattedMessage id='categories.women' /></h6>
        </Link>
        <Link to={`/browse/1201${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_shirts_long' /></p>
        </Link>
        <Link to={`/browse/1202${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_hoodies' /></p>
        </Link>
        <Link to={`/browse/1203${filterQuery}`}>
          <p><FormattedMessage id='categories.zip_hoodies' /></p>
        </Link>
        <Link to={`/browse/1204${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_jackets' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/1300${filterQuery}`}>
          <h6><FormattedMessage id='categories.jackets' /> - <FormattedMessage id='categories.women' /></h6>
        </Link>
        <Link to={`/browse/1301${filterQuery}`}>
          <p><FormattedMessage id='categories.fleece_jackets' /></p>
        </Link>
        <Link to={`/browse/1302${filterQuery}`}>
          <p><FormattedMessage id='categories.softshell' /></p>
        </Link>
        <Link to={`/browse/1303${filterQuery}`}>
          <p><FormattedMessage id='categories.wind_and_shell_jackets' /></p>
        </Link>
        <Link to={`/browse/1304${filterQuery}`}>
          <p><FormattedMessage id='categories.quilted_jackets' /></p>
        </Link>
        <Link to={`/browse/1305${filterQuery}`}>
          <p><FormattedMessage id='categories.combination_jackets' /></p>
        </Link>
        <Link to={`/browse/1306${filterQuery}`}>
          <p><FormattedMessage id='categories.business_and_citycoats' /></p>
        </Link>
        <Link to={`/browse/1307${filterQuery}`}>
          <p><FormattedMessage id='categories.vests' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/1400${filterQuery}`}>
          <h6><FormattedMessage id='categories.bottoms' /> - <FormattedMessage id='categories.women' /></h6>
        </Link>
        <Link to={`/browse/1401${filterQuery}`}>
          <p><FormattedMessage id='categories.pants' /></p>
        </Link>
        <Link to={`/browse/1402${filterQuery}`}>
          <p><FormattedMessage id='categories.shorts' /></p>
        </Link>
        <Link to={`/browse/1403${filterQuery}`}>
          <p><FormattedMessage id='categories.sweatpants_and_tights' /></p>
        </Link>
        <Link to={`/browse/1404${filterQuery}`}>
          <p><FormattedMessage id='categories.skirts_and_dresses' /></p>
        </Link>
        <Link to={`/browse/1405${filterQuery}`}>
          <p><FormattedMessage id='categories.underwear' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/1500${filterQuery}`}>
          <h6><FormattedMessage id='categories.sport' /> - <FormattedMessage id='categories.women' /></h6>
        </Link>
        <Link to={`/browse/1501${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_t_shirts' /></p>
        </Link>
        <Link to={`/browse/1502${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_polo_shirts' /></p>
        </Link>
        <Link to={`/browse/1503${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_shorts' /></p>
        </Link>
        <Link to={`/browse/1504${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_pants' /></p>
        </Link>
        <Link to={`/browse/1505${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_jackets' /></p>
        </Link>
        <Link to={`/browse/1506${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_accessories' /></p>
        </Link>
        <Link to={`/browse/1507${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_shirts' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/1600${filterQuery}`}>
          <h6><FormattedMessage id='categories.accessories' /> - <FormattedMessage id='categories.women' /></h6>
        </Link>
        <Link to={`/browse/1601${filterQuery}`}>
          <p><FormattedMessage id='categories.headgear' /></p>
        </Link>
        <Link to={`/browse/1602${filterQuery}`}>
          <p><FormattedMessage id='categories.gloves' /></p>
        </Link>
        <Link to={`/browse/1603${filterQuery}`}>
          <p><FormattedMessage id='categories.scarfs' /></p>
        </Link>
        <Link to={`/browse/1604${filterQuery}`}>
          <p><FormattedMessage id='categories.belts' /></p>
        </Link>
        <Link to={`/browse/1605${filterQuery}`}>
          <p><FormattedMessage id='categories.socks' /></p>
        </Link>
        <Link to={`/browse/1606${filterQuery}`}>
          <p><FormattedMessage id='categories.aprons' /></p>
        </Link>
        <Link to={`/browse/1607${filterQuery}`}>
          <p><FormattedMessage id='categories.rain_equipment' /></p>
        </Link>
        <Link to={`/browse/1608${filterQuery}`}>
          <p><FormattedMessage id='categories.bath_items' /></p>
        </Link>
        <Link to={`/browse/1609${filterQuery}`}>
          <p><FormattedMessage id='categories.blankets_and_other_sidelines' /></p>
        </Link>
        <Link to={`/browse/1610${filterQuery}`}>
          <p><FormattedMessage id='categories.shoes' /></p>
        </Link>
      </Col>
    </Container>
  )

  const menuMiehet = (
    <Container
      id='nav2'
      className='NavMenu'
      onMouseEnter={() => setActive(2)}
      onMouseLeave={() => setActive(0)}
      hidden={active !== 2}>
      <Col className='NavMenuCol'>
        <Link to={`/browse/2100${filterQuery}`}>
          <h6><FormattedMessage id='categories.shirts' /> - <FormattedMessage id='categories.men' /></h6>
        </Link>
        <Link to={`/browse/2101${filterQuery}`}>
          <p><FormattedMessage id='categories.t_shirts' /></p>
        </Link>
        <Link to={`/browse/2102${filterQuery}`}>
          <p><FormattedMessage id='categories.polo_shirts' /></p>
        </Link>
        <Link to={`/browse/2103${filterQuery}`}>
          <p><FormattedMessage id='categories.dress_shirts' /></p>
        </Link>
        <Link to={`/browse/2104${filterQuery}`}>
          <p><FormattedMessage id='categories.knitwear' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/2200${filterQuery}`}>
          <h6><FormattedMessage id='categories.sweat_shirts_short' /> - <FormattedMessage id='categories.men' /></h6>
        </Link>
        <Link to={`/browse/2201${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_shirts_long' /></p>
        </Link>
        <Link to={`/browse/2202${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_hoodies' /></p>
        </Link>
        <Link to={`/browse/2203${filterQuery}`}>
          <p><FormattedMessage id='categories.zip_hoodies' /></p>
        </Link>
        <Link to={`/browse/2204${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_jackets' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/2300${filterQuery}`}>
          <h6><FormattedMessage id='categories.jackets' /> - <FormattedMessage id='categories.men' /></h6>
        </Link>
        <Link to={`/browse/2301${filterQuery}`}>
          <p><FormattedMessage id='categories.fleece_jackets' /></p>
        </Link>
        <Link to={`/browse/2302${filterQuery}`}>
          <p><FormattedMessage id='categories.softshell' /></p>
        </Link>
        <Link to={`/browse/2303${filterQuery}`}>
          <p><FormattedMessage id='categories.wind_and_shell_jackets' /></p>
        </Link>
        <Link to={`/browse/2304${filterQuery}`}>
          <p><FormattedMessage id='categories.quilted_jackets' /></p>
        </Link>
        <Link to={`/browse/2305${filterQuery}`}>
          <p><FormattedMessage id='categories.combination_jackets' /></p>
        </Link>
        <Link to={`/browse/2306${filterQuery}`}>
          <p><FormattedMessage id='categories.business_and_citycoats' /></p>
        </Link>
        <Link to={`/browse/2307${filterQuery}`}>
          <p><FormattedMessage id='categories.vests' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/2400${filterQuery}`}>
          <h6><FormattedMessage id='categories.bottoms' /> - <FormattedMessage id='categories.men' /></h6>
        </Link>
        <Link to={`/browse/2401${filterQuery}`}>
          <p><FormattedMessage id='categories.pants' /></p>
        </Link>
        <Link to={`/browse/2402${filterQuery}`}>
          <p><FormattedMessage id='categories.shorts' /></p>
        </Link>
        <Link to={`/browse/2403${filterQuery}`}>
          <p><FormattedMessage id='categories.sweatpants_and_tights' /></p>
        </Link>
        <Link to={`/browse/2404${filterQuery}`}>
          <p><FormattedMessage id='categories.skirts_and_dresses' /></p>
        </Link>
        <Link to={`/browse/2405${filterQuery}`}>
          <p><FormattedMessage id='categories.underwear' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/2500${filterQuery}`}>
          <h6><FormattedMessage id='categories.sport' /> - <FormattedMessage id='categories.men' /></h6>
        </Link>
        <Link to={`/browse/2501${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_t_shirts' /></p>
        </Link>
        <Link to={`/browse/2502${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_polo_shirts' /></p>
        </Link>
        <Link to={`/browse/2503${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_shorts' /></p>
        </Link>
        <Link to={`/browse/2504${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_pants' /></p>
        </Link>
        <Link to={`/browse/2505${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_jackets' /></p>
        </Link>
        <Link to={`/browse/2506${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_accessories' /></p>
        </Link>
        <Link to={`/browse/2507${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_shirts' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/2600${filterQuery}`}>
          <h6><FormattedMessage id='categories.accessories' /> - <FormattedMessage id='categories.men' /></h6>
        </Link>
        <Link to={`/browse/2601${filterQuery}`}>
          <p><FormattedMessage id='categories.headgear' /></p>
        </Link>
        <Link to={`/browse/2602${filterQuery}`}>
          <p><FormattedMessage id='categories.gloves' /></p>
        </Link>
        <Link to={`/browse/2603${filterQuery}`}>
          <p><FormattedMessage id='categories.scarfs' /></p>
        </Link>
        <Link to={`/browse/2604${filterQuery}`}>
          <p><FormattedMessage id='categories.belts' /></p>
        </Link>
        <Link to={`/browse/2605${filterQuery}`}>
          <p><FormattedMessage id='categories.socks' /></p>
        </Link>
        <Link to={`/browse/2606${filterQuery}`}>
          <p><FormattedMessage id='categories.aprons' /></p>
        </Link>
        <Link to={`/browse/2607${filterQuery}`}>
          <p><FormattedMessage id='categories.rain_equipment' /></p>
        </Link>
        <Link to={`/browse/2608${filterQuery}`}>
          <p><FormattedMessage id='categories.bath_items' /></p>
        </Link>
        <Link to={`/browse/2609${filterQuery}`}>
          <p><FormattedMessage id='categories.blankets_and_other_sidelines' /></p>
        </Link>
        <Link to={`/browse/2610${filterQuery}`}>
          <p><FormattedMessage id='categories.shoes' /></p>
        </Link>
      </Col>
    </Container>
  )

  const menuLapset = (
    <Container
      id='nav3'
      className='NavMenu'
      onMouseEnter={() => setActive(3)}
      onMouseLeave={() => setActive(0)}
      hidden={active !== 3}>
      <Col className='NavMenuCol'>
        <Link to={`/browse/3100${filterQuery}`}>
          <h6><FormattedMessage id='categories.shirts' /> - <FormattedMessage id='categories.kids' /></h6>
        </Link>
        <Link to={`/browse/3101${filterQuery}`}>
          <p><FormattedMessage id='categories.t_shirts' /></p>
        </Link>
        <Link to={`/browse/3102${filterQuery}`}>
          <p><FormattedMessage id='categories.polo_shirts' /></p>
        </Link>
        <Link to={`/browse/3103${filterQuery}`}>
          <p><FormattedMessage id='categories.dress_shirts' /></p>
        </Link>
        <Link to={`/browse/3104${filterQuery}`}>
          <p><FormattedMessage id='categories.knitwear' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/3200${filterQuery}`}>
          <h6><FormattedMessage id='categories.sweat_shirts_short' /> - <FormattedMessage id='categories.kids' /></h6>
        </Link>
        <Link to={`/browse/3201${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_shirts_long' /></p>
        </Link>
        <Link to={`/browse/3202${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_hoodies' /></p>
        </Link>
        <Link to={`/browse/3203${filterQuery}`}>
          <p><FormattedMessage id='categories.zip_hoodies' /></p>
        </Link>
        <Link to={`/browse/3204${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_jackets' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/3300${filterQuery}`}>
          <h6><FormattedMessage id='categories.jackets' /> - <FormattedMessage id='categories.kids' /></h6>
        </Link>
        <Link to={`/browse/3301${filterQuery}`}>
          <p><FormattedMessage id='categories.fleece_jackets' /></p>
        </Link>
        <Link to={`/browse/3302${filterQuery}`}>
          <p><FormattedMessage id='categories.softshell' /></p>
        </Link>
        <Link to={`/browse/3303${filterQuery}`}>
          <p><FormattedMessage id='categories.wind_and_shell_jackets' /></p>
        </Link>
        <Link to={`/browse/3304${filterQuery}`}>
          <p><FormattedMessage id='categories.quilted_jackets' /></p>
        </Link>
        <Link to={`/browse/3305${filterQuery}`}>
          <p><FormattedMessage id='categories.combination_jackets' /></p>
        </Link>
        <Link to={`/browse/3306${filterQuery}`}>
          <p><FormattedMessage id='categories.business_and_citycoats' /></p>
        </Link>
        <Link to={`/browse/3307${filterQuery}`}>
          <p><FormattedMessage id='categories.vests' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/3400${filterQuery}`}>
          <h6><FormattedMessage id='categories.bottoms' /> - <FormattedMessage id='categories.kids' /></h6>
        </Link>
        <Link to={`/browse/3401${filterQuery}`}>
          <p><FormattedMessage id='categories.pants' /></p>
        </Link>
        <Link to={`/browse/3402${filterQuery}`}>
          <p><FormattedMessage id='categories.shorts' /></p>
        </Link>
        <Link to={`/browse/3403${filterQuery}`}>
          <p><FormattedMessage id='categories.sweatpants_and_tights' /></p>
        </Link>
        <Link to={`/browse/3404${filterQuery}`}>
          <p><FormattedMessage id='categories.skirts_and_dresses' /></p>
        </Link>
        <Link to={`/browse/3405${filterQuery}`}>
          <p><FormattedMessage id='categories.underwear' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/3500${filterQuery}`}>
          <h6><FormattedMessage id='categories.sport' /> - <FormattedMessage id='categories.kids' /></h6>
        </Link>
        <Link to={`/browse/3501${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_t_shirts' /></p>
        </Link>
        <Link to={`/browse/3502${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_polo_shirts' /></p>
        </Link>
        <Link to={`/browse/3503${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_shorts' /></p>
        </Link>
        <Link to={`/browse/3504${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_pants' /></p>
        </Link>
        <Link to={`/browse/3505${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_jackets' /></p>
        </Link>
        <Link to={`/browse/3506${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_accessories' /></p>
        </Link>
        <Link to={`/browse/3507${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_shirts' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/3600${filterQuery}`}>
          <h6><FormattedMessage id='categories.accessories' /> - <FormattedMessage id='categories.kids' /></h6>
        </Link>
        <Link to={`/browse/3601${filterQuery}`}>
          <p><FormattedMessage id='categories.headgear' /></p>
        </Link>
        <Link to={`/browse/3602${filterQuery}`}>
          <p><FormattedMessage id='categories.gloves' /></p>
        </Link>
        <Link to={`/browse/3603${filterQuery}`}>
          <p><FormattedMessage id='categories.scarfs' /></p>
        </Link>
        <Link to={`/browse/3604${filterQuery}`}>
          <p><FormattedMessage id='categories.belts' /></p>
        </Link>
        <Link to={`/browse/3605${filterQuery}`}>
          <p><FormattedMessage id='categories.socks' /></p>
        </Link>
        <Link to={`/browse/3606${filterQuery}`}>
          <p><FormattedMessage id='categories.aprons' /></p>
        </Link>
        <Link to={`/browse/3607${filterQuery}`}>
          <p><FormattedMessage id='categories.rain_equipment' /></p>
        </Link>
        <Link to={`/browse/3608${filterQuery}`}>
          <p><FormattedMessage id='categories.bath_items' /></p>
        </Link>
        <Link to={`/browse/3609${filterQuery}`}>
          <p><FormattedMessage id='categories.blankets_and_other_sidelines' /></p>
        </Link>
        <Link to={`/browse/3610${filterQuery}`}>
          <p><FormattedMessage id='categories.shoes' /></p>
        </Link>
      </Col>
    </Container>
  )

  const menuLaukut = (
    <Container
      id='nav4'
      className='NavMenu OneLine'
      onMouseEnter={() => setActive(4)}
      onMouseLeave={() => setActive(0)}
      hidden={active !== 4}>
      <Col className='NavMenuCol'>
        <Link to={`/browse/4001${filterQuery}`}>
          <p><FormattedMessage id='categories.shopping_bags' /></p>
        </Link>
        <Link to={`/browse/4002${filterQuery}`}>
          <p><FormattedMessage id='categories.purses_and_giftbags' /></p>
        </Link>
        <Link to={`/browse/4003${filterQuery}`}>
          <p><FormattedMessage id='categories.document_and_brochurebags' /></p>
        </Link>
        <Link to={`/browse/4004${filterQuery}`}>
          <p><FormattedMessage id='categories.computer_bags' /></p>
        </Link>
        <Link to={`/browse/4005${filterQuery}`}>
          <p><FormattedMessage id='categories.shoulder_and_fanny_pack' /></p>
        </Link>
        <Link to={`/browse/4006${filterQuery}`}>
          <p><FormattedMessage id='categories.backpacks' /></p>
        </Link>
        <Link to={`/browse/4007${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_and_travel_bags' /></p>
        </Link>
        <Link to={`/browse/4008${filterQuery}`}>
          <p><FormattedMessage id='categories.portmanteaus' /></p>
        </Link>
        <Link to={`/browse/4009${filterQuery}`}>
          <p><FormattedMessage id='categories.makeup_and_toiletbags' /></p>
        </Link>
        <Link to={`/browse/4010${filterQuery}`}>
          <p><FormattedMessage id='categories.coolers' /></p>
        </Link>
        <Link to={`/browse/4011${filterQuery}`}>
          <p><FormattedMessage id='categories.big_bags' /></p>
        </Link>
      </Col>
    </Container>
  )

  const menuTyovaatteetNew = (
    <Container
    id='nav5'
    className='NavMenu'
    onMouseEnter={() => setActive(5)}
    onMouseLeave={() => setActive(0)}
    hidden={active !== 5}>
      <Col className='NavMenuCol'>
        <Link to={`/browse/5100${filterQuery}`}>
          <h6><FormattedMessage id='categories.hi_vis' /></h6>
        </Link>
        <Link to={`/browse/5101${filterQuery}`}>
          <p><FormattedMessage id='categories.shirts' /></p>
        </Link>
        <Link to={`/browse/5105${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_shirts_short' /></p>
        </Link>
        <Link to={`/browse/5102${filterQuery}`}>
          <p><FormattedMessage id='categories.jackets' /></p>
        </Link>
        <Link to={`/browse/5103${filterQuery}`}>
          <p><FormattedMessage id='categories.bottoms' /></p>
        </Link>
        <Link to={`/browse/5104${filterQuery}`}>
          <p><FormattedMessage id='categories.headgear_and_accessories' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/5200${filterQuery}`}>
          <h6><FormattedMessage id='categories.construction_and_industry' /></h6>
        </Link>
        <Link to={`/browse/5201${filterQuery}`}>
          <p><FormattedMessage id='categories.shirts' /></p>
        </Link>
        <Link to={`/browse/5202${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_shirts_short' /></p>
        </Link>
        <Link to={`/browse/5203${filterQuery}`}>
          <p><FormattedMessage id='categories.jackets' /></p>
        </Link>
        <Link to={`/browse/5204${filterQuery}`}>
          <p><FormattedMessage id='categories.bottoms' /></p>
        </Link>
        <Link to={`/browse/5205${filterQuery}`}>
          <p><FormattedMessage id='categories.shoes' /></p>
        </Link>
        <Link to={`/browse/5206${filterQuery}`}>
          <p><FormattedMessage id='categories.headgear_and_accessories' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/5300${filterQuery}`}>
          <h6><FormattedMessage id='categories.accomodation_and_restaurant' /></h6>
        </Link>
        <Link to={`/browse/5301${filterQuery}`}>
          <p><FormattedMessage id='categories.tops' /></p>
        </Link>
        <Link to={`/browse/5302${filterQuery}`}>
          <p><FormattedMessage id='categories.bottoms' /></p>
        </Link>
        <Link to={`/browse/5303${filterQuery}`}>
          <p><FormattedMessage id='categories.aprons' /></p>
        </Link>
        <Link to={`/browse/5304${filterQuery}`}>
          <p><FormattedMessage id='categories.shoes' /></p>
        </Link>
        <Link to={`/browse/5305${filterQuery}`}>
          <p><FormattedMessage id='categories.headgear_and_accessories' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/5400${filterQuery}`}>
          <h6><FormattedMessage id='categories.health_and_care' /></h6>
        </Link>
        <Link to={`/browse/5401${filterQuery}`}>
          <p><FormattedMessage id='categories.tops' /></p>
        </Link>
        <Link to={`/browse/5402${filterQuery}`}>
          <p><FormattedMessage id='categories.bottoms' /></p>
        </Link>
        <Link to={`/browse/5403${filterQuery}`}>
          <p><FormattedMessage id='categories.aprons' /></p>
        </Link>
        <Link to={`/browse/5404${filterQuery}`}>
          <p><FormattedMessage id='categories.shoes' /></p>
        </Link>
        <Link to={`/browse/5405${filterQuery}`}>
          <p><FormattedMessage id='categories.headgear_and_accessories' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/5500${filterQuery}`}>
          <h6><FormattedMessage id='categories.classified' /></h6>
        </Link>
        <Link to={`/browse/5501${filterQuery}`}>
          <p><FormattedMessage id='categories.visible' /></p>
        </Link>
        <Link to={`/browse/5502${filterQuery}`}>
          <p><FormattedMessage id='categories.antistatic' /></p>
        </Link>
        <Link to={`/browse/5503${filterQuery}`}>
          <p><FormattedMessage id='categories.fire_and_welding_protected' /></p>
        </Link>
        <Link to={`/browse/5504${filterQuery}`}>
          <p><FormattedMessage id='categories.cold_and_rain_protected' /></p>
        </Link>
        <Link to={`/browse/5505${filterQuery}`}>
          <p><FormattedMessage id='categories.safety_footwear' /></p>
        </Link>
      </Col>
    </Container>
  )

  // const menuTyovaatteet = (
  //   <Container
  //     id='nav5'
  //     className='NavMenu OneLine'
  //     onMouseEnter={() => setActive(5)}
  //     onMouseLeave={() => setActive(0)}
  //     hidden={active !== 5}>
  //     <Col className='NavMenuCol'>
  //       <Link to={`/browse/5001${filterQuery}`}>
  //         <p>T-paidat</p>
  //       </Link>
  //       <Link to={`/browse/5002${filterQuery}`}>
  //         <p>Pikeet</p>
  //       </Link>
  //       <Link to={`/browse/5003${filterQuery}`}>
  //         <p>Collegepaidat</p>
  //       </Link>
  //       <Link to={`/browse/5004${filterQuery}`}>
  //         <p>Collegehupparit</p>
  //       </Link>
  //       <Link to={`/browse/5005${filterQuery}`}>
  //         <p>Vetoketjuhupparit</p>
  //       </Link>
  //       <Link to={`/browse/5006${filterQuery}`}>
  //         <p>Työhousut</p>
  //       </Link>
  //       <Link to={`/browse/5007${filterQuery}`}>
  //         <p>Kauluspaidat</p>
  //       </Link>
  //       <Link to={`/browse/5008${filterQuery}`}>
  //         <p>Collegetakit</p>
  //       </Link>
  //       <Link to={`/browse/5009${filterQuery}`}>
  //         <p>Takit ja liivit</p>
  //       </Link>
  //       <Link to={`/browse/5010${filterQuery}`}>
  //         <p>Oheistuotteet</p>
  //       </Link>
  //     </Col>
  //   </Container>
  // )

  const menuTuotemerkit = (
    <Container
      id='nav6'
      className='NavMenu OneLine'
      onMouseEnter={() => setActive(6)}
      onMouseLeave={() => setActive(0)}
      hidden={active !== 6}>
      <Col className='NavMenuCol'>
        <Link to={`/browse/6001${filterQuery}`}>
          <p>B&C</p>
        </Link>
        <Link to={`/browse/6002${filterQuery}`}>
          <p>Bella+Canvas</p>
        </Link>
        <Link to={`/browse/6003${filterQuery}`}>
          <p>bugOff</p>
        </Link>
        <Link to={`/browse/6004${filterQuery}`}>
          <p>Cobalt Fusion</p>
        </Link>
        <Link to={`/browse/6005${filterQuery}`}>
          <p>Cobalt Gear</p>
        </Link>
        <Link to={`/browse/6024${filterQuery}`}>
          <p>Cobalt Uusio</p>
        </Link>
        <Link to={`/browse/6022${filterQuery}`}>
          <p>EXCD</p>
        </Link>
        <Link to={`/browse/6006${filterQuery}`}>
          <p>Gildan</p>
        </Link>
        <Link to={`/browse/6019${filterQuery}`}>
          <p>Jack&Maker</p>
        </Link>
        <Link to={`/browse/6007${filterQuery}`}>
          <p>K-Up</p>
        </Link>
        <Link to={`/browse/6008${filterQuery}`}>
          <p>Kariban</p>
        </Link>
        <Link to={`/browse/6020${filterQuery}`}>
          <p>Kariban Premium</p>
        </Link>
        <Link to={`/browse/6009${filterQuery}`}>
          <p>Kimood</p>
        </Link>
        <Link to={`/browse/6016${filterQuery}`}>
          <p>Mukua</p>
        </Link>
        <Link to={`/browse/6021${filterQuery}`} className='ns-extra-class'>
          <p>Native Spirit</p>
        </Link>
        <Link to={`/browse/6010${filterQuery}`}>
          <p>Penduick</p>
        </Link>
        <Link to={`/browse/6011${filterQuery}`}>
          <p>Petrol</p>
        </Link>
        <Link to={`/browse/6012${filterQuery}`}>
          <p>Proact</p>
        </Link>
        <Link to={`/browse/6013${filterQuery}`}>
          <p>Promodoro</p>
        </Link>
        <Link to={`/browse/6014${filterQuery}`}>
          <p>Stormtech</p>
        </Link>
        <Link to={`/browse/6017${filterQuery}`}>
          <p>V-Pro</p>
        </Link>
        <Link to={`/browse/6018${filterQuery}`}>
          <p>Velilla</p>
        </Link>
        <Link to={`/browse/6015${filterQuery}`}>
          <p>WK</p>
        </Link>
        <Link to={`/browse/6023${filterQuery}`}>
          <p>X.O</p>
        </Link>
      </Col>
    </Container>
  )

  const menuTuoteryhmat = (
    <Container
      id='nav7'
      className='NavMenu'
      onMouseEnter={() => setActive(7)}
      onMouseLeave={() => setActive(0)}
      hidden={active !== 7}>
      <Col className='NavMenuCol'>
        <Link to={`/browse/0100${filterQuery}`}>
          <h6><FormattedMessage id='categories.shirts' /></h6>
        </Link>
        <Link to={`/browse/0101${filterQuery}`}>
          <p><FormattedMessage id='categories.t_shirts' /></p>
        </Link>
        <Link to={`/browse/0102${filterQuery}`}>
          <p><FormattedMessage id='categories.polo_shirts' /></p>
        </Link>
        <Link to={`/browse/0103${filterQuery}`}>
          <p><FormattedMessage id='categories.dress_shirts' /></p>
        </Link>
        <Link to={`/browse/0104${filterQuery}`}>
          <p><FormattedMessage id='categories.knitwear' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/0200${filterQuery}`}>
          <h6><FormattedMessage id='categories.sweat_shirts_short' /></h6>
        </Link>
        <Link to={`/browse/0201${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_shirts_long' /></p>
        </Link>
        <Link to={`/browse/0202${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_hoodies' /></p>
        </Link>
        <Link to={`/browse/0203${filterQuery}`}>
          <p><FormattedMessage id='categories.zip_hoodies' /></p>
        </Link>
        <Link to={`/browse/0204${filterQuery}`}>
          <p><FormattedMessage id='categories.sweat_jackets' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/0300${filterQuery}`}>
          <h6><FormattedMessage id='categories.jackets' /></h6>
        </Link>
        <Link to={`/browse/0301${filterQuery}`}>
          <p><FormattedMessage id='categories.fleece_jackets' /></p>
        </Link>
        <Link to={`/browse/0302${filterQuery}`}>
          <p><FormattedMessage id='categories.softshell' /></p>
        </Link>
        <Link to={`/browse/0303${filterQuery}`}>
          <p><FormattedMessage id='categories.wind_and_shell_jackets' /></p>
        </Link>
        <Link to={`/browse/0304${filterQuery}`}>
          <p><FormattedMessage id='categories.quilted_jackets' /></p>
        </Link>
        <Link to={`/browse/0305${filterQuery}`}>
          <p><FormattedMessage id='categories.combination_jackets' /></p>
        </Link>
        <Link to={`/browse/0306${filterQuery}`}>
          <p><FormattedMessage id='categories.business_and_citycoats' /></p>
        </Link>
        <Link to={`/browse/0307${filterQuery}`}>
          <p><FormattedMessage id='categories.vests' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/0400${filterQuery}`}>
          <h6><FormattedMessage id='categories.bottoms' /></h6>
        </Link>
        <Link to={`/browse/0401${filterQuery}`}>
          <p><FormattedMessage id='categories.pants' /></p>
        </Link>
        <Link to={`/browse/0402${filterQuery}`}>
          <p><FormattedMessage id='categories.shorts' /></p>
        </Link>
        <Link to={`/browse/0403${filterQuery}`}>
          <p><FormattedMessage id='categories.sweatpants_and_tights' /></p>
        </Link>
        <Link to={`/browse/0404${filterQuery}`}>
          <p><FormattedMessage id='categories.skirts_and_dresses' /></p>
        </Link>
        <Link to={`/browse/0405${filterQuery}`}>
          <p><FormattedMessage id='categories.underwear' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/0500${filterQuery}`}>
          <h6><FormattedMessage id='categories.sport' /></h6>
        </Link>
        <Link to={`/browse/0501${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_t_shirts' /></p>
        </Link>
        <Link to={`/browse/0502${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_polo_shirts' /></p>
        </Link>
        <Link to={`/browse/0503${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_shorts' /></p>
        </Link>
        <Link to={`/browse/0504${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_pants' /></p>
        </Link>
        <Link to={`/browse/0505${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_jackets' /></p>
        </Link>
        <Link to={`/browse/0506${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_accessories' /></p>
        </Link>
        <Link to={`/browse/0507${filterQuery}`}>
          <p><FormattedMessage id='categories.sport_shirts' /></p>
        </Link>
      </Col>
      <Col className='NavMenuCol'>
        <Link to={`/browse/0600${filterQuery}`}>
          <h6><FormattedMessage id='categories.accessories' /></h6>
        </Link>
        <Link to={`/browse/0601${filterQuery}`}>
          <p><FormattedMessage id='categories.headgear' /></p>
        </Link>
        <Link to={`/browse/0602${filterQuery}`}>
          <p><FormattedMessage id='categories.gloves' /></p>
        </Link>
        <Link to={`/browse/0603${filterQuery}`}>
          <p><FormattedMessage id='categories.scarfs' /></p>
        </Link>
        <Link to={`/browse/0604${filterQuery}`}>
          <p><FormattedMessage id='categories.belts' /></p>
        </Link>
        <Link to={`/browse/0605${filterQuery}`}>
          <p><FormattedMessage id='categories.socks' /></p>
        </Link>
        <Link to={`/browse/0606${filterQuery}`}>
          <p><FormattedMessage id='categories.aprons' /></p>
        </Link>
        <Link to={`/browse/0607${filterQuery}`}>
          <p><FormattedMessage id='categories.rain_equipment' /></p>
        </Link>
        <Link to={`/browse/0608${filterQuery}`}>
          <p><FormattedMessage id='categories.bath_items' /></p>
        </Link>
        <Link to={`/browse/0609${filterQuery}`}>
          <p><FormattedMessage id='categories.blankets_and_other_sidelines' /></p>
        </Link>
        <Link to={`/browse/0610${filterQuery}`}>
          <p><FormattedMessage id='categories.shoes' /></p>
        </Link>
      </Col>
    </Container>
  )

  return (
    <div className='NavContainer'>
      <Container className='NavBar' onMouseLeave={() => setActive(0)}>
        <Col className={active === 1 ? 'active' : null} onMouseEnter={() => setActive(1)}>
          <Link to={`/browse/1000${filterQuery}`}>
            <p><FormattedMessage id='categories.women' /></p>
          </Link>
        </Col>
        <Col className={active === 2 ? 'active' : null} onMouseEnter={() => setActive(2)}>
          <Link to={`/browse/2000${filterQuery}`}>
            <p><FormattedMessage id='categories.men' /></p>
          </Link>
        </Col>
        <Col className={active === 3 ? 'active' : null} onMouseEnter={() => setActive(3)}>
          <Link to={`/browse/3000${filterQuery}`}>
            <p><FormattedMessage id='categories.kids' /></p>
          </Link>
        </Col>
        <Col className={active === 4 ? 'active' : null} onMouseEnter={() => setActive(4)}>
          <Link to={`/browse/4000${filterQuery}`}>
            <p><FormattedMessage id='categories.bags' /></p>
          </Link>
        </Col>
        <Col className={active === 5 ? 'active' : null} onMouseEnter={() => setActive(5)}>
          <Link to={`/browse/5000${filterQuery}`}>
            <p><FormattedMessage id='categories.working_clothes' /></p>
          </Link>
        </Col>
        <Col className={active === 6 ? 'active' : null} onMouseEnter={() => setActive(6)}>
          <p><FormattedMessage id='categories.brands' /></p>
        </Col>
        <Col className={active === 7 ? 'active' : null} onMouseEnter={() => setActive(7)}>
          <p><FormattedMessage id='categories.product_groups' /></p>
        </Col>
        <Col
          className={active === 8 ? 'altNav active ecoNav' : 'altNav ecoNav'}
          onMouseEnter={() => setActive(8)}>
          <Link to={`/browse/eco${filterQuery}`}>
            <p style={{ textTransform: 'uppercase' }}><FormattedMessage id='categories.eco' /></p>
          </Link>
        </Col>
        <Col
          className={active === 9 ? 'altNav active' : 'altNav'}
          onMouseEnter={() => setActive(9)}>
          <Link to={`/outlet${filterQuery}`}>
            <p><FormattedMessage id='categories.outlet' /></p>
          </Link>
        </Col>
        <Col
          className={active === 10 ? 'altNav active' : 'altNav'}
          onMouseEnter={() => setActive(10)}>
          <Link to={`/browse/new${filterQuery}`}>
            <p><FormattedMessage id='categories.new' /></p>
          </Link>
        </Col>
      </Container>
      {menuNaiset}
      {menuMiehet}
      {menuLapset}
      {menuLaukut}
      {/* {menuTyovaatteet} */}
      {menuTyovaatteetNew}
      {menuTuotemerkit}
      {menuTuoteryhmat}
    </div>
  )
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(NavBar)
