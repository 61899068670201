// React & Routing
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useParams, useNavigate, Outlet, Navigate } from 'react-router-dom'
import LangCtx from './LangCtx.js'

// Redux
import { connect, useSelector } from 'react-redux'
import { actionLoadUser, actionFavoritesGet } from './redux/actions/action.auth'
import { actionCartDelayedJob, actionCartGet, actionCartUpdate } from './redux/actions/action.cart'
import {
  actionProductGetDisplay,
  actionProductUutuudet,
  actionProductKatsotuimmat,
} from './redux/actions/action.product'
import { actionCampaignGetAll } from './redux/actions/action.campaign'
import { actionUtilsGet } from './redux/actions/action.utils'

// Pages
import FrontPage from './pages/FrontPage/FrontPage'
import Product from './pages/Product/Product'
import OutletProduct from './pages/OutletProduct/OutletProduct'
import Browse from './pages/ItemBrowse/Browse'
import Search from './pages/ItemBrowse/Search'
import OutletComponent from './pages/ItemBrowse/Outlet'
import Checkout from './pages/Checkout/Checkout'
import Account from './pages/Account/Account'
import Favorites from './pages/Favorites/Favorites'
import Order from './pages/Order/Order'
import Campaign from './pages/ItemBrowse/Campaign'
import Campaigns from './pages/Campaigns/Campaigns'
import Collections from './pages/Collections/Collections'
import Received from './pages/Received/Received'
import Info from './pages/Info/Info'
import Brands from './pages/Brands/Brands'
import Contact from './pages/Contact/Contact'
import Terms from './pages/Terms/Terms'
import Cpdp from './pages/Cpdp/Cpdp'
import NotFound from './pages/NotFound/NotFound'
import ResetPw1 from './pages/ResetPw/ResetPw1'
import ResetPw2 from './pages/ResetPw/ResetPw2'
import CardEditor from './pages/CardEditor/CardEditor'
import ImageEditor from './pages/ImageEditor/ImageEditor'

// Components
import ToolBar from './components/ToolBar/ToolBar'
import NavBar from './components/NavBar/NavBar'
import Alert from './components/Alert/Alert'
import CookieAlert from './components/CookieAlert/CookieAlert'
import Footer from './components/Footer/Footer'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'

// Localization
import { IntlProvider } from 'react-intl'
import translations from './translations.json'

// Styling
import './App.scss'

// Utils
import setAuthToken from './utils/setAuthToken'

const App = ({
  initialize,
  actionCartGet,
  actionCartUpdate,
  actionCartDelayedJob,
  actionFavoritesGet,
  actionProductGetDisplay,
  actionProductUutuudet,
  actionProductKatsotuimmat,
  actionLoadUser,
  actionCampaignGetAll,
  actionUtilsGet,
  product: { display, displayLoadingDone },
  cart,
  user,
  isAuthenticated,
  loading,
}) => {
  const [hideCookieAlert, setHideCookieAlert] = useState(false)
  const utils = useSelector((state) => state.utils)
  const appEnvironment = process.env.REACT_APP_ENVIRONMENT
  const appVersion = process.env.REACT_APP_VERSION
  const [cartTimeout, setCartTimeout] = useState(undefined)
  const location = useLocation()
  const navigate = useNavigate()
  const isEditor = location.pathname.startsWith('/card-editor') || location.pathname.startsWith('/image-editor')
  
  const languages = [
    { id: 'fi', name: 'Suomi', n: 0 },
    { id: 'en', name: 'English', n: 1 }
  ]
  const [lang, setLang] = useState(languages[0])

  const setLanguageToURL = (lang) => {
    const currentPath = window.location.pathname.split('/').slice(2).join('/')
    const queryParams = window.location.search
    const newPath = currentPath ? `/${lang.id}/${currentPath}${queryParams}` : `/${lang.id}${queryParams}`
    navigate(newPath, { replace: true })
  }

  const setLanguageToApp = (lang) => {
    document.documentElement.setAttribute('lang', lang.id)
    setLang(lang)
  }

  const processTranslations = (translations, lang) => {
    const locales = languages.reduce((acc, language) => ({ ...acc, [language.id]: {} }), {})

    for (const [key, value] of Object.entries(translations)) {
      for (const [subKey, subValue] of Object.entries(value)) {
        if (subValue && subValue.length > lang.n) {
          locales.fi[`${key}.${subKey}`] = subValue[0]
          locales.en[`${key}.${subKey}`] = subValue[1]
        }
      }
    }
    
    const selectedLocale = locales[lang.id]
    return selectedLocale
  }

  const messages = processTranslations(translations, lang)

  useEffect(() => {
    actionCampaignGetAll()
    actionProductGetDisplay()
    actionUtilsGet()
    setAuthToken(localStorage.getItem('shop-token'))
    actionLoadUser()

    const cookiesAccepted = localStorage.getItem('cookies-accepted')
    if (cookiesAccepted === 'true') {
      setHideCookieAlert(true)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (displayLoadingDone) {
      actionProductUutuudet(display.uutuudet)
      actionProductKatsotuimmat({
        customList: display.customList,
        katsotuimmat: display.katsotuimmat,
      })
    }
    // eslint-disable-next-line
  }, [display, displayLoadingDone])

  useEffect(() => {
    if (isAuthenticated && user && user.email) {
      document.body.click()
      actionCartGet()
      actionFavoritesGet(user.favorites)
    } else {
      const storageFavorites = localStorage.getItem('dc_shop_favorites')
      if (storageFavorites) {
        actionFavoritesGet(JSON.parse(storageFavorites))
      }
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    // Käyttäjä on kirjautunut ja ostoskorissa muutoksia -> haetaan ostoskori API:lta
    if (user && user._id && cart && !cart.inSync) {
      actionCartGet()
    }
    // eslint-disable-next-line
  }, [cart])

  useEffect(() => {
    const updateWithDelay = () => {
      setCartTimeout(setTimeout(() => actionCartUpdate(cart.delayedJobs, cart.updated), 2000))
    }

    if (cart.delayedJobs && cart.delayedJobs.length) {
      setCartTimeout(clearTimeout(cartTimeout))
      updateWithDelay()
    }
    // eslint-disable-next-line
  }, [cart.delayedJobs.length])

  // Uudelleenohjataan etusivulle jos ei ole kirjautunut.
  // Lisäksi Switchin ensimmäisessä Routessa ehtolause isAuthenticated
  // ja komponentissa ToolBar.jsx riveillä 336 ja 396 ehtolauseet.
  // Lukko elementti tämän komponentin lopussa.
  // useEffect(() => {
  //   if (!loading && !isAuthenticated) {
  //     navigate('/', { replace: true })
  //   }
  // }, [isAuthenticated, loading, navigate])

  const getSize = (code) => {
    let found = utils.sizes.find((el) => el.code === code)
    if (found && found.value) return found.value
    else return ''
  }

  const getColor = (code, brand) => {
    const colorValue = (code, collection) => {
      let found = utils[collection].find((el) => el.code === code)
      if (found && found.value) return found.value
      else return ''
    }
    switch (brand) {
      case 'B&C':
        return colorValue(code, 'colors_bc')
      case 'Gildan':
        return colorValue(code, 'colors_gildan')
      case 'Mukua':
        return colorValue(code, 'colors_mukua')
      case 'V-Pro':
      case 'Velilla':
        return colorValue(code, 'colors_velilla')
      case 'Bella+Canvas':
      case 'bugOff':
      case 'Cobalt Fusion':
      case 'Cobalt Gear':
      case 'Cobalt Uusio':
      case 'EXCD':
      case 'Jack&Maker':
      case 'Kariban':
      case 'Kariban Premium':
      case 'K-Up':
      case 'Kimood':
      case 'Native Spirit':
      case 'Proact':
      case 'Penduick':
      case 'Petrol':
      case 'Promodoro':
      case 'Stormtech':
      case 'WK':
      case 'X.O':
        return colorValue(code, 'colors_master')
      default:
        return ''
    }
  }

  const getColorGroup = (code, brand) => {
    const colorGroupValue = (code, collection) => {
      let found = utils[collection].find((el) => el.code === code)
      if (found && found.group) return found.group
      else return ''
    }
    switch (brand) {
      case 'B&C':
        return colorGroupValue(code, 'colors_bc')
      case 'Gildan':
        return colorGroupValue(code, 'colors_gildan')
      case 'Mukua':
        return colorGroupValue(code, 'colors_mukua')
      case 'V-Pro':
      case 'Velilla':
        return colorGroupValue(code, 'colors_velilla')
      case 'Bella+Canvas':
      case 'bugOff':
      case 'Cobalt Fusion':
      case 'Cobalt Gear':
      case 'Cobalt Uusio':
      case 'EXCD':
      case 'Jack&Maker':
      case 'Kariban':
      case 'Kariban Premium':
      case 'K-Up':
      case 'Kimood':
      case 'Native Spirit':
      case 'Proact':
      case 'Penduick':
      case 'Petrol':
      case 'Promodoro':
      case 'Stormtech':
      case 'WK':
      case 'X.O':
        return colorGroupValue(code, 'colors_master')
      default:
        return ''
    }
  }

  return (
    <div className='App'>
      <LangCtx.Provider value={lang}>
      <IntlProvider locale={lang.id} defaultLocale='fi' messages={messages}>
        <div className='alertContainer'>
          <Alert />
        </div>
        {hideCookieAlert ? null : <CookieAlert setHideCookieAlert={setHideCookieAlert} />}
        {appEnvironment ? (
          <div className='environment-info'>
            <span>Testiympäristö | Versio: {appVersion}</span>
          </div>
        ) : null}

        {!isEditor ? <ToolBar getSize={getSize} languages={languages} lang={lang} setLang={setLanguageToURL} /> : null}
        {!loading && !isEditor ? <NavBar /> : null}
        <ScrollToTop />

        <Routes>
          <Route path='/' element={<Navigate to={`/${lang.id}`} />} />
          <Route path='/:urlLang' element={<LanguageWrapper languages={languages} lang={lang} setLanguage={setLanguageToApp} />}>
            <Route index element={!loading ? <FrontPage /> : null} />
            <Route path='checkout' element={<Checkout getSize={getSize} getColor={getColor} />} />
            <Route
              path='product/:productID'
              element={<Product getSize={getSize} getColor={getColor} getColorGroup={getColorGroup} />}
            />
            <Route
              path='outlet-product/:productID'
              element={
                <OutletProduct getSize={getSize} getColor={getColor} getColorGroup={getColorGroup} />
              }
            />
            <Route path='browse/:categoryID' element={<Browse getSize={getSize} />} />
            <Route path='search/:keyword' element={<Search getSize={getSize} />} />
            <Route path='outlet' element={<OutletComponent getSize={getSize} />} />
            <Route path='account' element={<Account />} />
            <Route path='favorites' element={<Favorites />} />
            <Route path='order/:orderNumber' element={<Order getSize={getSize} />} />
            <Route path='collections' element={<Collections />} />
            <Route path='received' element={<Received />} />
            <Route path='info' element={<Info />} />
            <Route path='campaign/:cid' element={<Campaign getSize={getSize} />} />
            <Route path='campaigns' element={<Campaigns />} />
            <Route path='brands' element={<Brands />} />
            <Route path='contact-us' element={<Contact />} />
            <Route path='terms' element={<Terms />} />
            <Route path='data-protection' element={<Cpdp />} />
            <Route path='reset' element={<ResetPw1 />} />
            <Route path='reset-password' element={<ResetPw2 />} />
            <Route path='card-editor/:productID' element={<CardEditor getColor={getColor} getColorGroup={getColorGroup} />} />
            <Route path='image-editor/:productID' element={<ImageEditor getColor={getColor} getColorGroup={getColorGroup} />} />
            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
      {!isEditor ? <Footer /> : null}
      </IntlProvider>
      </LangCtx.Provider>
    </div>
  )
}

const LanguageWrapper = ({ languages, lang, setLanguage }) => {
  const { urlLang } = useParams()
  const navigate = useNavigate()
  // console.log('---------------------')
  // console.log('---------------------')
  // console.log('URL kielikoodi:', urlLang)

  useEffect(() => {
    const checkLanguage = () => {
      const languageIsValid = languages.some(l => l.id === urlLang)

      if (!languageIsValid) {
        // console.log('URL kielikoodi ei ole validi, asetetaan URL:iin Reactin aktiivinen kieli.')
        const clientWants = window.location.pathname.split('/').slice(1).join('/')
        const clientGets = clientWants ? `/${lang.id}/${clientWants}` : `/${lang.id}`
        // console.log('Yritit mennä:', clientWants)
        // console.log('Ohjaamme tänne:', clientGets)
        navigate(clientGets, { replace: true })
      } else if (lang.id !== urlLang) {
        // console.log('URL kielikoodi on validi, mutta se ei ole aktiivisena Reactissa.')
        setLanguage(languages.find(l => l.id === urlLang))
      }
      // else {
      //   console.log('URL kielikoodi ja Reactin aktiivinen kieli ovat samat ja OK!')
      // }
    }

    checkLanguage()

    // eslint-disable-next-line
  }, [navigate, lang])

  return <Outlet />
}

const mapStateToProps = (state) => ({
  product: state.product,
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
  cart: state.cart,
})

const reduxActions = {
  actionCartGet,
  actionCartUpdate,
  actionCartDelayedJob,
  actionFavoritesGet,
  actionLoadUser,
  actionProductGetDisplay,
  actionProductUutuudet,
  actionProductKatsotuimmat,
  actionCampaignGetAll,
  actionUtilsGet,
}

export default connect(mapStateToProps, reduxActions)(App)
