// React & Routing
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Redux
import { connect } from 'react-redux'
import { actionAlert } from '../../redux/actions/action.alert'
import {
  actionCartUpdate,
  actionCartUpdateFromFile,
  actionCartDelayedJob,
} from '../../redux/actions/action.cart'
import {
  actionOrderSend,
  actionConfirmAvailability,
  actionResetAvailability,
  actionOrderReset,
  actionOrderErrorReset,
} from '../../redux/actions/action.order'
import { actionLoadUser, actionAddressAdd, actionReloadUser, actionResetUserReloadState } from '../../redux/actions/action.auth'

// Bootstrap
import { Form, Button, Container, Spinner, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap'

// Datepicker
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from  'react-datepicker'
import moment from 'moment'
import fi from 'date-fns/locale/fi'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

//Utils
import { specifyProduct, specifyColor, specifySize } from '../../utils/getFunctions'
import getTranslation from '../../utils/alertTranslations'
import proxy from '../../utils/proxy'

// Styling
import './Checkout.scss'
import { useEffect } from 'react'

const Checkout = ({
  getSize,
  getColor,
  actionAlert,
  actionAddressAdd,
  actionCartUpdate,
  actionCartUpdateFromFile,
  actionCartDelayedJob,
  actionOrderSend,
  actionConfirmAvailability,
  actionResetAvailability,
  actionOrderReset,
  actionLoadUser,
  actionReloadUser,
  actionResetUserReloadState,
  actionOrderErrorReset,
  auth: { isAuthenticated, loading, user, reloadDone },
  cart: { products, totalPlusVAT, total, VAT, inSync, updated, delayedJobs },
  order: { sendSuccess, addSuccess, confirmAvailability, checkout, error },
}) => {
  const intl = useIntl()
  registerLocale('fi', fi)
  const navigate = useNavigate()
  const { urlLang } = useParams()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [orderInProgress, setOrderInProgress] = useState(false)
  const [deliveryMethod, setDeliveryMethod] = useState('0')
  const [invalidForm, setInvalidForm] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [selectedAddress, setSelectedAddress] = useState('')
  const [addressOptions, setAddressOptions] = useState(null)
  const [saveToAddressList, setSaveToAddressList] = useState(false)

  // jalkitoimitus 0 -> ei ole tarvinnut edes kysyä asiakkaalta, kaikki tuotteet löytyy
  // jalkitoimitus 1 -> tilaus toimitetaan heti, puuttuvat jälkitoimituksena
  // jalkitoimitus 2 -> kaikki tuotteet toimitetaan kerralla
  const [jalkitoimitus, setJalkitoimitus] = useState(0)

  const [theOrder, setTheOrder] = useState({
    customerNumber: '',
    deliveryNumber: '',
    name: '',
    customer: '',
    customer2: '',
    address: '',
    city: '',
    deliveryTerm: '',
    invoiceCustomer: '',
    invoiceCustomer2: '',
    invoiceAddress: '',
    invoiceCity: '',
    paymentTerm: '',
    discount: 0,
    salesAccount: '',
    date: '',
    deliveryWeek: '',
    deliveryMethod: '',
    mark: '',
    number: '',
    msg: '',
    pickupDate: '',
    products: [],
  })

  useEffect(() => {
    window.scrollTo(0, 0)

    return function cleanUp() {
      actionResetUserReloadState()
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      return navigate(`/${urlLang}`)
    } else if (!loading && isAuthenticated) {
      actionReloadUser()
    }
    // eslint-disable-next-line
  }, [isAuthenticated, loading])

  const getNextWeekdayDate = () => {
    // Create a new date object
    let date = new Date()
  
    // Add one day to the date
    date.setDate(date.getDate() + 1)
  
    // Check if the date is a Saturday or Sunday
    while (date.getDay() === 0 || date.getDay() === 6) {
      // Add another day to the date
      date.setDate(date.getDate() + 1)
    }
  
    // Return the new date
    return date
  }

  const formik = useFormik({
    initialValues: {
      orderCustomer: '',
      orderCustomer2: '',
      orderAddress: '',
      orderCity: '',
      orderMark: '',
      orderNumber: '',
      orderMsg: '',
      orderPickupDate: getNextWeekdayDate(),
    },
    validationSchema: Yup.object().shape({
      orderCustomer: Yup.string()
        .required(intl.formatMessage({ id: 'forms.required' }))
        .max(50, intl.formatMessage({ id: 'forms.max_chars_50' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
      orderCustomer2: Yup.string()
        .max(50, intl.formatMessage({ id: 'forms.max_chars_50' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        )
        .test(
          'deliverymethod-is-matkahuolto',
          intl.formatMessage({ id: 'forms.delivery_method_is_matkahuolto' }),
          (value) => validateCustomer2(deliveryMethod, value)
        ),
      orderAddress: Yup.string()
        .required(intl.formatMessage({ id: 'forms.required' }))
        .max(35, intl.formatMessage({ id: 'forms.max_chars_35' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
      orderCity: Yup.string()
        .required(intl.formatMessage({ id: 'forms.required' }))
        .max(35, intl.formatMessage({ id: 'forms.max_chars_35' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
      orderMark: Yup.string()
        .max(35, intl.formatMessage({ id: 'forms.max_chars_35' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
      orderNumber: Yup.string()
        .max(35, intl.formatMessage({ id: 'forms.customer_order_number_35' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        ),
      orderMsg: Yup.string()
        .max(1000, intl.formatMessage({ id: 'forms.max_chars_1000' }))
        .test(
          'contains-euro-char',
          intl.formatMessage({ id: 'forms.contains_euro_char' }),
          (value) => !/[€]/.test(value)
        )
        .test(
          'deliverymethod-is-other',
          intl.formatMessage({ id: 'forms.delivery_method_is_other' }),
          (value) => validateOrderMsg(deliveryMethod, value)
          ),
        orderPickupDate: Yup.string()
          .test(
            'contains-euro-char',
            intl.formatMessage({ id: 'forms.contains_euro_char' }),
            (value) => !/[€]/.test(value)
          )
          .test(
            'deliverymethod-is-pickup',
            intl.formatMessage({ id: 'forms.delivery_method_is_pickup' }),
            (value) => validateOrderPickupDate(deliveryMethod, value)
            ),
    }),
    onSubmit: () => {
      setOrderInProgress(true)
      actionConfirmAvailability(theOrder)
    },
  })

  useEffect(() => {
    if (confirmAvailability && checkout && checkout.products && checkout.products.length) {
      const someProductNotAvailable = checkout.products.map((p) => p.available).includes(false)
      if (someProductNotAvailable) {
        const notAvailableProducts = checkout.products.filter((prod) => !prod.available)
        const modalElements = notAvailableProducts.map((el, index) => (
          <Container className='checkoutModalItem' key={index}>
            <div className='section'>
              <img
                src={`${proxy}/images/${specifyProduct(el.id)}/${specifyProduct(
                  el.id
                )}-${specifyColor(el.id).replace('/', '_')}-thumbnail.jpg`}
                alt={`${specifyProduct(el.id)}-${specifyColor(el.id)}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null //estä loop
                  currentTarget.src = `${proxy}/images/placeholder.jpg`
                }}></img>
            </div>
            <div className='section'>
              <p>
                {specifyProduct(el.id)} ({getSize(specifySize(el.id))}) -{' '}
                {getColor(specifyColor(el.id), el.brand)}
              </p>
              <p><FormattedMessage id="checkout.ordered" />: {el.amount}</p>
              <p><FormattedMessage id="checkout.in_stock" />: {el.inStock}</p>
            </div>
          </Container>
        ))
        setModalContent(modalElements)
        setModalShow(true)
      } else {
        actionOrderSend(theOrder, user._id)
      }
    }
    // eslint-disable-next-line
  }, [confirmAvailability, checkout, modalShow])

  const deliveryAddressIsDefault = (customer, address, city) => {
    if (customer !== user.customer || address !== user.address || city !== user.city) return false
    else return true
  }

  useEffect(() => {
    setTheOrder({
      customerNumber: user.customerNumber,
      deliveryNumber: deliveryAddressIsDefault(formik.values.orderCustomer, formik.values.orderAddress, formik.values.orderCity) ? user.deliveryNumber : '0',
      name: user.name,
      customer: formik.values.orderCustomer,
      customer2: formik.values.orderCustomer2,
      address: formik.values.orderAddress,
      city: formik.values.orderCity,
      deliveryTerm: user.deliveryTerm,
      invoiceCustomer: user.invoiceCustomer,
      invoiceCustomer2: user.invoiceCustomer2,
      invoiceAddress: user.invoiceAddress,
      invoiceCity: user.invoiceCity,
      paymentTerm: user.paymentTerm,
      discount: user.discount,
      salesAccount: user.salesAccount,
      date: '',
      deliveryWeek: '',
      deliveryMethod: deliveryMethod,
      mark: formik.values.orderMark,
      number: formik.values.orderNumber,
      msg: formik.values.orderMsg,
      pickupDate: formik.values.orderPickupDate,
      products: products,
    })
    // eslint-disable-next-line
  }, [
    user,
    products,
    deliveryMethod,
    formik.values.orderCustomer,
    formik.values.orderCustomer2,
    formik.values.orderAddress,
    formik.values.orderCity,
    formik.values.orderMark,
    formik.values.orderNumber,
    formik.values.orderMsg,
    formik.values.orderPickupDate
  ])

  const validateOrderMsg = (deliv, value) => {
    if (deliv === '30' && !value) return false
    else return true
  }

  const validateOrderPickupDate = (deliv, value) => {
    if (deliv === '1' && (!value || value === null)) {
      return false
    } else {
    } return true
  }

  const validateCustomer2 = (deliv, value) => {
    if ((deliv === '4' || deliv === '5' || deliv === '30') && !value) return false
    else return true
  }

  const handlePickupDateChange = (date) => {
    formik.setValues({ ...formik.values, orderPickupDate: date })
  }

  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  useEffect(() => {
    if (user && user.email && reloadDone) {
      if (user.customer) formik.initialValues.orderCustomer = user.customer
      if (user.customer2) formik.initialValues.orderCustomer2 = user.customer2
      if (user.address) formik.initialValues.orderAddress = user.address
      if (user.city) formik.initialValues.orderCity = user.city
    }
    if (user && user.addressList && reloadDone) {
      const options = user.addressList.map((el, index) => (
        <option key={index} value={index + 1}>{`${el.customer}, ${el.customer2}, ${el.address}, ${el.city}`}</option>
      ))
      options.unshift(<option key={'Lisää uusi toimitusosoite'} value={'new'}><FormattedMessage id="checkout.add_new_delivery_address" /></option>)
      options.unshift(<option key={'Asiakaskortin toimitusosoite'} value={''}><FormattedMessage id="checkout.customer_card_delivery_address" /></option>)
      setAddressOptions(options)
    }
    // eslint-disable-next-line
  }, [reloadDone])

  useEffect(() => {
    if (selectedAddress === 'new') {
      formik.setFieldValue('orderCustomer', '')
      formik.setFieldValue('orderCustomer2', '')
      formik.setFieldValue('orderAddress', '')
      formik.setFieldValue('orderCity', '')
    } else if (user?.addressList && selectedAddress) {
      formik.setFieldValue('orderCustomer', user.addressList[selectedAddress - 1].customer)
      formik.setFieldValue('orderCustomer2', user.addressList[selectedAddress - 1].customer2)
      formik.setFieldValue('orderAddress', user.addressList[selectedAddress - 1].address)
      formik.setFieldValue('orderCity', user.addressList[selectedAddress - 1].city)
    } else {
      formik.setFieldValue('orderCustomer', user.customer)
      formik.setFieldValue('orderCustomer2', user.email) // Muutettu 18.10.2024 customer2 -> email
      formik.setFieldValue('orderAddress', user.address)
      formik.setFieldValue('orderCity', user.city)
    }

    // Jos toimitustapa on Nouto, toimitusosoitteisiin viiva
    if (deliveryMethod === '1') {
      if (!user.address) formik.setFieldValue('orderAddress', '-')
      if (!user.city) formik.setFieldValue('orderCity', '-')
    }
    // eslint-disable-next-line
  }, [selectedAddress, user])

  useEffect(() => {
    if (
      deliveryMethod === '0' ||
      !termsAccepted ||
      !isAuthenticated ||
      orderInProgress ||
      formik.errors.orderCustomer ||
      formik.errors.orderCustomer2 ||
      formik.errors.orderAddress ||
      formik.errors.orderCity ||
      formik.errors.orderMark ||
      formik.errors.orderNumber ||
      formik.errors.orderMsg ||
      formik.errors.orderPickupDate ||
      !products ||
      !products.length
    ) {
      setInvalidForm(true)
    } else {
      setInvalidForm(false)
    }
  }, [
    deliveryMethod,
    termsAccepted,
    isAuthenticated,
    orderInProgress,
    formik.errors,
    formik.errors.orderMsg,
    formik.errors.orderPickupDate,
    formik.errors.orderCustomer,
    formik.errors.orderCustomer2,
    formik.errors.orderAddress,
    formik.errors.orderCity,
    formik.values.orderMsg,
    formik.values.orderPickupDate,
    formik.values.orderCustomer,
    formik.values.orderCustomer2,
    formik.values.orderAddress,
    formik.values.orderCity,
    products
  ])

  useEffect(() => {
    if (error) {
      actionOrderErrorReset()
      setOrderInProgress(false)
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    if (sendSuccess) {
      actionCartUpdate([{ action: 'removeAll' }])
      if (saveToAddressList) {
        actionAddressAdd({
          customer: theOrder.customer,
          customer2: theOrder.customer2,
          address: theOrder.address,
          city: theOrder.city,
        })
      }
    }
    // eslint-disable-next-line
  }, [sendSuccess])

  useEffect(() => {
    if (sendSuccess && addSuccess) {
      actionOrderReset()
      actionLoadUser()
      setOrderInProgress(false)
      navigate(`/${urlLang}/received`)
    }
    // eslint-disable-next-line
  }, [sendSuccess, addSuccess])

  const deliveryMethodChange = (e) => {
    setDeliveryMethod(e.target.value)
    if (e.target.value === '1') {
      setSaveToAddressList(false)
      setSelectedAddress('')
      formik.setFieldValue('orderCustomer', user.customer)
      formik.setFieldValue('orderCustomer2', user.email) // Muutettu 18.10.2024 customer2 -> email
      
      if (!user.address) {
        formik.setFieldValue('orderAddress', '-')
      } else {
        formik.setFieldValue('orderAddress', user.address)
      }
      
      if (!user.city) {
        formik.setFieldValue('orderCity', '-')
      } else {
        formik.setFieldValue('orderCity', user.city)
      }
    }
    // if (e.target.value === '30') {
    //   formik.setFieldValue('orderCustomer2', '')
    // }
    if (e.target.value !== '1') {
      if (formik.values.orderAddress === '-') {
        const address = user?.addressList && selectedAddress ? user.addressList[selectedAddress - 1].address : user.address
        formik.setFieldValue('orderAddress', address)
      }
      if (formik.values.orderCity === '-') {
        const city = user?.addressList && selectedAddress ? user.addressList[selectedAddress - 1].city : user.city
        formik.setFieldValue('orderCity', city)
      }
    }
  }

  const userPaymentTermInfo = [
    {
      term: '14 pv netto',
      id: '1',
    },
    {
      term: '21 pv netto',
      id: '2',
    },
    {
      term: '30 pv netto',
      id: '3',
    },
    {
      term: '7 pv netto',
      id: '4',
    },
    {
      term: '10 pv netto',
      id: '5',
    },
    {
      term: 'Käteinen',
      id: '6',
    },
    {
      term: 'Postiennakko',
      id: '7',
    },
    {
      term: 'Hyvityslasku',
      id: '8',
    },
    {
      term: 'Sopimuksen mukaan',
      id: '9',
    },
    {
      term: 'Heti',
      id: '10',
    },
    {
      term: '14 pv - 2%',
      id: '11',
    },
    {
      term: 'Remburssi',
      id: '12',
    },
    {
      term: 'Jälkivaatimus',
      id: '13',
    },
    {
      term: '60 day nett',
      id: '14',
    },
    {
      term: '45 pv netto',
      id: '15',
    },
    {
      term: '90 pv netto',
      id: '16',
    },
    {
      term: '10 days-3% 60 days net',
      id: '17',
    },
    {
      term: '14 pv -2% 30 pv netto',
      id: '18',
    },
    {
      term: 'FOB Vantaa Finland',
      id: '19',
    },
    {
      term: '30 days -2% 60 days net',
      id: '20',
    },
    {
      term: '14 dagar netto',
      id: '21',
    },
    {
      term: '15 päivää',
      id: '22',
    },
    {
      term: '7 days -2,5% 60 days net',
      id: '23',
    },
    {
      term: '120 päivää netto',
      id: '24',
    },
    {
      term: 'Payment in advance',
      id: '25',
    },
    {
      term: '2% 10 days, 1% 30 days, 60 net',
      id: '26',
    },
  ]

  const termsLabel = (
    <p>
      <FormattedMessage id="checkout.terms_i_have_read" />{' '}
      <a href={`${proxy}/terms`} target='_blank' rel='noopener noreferrer'>
        <FormattedMessage id="checkout.terms_of_sale" />
      </a>{' '}
      <FormattedMessage id="checkout.terms_and_i_accept" /> *
    </p>
  )

  const jalkitoimitusLabelOne = intl.formatMessage({ id: "checkout.post_delivery_one" })
  const jalkitoimitusLabelTwo = intl.formatMessage({ id: "checkout.post_delivery_two" })

  const cancelSubmit = () => {
    setJalkitoimitus(0)
    setModalShow(false)
    setOrderInProgress(false)
    actionResetAvailability()
  }

  const confirmSubmit = () => {
    if (jalkitoimitus === 0) return actionAlert(intl.formatMessage({ id: 'checkout.choose_delivery_method' }), 'danger')
    const finalizedOrder = checkout
    finalizedOrder.jalkitoimitus =
      jalkitoimitus === 1 ? jalkitoimitusLabelOne : jalkitoimitusLabelTwo
    actionOrderSend(finalizedOrder, user._id)
    setModalShow(false)
    setOrderInProgress(true)
    actionResetAvailability()
  }

  const getDiscount = (prod) => {
    // Asiakaskohtainen hinta, palautetaan tyhjä
    if (!prod.discount) return ''

    // Asiakaskohtaisen ale-prosentin mukaisesti
    return `(-${prod.discount} %)`
  }

  const minusOneOrRemove = (prod) => {
    if (prod.amount === 1 && !delayedJobs.length) {
      let confirm = window.confirm(getTranslation('cart', 'confirmRemoveProduct', urlLang, prod.name))
      if (confirm) actionCartUpdate([{ action: 'remove', item: { id: prod.id } }], updated)
    } else {
      actionCartDelayedJob({ action: 'minus', item: { id: prod.id } }, delayedJobs)
    }
  }

  const removeProduct = (prod) => {
    let confirm = window.confirm(getTranslation('cart', 'confirmRemoveProduct', urlLang, prod.name))
    if (confirm) actionCartUpdate([{ action: 'remove', item: { id: prod.id } }], updated)
  }

  const countNewAmount = (id, amount) => {
    let plusJobs = delayedJobs.filter((job) => job.item.id === id && job.action === 'plus').length
    let minusJobs = delayedJobs.filter((job) => job.item.id === id && job.action === 'minus').length
    let newAmount = amount + plusJobs - minusJobs
    return newAmount > 0 ? newAmount : 1
  }

  useEffect(() => {
    formik.validateForm()
    // eslint-disable-next-line
  }, [
    deliveryMethod,
    selectedAddress,
    formik.values.orderMsg,
    formik.values.orderPickupDate,
    formik.values.orderCustomer,
    formik.values.orderCustomer2,
    formik.values.orderAddress,
    formik.values.orderCity
  ])

  const infoText = <span>
    <strong><FormattedMessage id='checkout.pickup' />:</strong><br/>
    <FormattedMessage id='checkout.pickup_description' /><br/><br/>
    <strong><FormattedMessage id='checkout.lahella_package' />:</strong><br/>
    <FormattedMessage id='checkout.lahella_package_description' /><br/><br/>
    <strong><FormattedMessage id='checkout.jakopaketti' />:</strong><br/>
    <FormattedMessage id='checkout.jakopaketti_description' /><br/><br/>
    <strong><FormattedMessage id='checkout.other_shipping_method' />:</strong><br/>
    <FormattedMessage id='checkout.other_shipping_method_description' />
  </span>

  const infoElement = 
    <OverlayTrigger
      placement='top'
      delay={{ show: 200, hide: 200 }}
      overlay={<Tooltip id='tooltip-top' className='tooltipInfoTextCheckout'>{infoText}</Tooltip>}
      >
      <span>
        <img src={`${proxy}/images/icons/info-circle-solid.svg`} alt='' className='infoIcon' />
      </span>
    </OverlayTrigger>

  // Tuotteiden lisäys tiedostosta koodit alkaa
  // Omalta laitteelta ladattavan ostoskori-tiedoston state
  const [cartFile, setCartFile] = useState(null)
  const [resetFileInForm, setResetFileInForm] = useState(false)
  const [showCartFileElements, setShowCartFileElements] = useState(false)

  // Validoi tiedostotyypin ja -koon, ja asettaa tiedoston cartFile-stateen
  const handleCartFileFormChange = (event) => {
    const file = event.target.files[0]
    if (file && (file.type === 'application/json' || file.type === 'text/csv' || file.type === 'application/vnd.ms-excel')) {
      if (file.size <= 5 * 1024 * 1024) { // max 5MB
        setCartFile(file)
      } else {
        setCartFile(null)
        alert(getTranslation('checkout', 'cartFileTooLarge', urlLang))
        setResetFileInForm(true)
      }
    } else {
      setCartFile(null)
      alert(getTranslation('checkout', 'wrongFileType', urlLang, file.type))
      setResetFileInForm(true)
    }
  }

  // Muunna CSV-tiedoston data JSON-muotoon ja poista otsikko-rivi
  const csvToJson = (csv) => {
    // Muutetaan kaikki rivinvaihdot \n-merkeiksi
    const standardizedCsv = csv.replace(/\r\n|\r|\n/g, '\n')

    let lines = standardizedCsv.split('\n')
    // Poista tyhjät merkkijonot
    lines = lines.filter(line => line.trim() !== '')

    const result = []
    const headers = lines[0].split(',')
  
    for (let i = 1; i < lines.length; i++) {
      const obj = {}
      const currentline = lines[i].split(',')
  
      for (let j = 0; j < headers.length; j++) {
        if (headers[j] === 'qty') {
          const qty = Number(currentline[j])
          if (isNaN(qty)) {
            throw new Error('qty-kentän arvo ei ole numero. / qty-field value is not a number.')
          }
          obj[headers[j]] = qty
        } else {
          obj[headers[j]] = currentline[j]
        }
      }
  
      result.push(obj)
    }
  
    return result
  }

  // Lähetä tiedoston tuotedata Redux actionille
  const addToCartFromFile = (itemsFromFile) => {
    const jobs = itemsFromFile.map((item) => ({
      action: 'add',
      item: { id: item.id, amount: item.qty },
    }))
    actionCartUpdateFromFile(jobs, updated)
    setCartFile(null)
    setResetFileInForm(true)
  }

  // Validoi ladatun tiedoston data
  const validateCartFileData = (file) => {
    const reader = new FileReader()
    reader.onload = function(e) {
      let data
      try {
        if (file.type === 'application/json') {
          data = JSON.parse(e.target.result)
        } else if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
          data = csvToJson(e.target.result)
        } else {
          setCartFile(null)
          alert(getTranslation('checkout', 'fileTypeNotSupported', urlLang))
          setResetFileInForm(true)
          return
        }
      } catch (error) {
        setCartFile(null)
        alert(getTranslation('checkout', 'fileReadError', urlLang))
        setResetFileInForm(true)
        return
      }
  
      if (!Array.isArray(data)) {
        setCartFile(null)
        alert(getTranslation('checkout', 'fileNotArray', urlLang))
        setResetFileInForm(true)
        return
      }
  
      for (const item of data) {
        if (!item.hasOwnProperty('id') || typeof item.id !== 'string' || item.id === '') {
          setCartFile(null)
          alert(getTranslation('checkout', 'fileIdNotString', urlLang))
          setResetFileInForm(true)
          return
        }
  
        if (!item.hasOwnProperty('qty') || typeof item.qty !== 'number' || item.qty < 1) {
          setCartFile(null)
          alert(getTranslation('checkout', 'fileQtyNotNumber', urlLang))
          setResetFileInForm(true)
          return
        }
      }
  
      addToCartFromFile(data)
    }
    reader.readAsText(file)
  }

  // Jos cartFile saa arvon, on siellä tiedosto odottamassa sen tietojen lukua
  useEffect(() => {
    if (cartFile) {
      validateCartFileData(cartFile)
    }
    // eslint-disable-next-line
  }, [cartFile])
  // Tuotteiden lisäys tiedostosta koodit päättyy

  useEffect(() => {
    if (selectedAddress === '') {
      setSaveToAddressList(false)
    } else if (selectedAddress === 'new') {
      setSaveToAddressList(true)
    } else {
      setSaveToAddressList(false)
    }
  }, [selectedAddress])

  return (
    <div className='Checkout'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'checkout.title' })} | dc-collection</title>
      </Helmet>
      <Modal
        size='lg'
        className='checkoutModal'
        centered
        backdrop='static'
        keyboard={false}
        show={modalShow}
        onHide={() => setModalShow(false)}>
        <Modal.Body>
          <Modal.Title>{intl.formatMessage({ id: 'checkout.products_not_in_stock' })}</Modal.Title>
          <p>
            {intl.formatMessage({ id: 'checkout.products_not_in_stock_description' })}
          </p>
          {modalContent}
          <div className='checkoutModalButtons'>
            <Form.Group>
              <Form.Check
                name='jalkitoimitus'
                type='checkbox'
                label={jalkitoimitusLabelOne}
                checked={jalkitoimitus === 1 ? true : false}
                onChange={() => setJalkitoimitus(1)}
              />
              <Form.Check
                name='jalkitoimitus'
                type='checkbox'
                label={jalkitoimitusLabelTwo}
                checked={jalkitoimitus === 2 ? true : false}
                onChange={() => setJalkitoimitus(2)}
              />
            </Form.Group>
            <div>
              <Button
                variant='primary'
                className={!jalkitoimitus ? 'disabledButton' : ''}
                disabled={!jalkitoimitus}
                onClick={confirmSubmit}>
                <FormattedMessage id='checkout.confirm_order' />
              </Button>
              <Button variant='secondary' onClick={cancelSubmit}>
                <FormattedMessage id='checkout.return_to_checkout' />
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <h4>
        <strong><FormattedMessage id='checkout.title' /></strong>
      </h4>
      <Container className='checkoutTitle'>
        <div className='headerCol'>
          <h6>
            <strong><FormattedMessage id='checkout.product' /></strong>
          </h6>
        </div>
        <div className='headerCol'>
          <h6>
            <strong><FormattedMessage id='checkout.name_color_size' /></strong>
          </h6>
        </div>
        <div className='headerCol'>
          <h6>
            <strong><FormattedMessage id='checkout.quantity' /></strong>
          </h6>
        </div>
        <div className='headerCol'>
          <h6>
            <strong><FormattedMessage id='checkout.ovh_price' /></strong>
          </h6>
        </div>
        <div className='headerCol'>
          <h6>
            <strong><FormattedMessage id='checkout.net_price_discount' /></strong>
          </h6>
        </div>
        <div className='headerCol'>
          <h6>
            <strong><FormattedMessage id='checkout.total' /></strong>
          </h6>
        </div>
        <div style={{ width: '5%' }}></div>
      </Container>
      {products && inSync
        ? products.map((el, index) => (
            <>
              <Container className='checkoutItem' key={index}>
                <div className='checkoutCol'>
                  <img
                    src={`${proxy}/images/${specifyProduct(el.id)}/${specifyProduct(
                      el.id
                    )}-${specifyColor(el.id).replace('/', '_')}-thumbnail.jpg`}
                    alt={`${specifyProduct(el.id)}-${specifyColor(el.id)}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null //estä loop
                      currentTarget.src = `${proxy}/images/placeholder.jpg`
                    }}></img>
                  <Link to={`/product/${specifyProduct(el.id)}`}>{specifyProduct(el.id)}</Link>
                </div>
                <div className='checkoutCol'>
                  <p>{el.name}</p>
                  <p>
                    {getColor(specifyColor(el.id), el.brand)} {getSize(specifySize(el.id))}
                  </p>
                </div>
                <div className='amountContainer checkoutCol'>
                  <div className='amountContents'>
                    <p>
                      {delayedJobs.length
                        ? countNewAmount(products[index].id, el.amount)
                        : el.amount}{' '}
                      kpl
                    </p>
                    <div className='plusMinusContainer'>
                      <Button
                        variant='outline-primary'
                        className='plusMinusBtn'
                        onClick={() =>
                          actionCartDelayedJob(
                            { action: 'plus', item: { id: products[index].id } },
                            delayedJobs
                          )
                        }>
                        +
                      </Button>
                      <Button
                        variant='outline-primary'
                        className='plusMinusBtn'
                        onClick={() => minusOneOrRemove(products[index])}>
                        -
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='checkoutCol'>
                  <p>
                    {el.oldPrice ?
                      <span className={'strikethrough'}>{el.oldPrice} € </span>
                      : null}
                    <span style={el.oldPrice ? { color: 'red' } : {}}>{el.price.toFixed(2).replace('.', ',')}{' €'}</span>
                  </p>
                </div>
                <div className='checkoutCol'>
                  <p>
                    {el.salePrice ? `${el.salePrice.toFixed(2).replace('.', ',')} €` : '-'}{' '}
                    {getDiscount(el)}
                  </p>
                </div>
                <div className='checkoutCol'>
                  <p>
                    <strong>
                      {el.salePrice
                        ? `${parseFloat((el.salePrice * el.amount).toFixed(2))
                            .toFixed(2)
                            .replace('.', ',')} €`
                        : `${parseFloat((el.price * el.amount).toFixed(2))
                            .toFixed(2)
                            .replace('.', ',')} €`}
                    </strong>
                  </p>
                </div>
                <div className='checkoutCol'>
                  <Button variant='outline-danger' onClick={() => removeProduct(el)}>
                    x
                  </Button>
                </div>
              </Container>
              <div className='mobilePriceRow'>
                <div className='checkoutCol'>
                  <p><FormattedMessage id='checkout.ovh_price' />:</p>
                  <p>
                  {el.oldPrice ?
                      <span className={'strikethrough'}>{el.oldPrice}{' € '}</span>
                      : null}
                    <span style={el.oldPrice ? { color: 'red' } : {}}>{el.price.toFixed(2).replace('.', ',')}{' €'}</span>
                  </p>
                </div>
                <div className='checkoutCol'>
                  <p><FormattedMessage id='checkout.net_price_discount' />:</p>
                  <p>
                    {el.salePrice ? `${el.salePrice.toFixed(2).replace('.', ',')} €` : '-'}{' '}
                    {getDiscount(el)}
                  </p>
                </div>
                <div className='checkoutCol'>
                  <p>
                    <strong><FormattedMessage id='checkout.total' />:</strong>
                  </p>
                  <p>
                    <strong>
                      {el.salePrice
                        ? `${parseFloat((el.salePrice * el.amount).toFixed(2))
                            .toFixed(2)
                            .replace('.', ',')} €`
                        : `${parseFloat((el.price * el.amount).toFixed(2))
                            .toFixed(2)
                            .replace('.', ',')} €`}
                    </strong>
                  </p>
                </div>
              </div>
            </>
          ))
        : null}
      <div className='checkoutTotal'>
        <div>
          <p><FormattedMessage id='checkout.without_tax' />: {total} €</p>
          <p><FormattedMessage id='checkout.vat' /> {VAT && VAT !== '0,00' ? '25,5%': '0%'}: {VAT} €</p>
          <p style={{ fontSize: '16px' }}>
            <strong><FormattedMessage id='checkout.total' />: {totalPlusVAT} €</strong>
          </p>
        </div>
      </div>
      {/* <hr style={{ marginTop: '30px' }} /> */}

      {isAuthenticated ? (
      <form onSubmit={formik.handleSubmit}>
        <div className='form-col'>

          <p style={{ margin: '30px auto 10px 0', textAlign: 'left', maxWidth: '400px' }}><FormattedMessage id='checkout.required_fields' /></p>

          <Form.Group>
            <label htmlFor='orderMark'><FormattedMessage id='forms.mark' /></label>
            <input
              name='orderMark'
              placeholder='...'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.orderMark}
            />
            {formik.errors.orderMark ? (
              <p className='validation-error'>{formik.errors.orderMark}</p>
            ) : null}
          </Form.Group>

          <Form.Group>
            <label htmlFor='orderNumber'><FormattedMessage id='forms.customer_order_number' /></label>
            <input
              name='orderNumber'
              placeholder='...'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.orderNumber}
            />
            {formik.errors.orderNumber ? (
              <p className='validation-error'>{formik.errors.orderNumber}</p>
            ) : null}
          </Form.Group>

          <Form.Group id='orderMsg'>
            <label htmlFor='orderMsg'>
              <FormattedMessage id='forms.message_for_order' />{deliveryMethod === '30' ? ' *' : null}
            </label>
            <textarea
              type='text'
              rows='5'
              name='orderMsg'
              placeholder='...'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.orderMsg}
            />
            {formik.errors.orderMsg ? (
              <p className='validation-error'>{formik.errors.orderMsg}</p>
            ) : null}
          </Form.Group>

        <label htmlFor='checkoutInformation'><FormattedMessage id='forms.billing_address' /></label>
        <div className='checkoutInformation' style={{ marginBottom: '0px' }}>
          <p>{user.customer}</p>
          <p>{user.invoiceAddress}</p>
        </div>
        <div id='paymentTerm'>
        <label htmlFor='userPaymentInformation'><FormattedMessage id='forms.payment_term' /></label>
          <div className='userPaymentInformation'>
            {userPaymentTermInfo
              .filter((term) => term.id === user.paymentTerm)
              .map((filteredTerm) => (
                <p key={filteredTerm.term}>{filteredTerm.term}</p>
              ))}
          </div>
        </div>
        </div>
        <div className='form-col'>
        <Form.Group className='deliveryMethod' style={{ margin: '0' }}>
            <Form.Label><FormattedMessage id='forms.delivery_method' /> * {infoElement}</Form.Label>
            <Form.Control
              name='sort'
              type='text'
              as='select'
              value={deliveryMethod}
              onChange={deliveryMethodChange}>
              <option value='0'><FormattedMessage id='forms.choose' /></option>
              <option value='1'><FormattedMessage id='forms.pickup' /></option>
              <option value='4'><FormattedMessage id='forms.jakopaketti' /></option>
              <option value='5'><FormattedMessage id='forms.lahella_paketti' /></option>
              <option value='30'><FormattedMessage id='forms.other_delivery_method' /></option>
            </Form.Control>
          </Form.Group>

          {deliveryMethod === '30' ? (
            <p
              style={{
                color: '#007bff',
                width: 'fit-content',
                margin: '5px auto',
                maxWidth: '400px',
                textAlign: 'left'
              }}>
                <FormattedMessage id='forms.delivery_method_is_other' />
            </p>
          ) : null}

          <Form.Group className='deliveryAddress' hidden={deliveryMethod === '0'}>
            <label htmlFor='selectedAddress'><FormattedMessage id='forms.delivery_address' /></label>
            <Form.Control
              name='selectedAddress'
              type='text'
              as='select'
              className={deliveryMethod === '1' ? 'disabled' : ''}
              style={{ margin: '0 auto' }}
              disabled={deliveryMethod === '1'}
              value={selectedAddress}
              onChange={(e) => setSelectedAddress(e.target.value)}>
              {addressOptions}
            </Form.Control>

            <label htmlFor='orderCustomer'><FormattedMessage id='forms.order_customer' /> *</label>
            <input
              name='orderCustomer'
              placeholder='...'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.orderCustomer}
              disabled={selectedAddress === ''}
              className={selectedAddress === '' ? 'disabled' : ''}
            />
            {formik.errors.orderCustomer ? (
              <p className='validation-error'>{formik.errors.orderCustomer}</p>
            ) : null}

            <Form.Group className='orderPickupDate' style={{ margin: '0' }} hidden={deliveryMethod !== '1'}>
            <label htmlFor='orderPickupDate'><FormattedMessage id='forms.pickup_date' /> *</label>
            <DatePicker
            name='orderPickupDate'
            locale='fi'
            dateFormat="dd.MM.yyyy"
            calendarStartDay={1}
            selected={formik.values.orderPickupDate}
            placeholderText={intl.formatMessage({id: 'forms.select_pickup_date'})}
            minDate={moment().toDate()}
            filterDate={isWeekday}
            onChange={(date) => handlePickupDateChange(date)}
            />
            {formik.errors.orderPickupDate ? (
              <p className='validation-error'>{formik.errors.orderPickupDate}</p>
            ) : null}
            </Form.Group>

            <label htmlFor='orderCustomer2' hidden={deliveryMethod === '1'}>
              {(deliveryMethod === '4' || deliveryMethod === '5' || deliveryMethod === '30') ?
              <span><FormattedMessage id='forms.order_customer2' /> *</span>
              : <FormattedMessage id='forms.order_customer2' />}
            </label>
            <input
              name='orderCustomer2'
              placeholder='...'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={deliveryMethod === '1' ? 'disabled' : ''}
              disabled={deliveryMethod === '1'}
              hidden={deliveryMethod === '1'}
              value={formik.values.orderCustomer2}
            />
            {formik.errors.orderCustomer2 ? (
              <p className='validation-error'>{formik.errors.orderCustomer2}</p>
            ) : null}

            <label htmlFor='orderAddress' hidden={deliveryMethod === '1'}><FormattedMessage id='forms.order_address' /> *</label>
            <input
              name='orderAddress'
              placeholder='...'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hidden={deliveryMethod === '1'}
              value={formik.values.orderAddress}
              disabled={selectedAddress === ''}
              className={selectedAddress === '' ? 'disabled' : ''}
            />
            {formik.errors.orderAddress ? (
              <p className='validation-error'>{formik.errors.orderAddress}</p>
            ) : null}

            <label htmlFor='orderCity' hidden={deliveryMethod === '1'}><FormattedMessage id='forms.order_city' /> *</label>
            <input
              name='orderCity'
              placeholder='...'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hidden={deliveryMethod === '1'}
              value={formik.values.orderCity}
              disabled={selectedAddress === ''}
              className={selectedAddress === '' ? 'disabled' : ''}
            />
            {formik.errors.orderCity ? (
              <p className='validation-error'>{formik.errors.orderCity}</p>
            ) : null}

            <Form.Check
              name='saveToAddressList'
              className='saveToAddressList'
              type='checkbox'
              label={deliveryMethod === '1' || selectedAddress === '' || selectedAddress === 'new' ? null : intl.formatMessage({id: 'forms.save_delivery_address'})}
              hidden={deliveryMethod === '1' || selectedAddress === '' || selectedAddress === 'new'}
              checked={saveToAddressList}
              onChange={() => setSaveToAddressList(!saveToAddressList)}
              disabled={selectedAddress === ''}
            />
          </Form.Group>

          {/* <hr style={{marginTop: '30px'}}/> */}
          <div className='checkoutTerms'>
            <Form.Group>
              <Form.Check
                name='terms'
                type='checkbox'
                label={termsLabel}
                defaultChecked={termsAccepted}
                value={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
            </Form.Group>
          </div>
          <Button
            variant='dark'
            type='submit'
            className={invalidForm ? 'buyButton disabledButton' : 'buyButton'}
            style={{ textTransform: 'uppercase' }}
            disabled={invalidForm}>
            {orderInProgress ? (
              <>
                <FormattedMessage id='checkout.order_in_process' />
                <Spinner animation='border' variant='light' size='sm' />
              </>
            ) : (
              <FormattedMessage id='checkout.confirm_order' />
            )}
          </Button>
          </div>
        </form>
      ) : null}

      <div className='cartTemplatesContainer'>
        <Button
        variant='outline-secondary'
        id='cart-file-title-button'
        onClick={() => setShowCartFileElements(!showCartFileElements)}
        >
          <FormattedMessage id='checkout.add_products_from_file' />
        </Button>
        {showCartFileElements ? <><div>
        <Form id='upload-cart-file'>
          <Form.Group>
            <Form.File 
              id="cart-file-input"
              label=""
              accept=".csv, .json"
              onChange={handleCartFileFormChange}
              value={resetFileInForm ? '' : undefined}
            />
          </Form.Group>
        </Form>
        </div>
        <Button variant='outline-primary' onClick={() => window.location.href = (`${proxy}/api/public/cart-template-csv`)}>
          <FormattedMessage id='checkout.download_csv_template' />
        </Button>
        <Button variant='outline-primary' onClick={() => window.location.href = (`${proxy}/api/public/cart-template-json`)}>
          <FormattedMessage id='checkout.download_json_template' />
        </Button>
        </> : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
  order: state.order,
})

const reduxActions = {
  actionAlert,
  actionAddressAdd,
  actionCartUpdate,
  actionCartUpdateFromFile,
  actionCartDelayedJob,
  actionOrderSend,
  actionConfirmAvailability,
  actionResetAvailability,
  actionOrderReset,
  actionLoadUser,
  actionReloadUser,
  actionResetUserReloadState,
  actionOrderErrorReset
}

export default connect(mapStateToProps, reduxActions)(Checkout)
