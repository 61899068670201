import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

// Localization
import { FormattedMessage } from 'react-intl'

// Styling
import './NotFound.scss'
import warning from './../../images/warning.svg'

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='notFoundContainer'>
      <h4><FormattedMessage id='general.page_not_found' /></h4>
      <img src={warning} alt='Varoitus' width='80' height='80' style={{ margin: '32px auto' }} />
      <Link to='/'>
        <p><FormattedMessage id='general.go_to_homepage' /></p>
      </Link>
    </div>
  )
}

export default NotFound
