// React & Routing
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Styling
import './Brands.scss'

const Brands = () => {
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='Brands'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'brands_page.title' })} | dc-collection</title>
      </Helmet>
      <h4>
        <strong><FormattedMessage id="brands_page.title" /></strong>
      </h4>

      <div className='BrandsContainer'>
        <p className='p0'>BELLA+CANVAS</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.bella_canvas" />
        </p>
        <p className='p0'>B&amp;C</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.bc" />
        </p>
        <p className='p0'>BUGOFF</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.bugoff" />
        </p>
        <p className='p0'>COBALT FUSION</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.cobalt_fusion" />
        </p>
        <p className='p0'>COBALT GEAR&nbsp;</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.cobalt_gear1" />
        </p>
        <p className='p1'>
          <FormattedMessage id="brands_page.cobalt_gear2" />
        </p>
        <p className='p0'>COBALT UUSIO</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.cobalt_uusio" />
        </p>
        <p className='p0'>GILDAN</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.gildan" />
        </p>
        <p className='p0'>KARIBAN</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.kariban" />
        </p>
        <p className='p0'>KIMOOD</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.kimoood" />
        </p>
        <p className='p0'>K-UP</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.kup" />
        </p>
        <p className='p0'>BLACK&amp;MATCH</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.black_match" />
        </p>
        <p className='p0'>PROACT</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.proact" />
        </p>
        <p className='p0'>PROMODORO</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.promodoro" />
        </p>
        <p className='p0'>STORMTECH</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.stormtech" />
        </p>
        <p className='p0'>VELILLA</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.velilla" />
        </p>
        <p className='p0'>WK Designed to work</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.wk" />
        </p>
        <p className='p0'>X.O</p>
        <p className='p1'>
          <FormattedMessage id="brands_page.xo" />
        </p>
      </div>
    </div>
  )
}

export default connect()(Brands)
