import axios from 'axios'
import proxy from '../../utils/proxy'
import { CONTACT_SUCCESS, CONTACT_RESET } from './types'
import { actionAlert } from './action.alert'
import getTranslation from '../../utils/alertTranslations'

// Lähetä yhteydenottolomake
export const actionSendContactForm = (obj) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify(obj)

  try {
    const res = await axios.post(`${proxy}/api/public/contact-form`, body, config)

    if (res.status === 200) {
      const langCode = window.location.pathname?.split('/')[1]
      dispatch(
        actionAlert(getTranslation('contact', 'thanks', langCode), 'desperado')
      )

      dispatch({
        type: CONTACT_SUCCESS,
      })
    }
    if (res.status === 400) {
      const langCode = window.location.pathname?.split('/')[1]
      dispatch(actionAlert(getTranslation('contact', 'error', langCode), 'danger'))
    }
  } catch (err) {
    console.error(err)
    const langCode = window.location.pathname?.split('/')[1]
    dispatch(actionAlert(getTranslation('contact', 'error', langCode), 'danger'))
    dispatch({
      type: CONTACT_RESET,
    })
  }
}

export const actionContactReset = () => (dispatch) => {
  dispatch({
    type: CONTACT_RESET,
  })
}
