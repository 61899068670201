// React & Routing
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Localization
import { useIntl } from 'react-intl'

// Styling
import './Cpdp.scss'

const Cpdp = () => {
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='Cpdp'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'footer.data_protection' })} | dc-collection</title>
      </Helmet>
      <div className='DataProtection'>
        <h4>
          <strong>{intl.formatMessage({ id: 'footer.data_protection' })}</strong>
        </h4>
        <p className='p1'>
          <span className='s1'>
            Desperado Clothing Oy:n henkil&ouml;- ja yritystietojen k&auml;sittely&auml; koskevat
            periaatteet, kun henkil&ouml;tietoja ker&auml;t&auml;&auml;n asiakassuhteeseen perustuen
            tai dc-collection.fi verkkosivuille rekister&ouml;idylt&auml;.
            <span className='Apple-converted-space'>&nbsp;</span>
          </span>
        </p>
        <p className='p2'>
          <br />
        </p>
        <p className='p3'>
          <span className='s1'>
            <strong>HENKIL&Ouml;TIETOJEN K&Auml;SITTELY&Auml; KOSKEVAT PERIAATTEET</strong>
          </span>
        </p>
        <br />
        <p className='p3'>
          <span className='s1'>
            <strong>I. Rekisterinpit&auml;j&auml;</strong>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Desperado Clothing Oy, Kis&auml;llintie 9, 01730 Vantaa, Y 0942112-7 puh. (9) 2766 8612,
            <span className='Apple-converted-space'>&nbsp;</span>
          </span>
        </p>
        <br />
        <p className='p3'>
          <span className='s1'>
            <strong>II. Henkil&ouml;tietojen k&auml;sittelyn perusteet</strong>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Desperado Clothing Oy k&auml;sittelee henkil&ouml;tietoja tietosuoja-asetuksen 6
            artiklan kohdan mukaisesti seuraavin perustein:
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            <strong>Suostumus:</strong> Voimme k&auml;sitell&auml; annettuja tai havaittuja tietoja
            suostumuksen perusteella tai alla mainitun oikeutetun edun perusteella muun muassa
            markkinointitarkoituksessa. Mik&auml;li k&auml;sittelyn tarkoitus muuttuu, pyyd&auml;mme
            asiakkaalta siihen suostumuksen. Henkil&ouml;tietojen antaminen perustuu asiakassuhteen
            luomisen yhteydess&auml; tai verkkosivuille rekister&ouml;innin yhteydess&auml;
            annettuihin tietoihin, jotta voimme palvella asiakkaitamme paremmin.
            <span className='Apple-converted-space'>&nbsp;</span>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            <strong>Sopimus:</strong> K&auml;sittelemme annettuja tietoja
            t&auml;ytt&auml;&auml;ksemme sopimuksen mukaiset velvoitteemme palvelusta tai
            tuotteesta, joka meilt&auml; on tilattu. Henkil&ouml;tietojen antaminen perustuu silloin
            sopimukseen, jotta Desperado Clothing Oy voi toimia sopimuksen mukaisesti ja laskuttaa
            sopimuksen mukaisista palveluista. Sopimuksen toteuttaminen ei
            p&auml;&auml;s&auml;&auml;nt&ouml;isesti ole mahdollista ilman, ett&auml; Desperado
            Clothing Oy k&auml;sittelisi yritysasiakkuuteen liittyvi&auml; henkil&ouml;tietoja.
            Henkil&ouml;tietojen toimittaminen on p&auml;&auml;s&auml;&auml;nt&ouml;isesti
            sopimuksen tekemisen edellytys.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            <strong>Oikeutettu etu:</strong> K&auml;sittelemme annettuja tietoja palveluiden
            toteuttamiseen, liiketoiminnan ja asiakaspalvelun hoitamiseen ja kehitt&auml;miseen,
            v&auml;&auml;rink&auml;yt&ouml;sten est&auml;miseen ja selvitt&auml;miseen sek&auml;
            markkinointiin. N&auml;m&auml; tarkoitukset ovat Desperado Clothing Oy:n liiketoiminnan
            kannalta v&auml;ltt&auml;m&auml;tt&ouml;mi&auml;, ja siten Desperado Clothing Oy:n
            oikeutetun edun mukaisia. Etu on lainmukainen (elinkeinovapaus).
          </span>
        </p>
        <br />
        <p className='p3'>
          <span className='s1'>K&auml;ytt&ouml;tarkoitus ja tietol&auml;hteet</span>
        </p>
        <p className='p3'>
          <span className='s1'>
            <strong>
              III. Mit&auml; tietoja ker&auml;&auml;mme ja mihin tarkoitukseen tiedot
              ker&auml;t&auml;&auml;n
            </strong>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Rekisterimme sis&auml;lt&auml;&auml; tietoja Desperado Clothing Oy:n asiakkaista ja
            potentiaalisista asiakkaista.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Ker&auml;&auml;mme vain ennalta m&auml;&auml;riteltyjen k&auml;ytt&ouml;tarkoitusten
            kannalta tarpeellisia henkil&ouml;tietoja. Ker&auml;&auml;mme annettuja tietoja muun
            muassa palvelun toimittamiseksi ja laskuttamiseksi, asiakassuhteen hoitamiseksi ja
            kehitt&auml;miseksi, asiakastuen hoitamiseksi, liiketoiminnan kehitt&auml;miseksi ja
            suunnittelemiseksi, myynnin edist&auml;miseksi sek&auml; markkinointiin.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Ker&auml;&auml;mme my&ouml;s annettuja tietoja kohdentaaksemme sis&auml;lt&ouml;&auml;
            ja markkinointia asiakkaiden mieltymyksi&auml; paremmin vastaavaksi.
          </span>
        </p>
        <p className='p2'>
          <br />
        </p>
        <p className='p3'>
          <span className='s1'>Ker&auml;&auml;mme seuraavia tietoja:</span>
        </p>
        <ul className='ul1'>
          <li className='li3'>
            <span className='s1'>
              Yhteystiedot, kuten nimi, osoite, puhelinnumero, s&auml;hk&ouml;postiosoite
            </span>
          </li>
          <li className='li3'>
            <span className='s1'>
              Asiakassuhdetta koskevat tiedot, kuten laskutus- ja maksutiedot, tuote- ja
              tilaustiedot, asiakaspalautteet ja yhteydenotot sek&auml; peruutustiedot
            </span>
          </li>
          <li className='li3'>
            <span className='s1'>
              Yksi rekister&ouml;it&auml;v&auml;&auml;n liitett&auml;v&auml; tunnistetieto
            </span>
          </li>
          <li className='li3'>
            <span className='s1'>Luvat ja suostumukset</span>
          </li>
          <li className='li3'>
            <span className='s1'>Estotiedot</span>
          </li>
          <li className='li3'>
            <span className='s1'>Muut suostumuksella ker&auml;tyt tiedot</span>
          </li>
        </ul>
        <p className='p3'>
          <span className='s1'>
            Rekisteri voi sis&auml;lt&auml;&auml; my&ouml;s ilmoitettuja kiinnostusalueita koskevia
            tietoja.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Kolmannet osapuolet, jotka ovat pyyt&auml;neet henkil&ouml;tietojen luovuttamiseen
            asiakkaan suostumuksen luovuttavat meille tietoja. T&auml;llaisia kolmansia osapuolia
            ovat esimerkiksi tavarantoimittajat.
            <span className='Apple-converted-space'>&nbsp;</span>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Rekisteriss&auml; olevia nimi- ja osoitetietoja p&auml;ivitet&auml;&auml;n lis&auml;ksi
            osoitetietoj&auml;rjestelmist&auml; tietojen t&auml;sm&auml;llisyyden varmistamiseksi.
          </span>
        </p>
        <br />
        <p className='p3'>
          <span className='s1'>
            <strong>
              V. Tietojen s&auml;&auml;nn&ouml;nmukaiset luovutukset ja tietojen siirto EU:n ja
              ETA:n ulkopuolelle
            </strong>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Henkil&ouml;tietoja ei luovuteta kolmansille osapuolille muutoin kuin voimassaolevan
            lains&auml;&auml;d&auml;nn&ouml;n sallimissa tai velvoittamissa rajoissa.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Henkil&ouml;tietoja voidaan siirt&auml;&auml; tai luovuttaa, mik&auml;li asiakas on
            antanut nimenomaisen suostumuksen t&auml;h&auml;n.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Voimme my&ouml;s luovuttaa henkil&ouml;tietoja ulkopuolisille yrityksille,
            organisaatioille tai henkil&ouml;ille, mik&auml;li tietojen k&auml;ytt&ouml;,
            s&auml;ilytt&auml;minen tai paljastaminen on tarpeen sopimuksen
            t&auml;yt&auml;nt&ouml;&ouml;n panemiseksi, mahdollisten rikkomusten tutkimiseksi taikka
            laillisten oikeuksiemme suojelemiseksi.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Desperado Clothing Oy voi luovuttaa tietoja kolmansille osapuolille. T&auml;ss&auml;
            kohdassa tarkoitettuja kolmansia osapuolia ovat Desperado Clothing Oy:n
            yhteisty&ouml;kumppanit, kuten tavarantoimittajat, maksunvalvonta ja luottorekisterin
            yll&auml;pit&auml;j&auml;t, verkkokauppa-alustan toimittajat ja
            yll&auml;pit&auml;j&auml;t, markkinointipostitusta tarjoavat yhteisty&ouml;kumppanit.
            <span className='Apple-converted-space'>&nbsp;</span>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Tavarantoimittajat k&auml;ytt&auml;v&auml;t annettuja tietoja tuotemerkkimarkkinointiin.
            Verkkokauppa-alustan toimittajat ja yll&auml;pit&auml;j&auml;t sek&auml;
            markkinointipostitusta tarjoavat yhteisty&ouml;kumppanit k&auml;sittelev&auml;t
            annettuja henkil&ouml;tietoja vain Desperado Clothing Oy:n toimeksiantoon perustuen.
            <span className='Apple-converted-space'>&nbsp;</span>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>Emme siirr&auml; tietoja EU:n tai ETA-alueen ulkopuolelle.</span>
        </p>
        <br />
        <p className='p3'>
          <span className='s1'>
            <strong>V. Tietojen s&auml;ilytysaika</strong>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            S&auml;ilyt&auml;mme tietojasi vain niin kauan kuin se on tarpeen yll&auml;
            m&auml;&auml;riteltyjen tarkoitusten toteuttamiseksi kulloinkin voimassaolevan
            lains&auml;&auml;d&auml;nn&ouml;n mukaisesti.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Mik&auml;li k&auml;sittely perustuu suostumukseen, k&auml;sittelemme tietoja, kunnes
            asiakas on esimerkiksi peruuttanut uutiskirjeen tilauksen tai kunnes asiakas on
            peruuttanut suostumuksen. &nbsp;
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Markkinointirekisteriss&auml; tietoja s&auml;ilytet&auml;&auml;n
            l&auml;ht&ouml;kohtaisesti niin kauan kuin henkil&ouml; toimii sellaisissa
            ty&ouml;teht&auml;viss&auml; tai sellaisessa asemassa, joihin markkinoitava tuote tai
            palvelu liittyy, eik&auml; h&auml;n ole kielt&auml;ytynyt vastaanottamasta
            markkinointiviestint&auml;&auml;. Mik&auml;li asiakas kielt&auml;ytyy markkinoinnista,
            s&auml;ilyt&auml;mme tiedon kiellosta ja yhteystiedoista, jotta voimme varmistaa kiellon
            noudattamisen.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Mik&auml;li k&auml;sittelemme tietoja sopimuksen johdosta, k&auml;sittelemme tietoja
            niin kauan kuin meill&auml; on voimassa oleva sopimus. Sopimuksen p&auml;&auml;ttymisen
            j&auml;lkeen k&auml;sittelemme tietoja kyseisen tilikauden aikana liiketoiminnallisista
            syist&auml;. Laskutustiedot s&auml;ilyt&auml;mme v&auml;hint&auml;&auml;n 10 vuotta.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Mik&auml;li olemme velvoitettuja s&auml;ilytt&auml;m&auml;&auml;n henkil&ouml;tietoja
            noudattaaksemme kirjanpito- tai muuta pakottavaa lains&auml;&auml;d&auml;nt&ouml;&auml;
            my&ouml;s asiakassuhteen tai muun henkil&ouml;tietojen k&auml;sittelyperusteen
            p&auml;&auml;ttymisen j&auml;lkeen toimimme lains&auml;&auml;d&auml;nn&ouml;n
            mukaisesti.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>&nbsp;</span>
        </p>
        <p className='p3'>
          <span className='s1'>Tietosuoja</span>
        </p>
        <p className='p3'>
          <span className='s1'>
            <strong>VI. Tietojen suojaaminen</strong>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Rekisteri on s&auml;hk&ouml;isess&auml; muodossa. K&auml;yt&auml;mme tarpeellisia
            teknisi&auml; ja organisatorisia tietoturvakeinoja henkil&ouml;tietojen suojaamiseksi
            oikeudetonta p&auml;&auml;sy&auml;, luovuttamista, h&auml;vitt&auml;mist&auml; tai muuta
            oikeudetonta k&auml;sittely&auml; vastaan. Tiedot on suojattu muassa palomuurien,
            salaustekniikoiden, turvallisten laitetilojen k&auml;yt&ouml;n ja asianmukaisen
            kulunvalvonnan avulla.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Rekisteriin on k&auml;ytt&ouml;oikeus vain niill&auml; rekisterinpit&auml;j&auml;n
            palveluksessa olevilla ja muilla m&auml;&auml;ritellyill&auml; henkil&ouml;ill&auml;,
            jotka tarvitsevat tietoja teht&auml;viss&auml;&auml;n. Heill&auml; on
            k&auml;yt&ouml;ss&auml;&auml;n k&auml;ytt&auml;j&auml;tunnukset ja salasanat ja
            heit&auml; on ohjeistettu henkil&ouml;tietojen k&auml;sittelyyn liittyviss&auml;
            kysymyksiss&auml;. Valitsemme my&ouml;s alihankkijamme ja sopimuskumppanimme
            huolellisesti.
          </span>
        </p>
        <br />
        <p className='p3'>
          <span className='s1'>
            <strong>VII. Rekister&ouml;idyn oikeudet</strong>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Tietojen tarkastaminen, korjaaminen, siirt&auml;minen ja poistaminen: Asiakkaalla ja/tai
            henkil&ouml;ll&auml; on oikeus tarkastaa yrityskohtaisesti tallennetut
            henkil&ouml;tiedot. Oikaisemme, poistamme tai t&auml;ydenn&auml;mme k&auml;sittelyn
            tarkoituksen kannalta virheelliset, tarpeettomat, puutteelliset tai vanhentuneet
            henkil&ouml;tiedot pyynn&ouml;st&auml;. Asiakkaan tulee tehd&auml; pyynt&ouml;
            allekirjoitetussa tai sit&auml; vastaavalla tavalla varmennetussa asiakirjassa taikka
            henkil&ouml;kohtaisesti rekisterinpit&auml;j&auml;n luona.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Suoramarkkinoinnin ja siihen liittyv&auml;n profiloinnin kielto-oikeus: Asiakas voi
            milloin tahansa kielt&auml;&auml; tietojen luovuttamisen ja k&auml;sittelyn
            suoramarkkinointia varten painamalla s&auml;hk&ouml;postinviestin lopussa olevaa
            linkki&auml; tai ilmoittaa kielt&auml;ytymisest&auml; osoitteessa{' '}
            <a href='mailto:desperado@desperado.fi'>
              <span className='s3'>desperado@desperado.fi</span>
            </a>
            .
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Vastustamisoikeus, oikeus rajoittaa k&auml;sittely&auml; ja suostumuksen peruuttaminen:
            Asiakas voi peruuttaa suostumuksen milloin tahansa osoitteessa{' '}
            <a href='mailto:desperado@desperado.fi'>
              <span className='s3'>desperado@desperado.fi</span>
            </a>
            . Asiakkaalla on oikeus vaatia henkil&ouml;tietojen poistamista, mik&auml;li
            henkil&ouml;tietoja ei en&auml;&auml; tarvita niihin tarkoituksiin, joita varten ne on
            ker&auml;tty, henkil&ouml;tietoja k&auml;sitell&auml;&auml;n lainvastaisesti sek&auml;
            mik&auml;li henkil&ouml;tiedot on poistettava rekisterinpit&auml;j&auml;&auml;n
            sovellettavan lakis&auml;&auml;teisen velvoitteen noudattamiseksi. &nbsp;Asiakkaalla on
            oikeus vaatia k&auml;sittelyn rajoittamista, mik&auml;li asiakas kiist&auml;&auml;
            henkil&ouml;tietojen paikkansapit&auml;vyyden ja/tai henkil&ouml;tietoja
            k&auml;sitell&auml;&auml;n lainvastaisesti
          </span>
        </p>
        <br />
        <p className='p3'>
          <span className='s1'>
            <strong>VIII. Oikeutesi tehd&auml; valitus valvontaviranomaiselle&nbsp;</strong>
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>
            Asiakkaalla on rekister&ouml;ityn&auml; oikeus saattaa asia tietosuojavaltuutetun
            k&auml;sitelt&auml;v&auml;ksi, jos katsoo, ett&auml; henkil&ouml;tietojen
            k&auml;sittelyss&auml; rikotaan sit&auml; koskevaa
            lains&auml;&auml;d&auml;nt&ouml;&auml;.
          </span>
        </p>
        <p className='p3'>
          <span className='s1'>Valvontaviranomaisen yhteystiedot ovat:</span>
        </p>
        <p className='p1'>
          <span className='s1'>Tietosuojavaltuutetun toimisto</span>
        </p>
        <p className='p1'>
          <span className='s1'>K&auml;yntiosoite: Ratapihantie 9, 6. krs. 00520 Helsinki</span>
        </p>
        <p className='p1'>
          <span className='s1'>Postiosoite: PL 800, 00521 Helsinki</span>
        </p>
        <p className='p1'>
          <span className='s1'>Vaihde: 029 56 66700</span>
        </p>
        <p className='p1'>
          <span className='s1'>S&auml;hk&ouml;posti: tietosuoja(at)om.fi</span>
        </p>
      </div>
    </div>
  )
}

export default connect()(Cpdp)
