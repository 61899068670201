// React & Routing
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Bootstrap
import { Button, Row } from 'react-bootstrap'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Styling
import './Received.scss'

// Utils
import proxy from '../../utils/proxy'

const Received = () => {
  const intl = useIntl()
  return (
    <div className='Received'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'order_received_page.title' })} | dc-collection</title>
      </Helmet>
      <h4>
        <strong>{intl.formatMessage({ id: 'order_received_page.title' })}!</strong>
      </h4>
      <img src={`${proxy}/images/icons/check-circle-regular.svg`} alt='' id='receivedCheckIcon' />
      <p>
        <FormattedMessage id='order_received_page.thank_you_message' />
      </p>
      <Row>
        <Link to='/account'>
          <Button variant='dark'>
            <FormattedMessage id='toolbar.my_account' />
          </Button>
        </Link>
        <Link to='/'>
          <Button variant='dark'>
            <FormattedMessage id='general.homepage' />
          </Button>
        </Link>
      </Row>
      <hr />
      <h5>
        <FormattedMessage id='order_received_page.any_questions' />
      </h5>
      <p>
        <FormattedMessage id='order_received_page.any_questions_description' />
      </p>
      <Row id='contactUsRow'>
        <Link to='/contact-us'>
          <Button variant='outline-primary'>
            <FormattedMessage id='footer.contact_us' />
          </Button>
        </Link>
      </Row>
    </div>
  )
}

export default connect()(Received)
