import React, { useEffect, useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from 'react-bootstrap'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

const Form2 = (props) => {
  const { submitForm, success } = props
  const formikRef = useRef()
  const intl = useIntl()

  useEffect(() => {
    if (success) {
      formikRef.current.resetForm()
    }
  }, [success])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, intl.formatMessage({ id: 'contact_page.name_too_short' }))
      .max(100, intl.formatMessage({ id: 'contact_page.name_too_long' }))
      .required(intl.formatMessage({ id: 'contact_page.name_required' })),
    company: Yup.string()
      .min(2, intl.formatMessage({ id: 'contact_page.company_too_short' }))
      .max(100, intl.formatMessage({ id: 'contact_page.company_too_long' }))
      .required(intl.formatMessage({ id: 'contact_page.company_required' })),
    companyId: Yup.string()
      .max(9, intl.formatMessage({ id: 'contact_page.company_id_invalid' }))
      .matches(/^[\d]{7}-[\d]$/, intl.formatMessage({ id: 'contact_page.company_id_invalid' }))
      .required(intl.formatMessage({ id: 'contact_page.company_id_required' })),
    phone: Yup.string()
      .min(6, intl.formatMessage({ id: 'contact_page.phone_number_too_short' }))
      .max(13, intl.formatMessage({ id: 'contact_page.phone_number_too_long' }))
      .matches(/^[+]?[0-9]*$/, intl.formatMessage({ id: 'contact_page.phone_number_specials' }))
      .required(intl.formatMessage({ id: 'contact_page.phone_number_required' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'contact_page.email_invalid' }))
      .required(intl.formatMessage({ id: 'contact_page.required' })),
    website: Yup.string()
      .min(5, intl.formatMessage({ id: 'contact_page.website_too_short' }))
      .max(50, intl.formatMessage({ id: 'contact_page.website_too_long' })),
    message: Yup.string()
      .min(5, intl.formatMessage({ id: 'contact_page.message_too_short' }))
      .max(1000, intl.formatMessage({ id: 'contact_page.message_too_long' }))
      .required(intl.formatMessage({ id: 'contact_page.message_required' })),
  })

  return (
    <div className='contactForm'>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          company: '',
          companyId: '',
          phone: '',
          email: '',
          website: '',
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(values)
        }}>
        {({ errors, touched }) => (
          <Form>
            <p style={{ margin: '30px auto 0 auto', maxWidth: '500px' }}>
              <FormattedMessage id='contact_page.looking_for_retailers' />
            </p>

            <label htmlFor='name'>{intl.formatMessage({ id: 'contact_page.name' })} *</label>
            <Field id='name' name='name' placeholder='...' />
            {errors.name && touched.name ? <p className='validation-error'>{errors.name}</p> : null}

            <label htmlFor='company'>{intl.formatMessage({ id: 'contact_page.company' })} *</label>
            <Field id='company' name='company' placeholder='...' />
            {errors.company && touched.company ? (
              <p className='validation-error'>{errors.company}</p>
            ) : null}

            <label htmlFor='companyId'>{intl.formatMessage({ id: 'contact_page.company_id' })} *</label>
            <Field id='companyId' name='companyId' placeholder='...' />
            {errors.companyId && touched.companyId ? (
              <p className='validation-error'>{errors.companyId}</p>
            ) : null}

            <label htmlFor='phone'>{intl.formatMessage({ id: 'contact_page.phone' })} *</label>
            <Field id='phone' name='phone' placeholder='...' type='tel' />
            {errors.phone && touched.phone ? (
              <p className='validation-error'>{errors.phone}</p>
            ) : null}

            <label htmlFor='email'>{intl.formatMessage({ id: 'contact_page.email' })} *</label>
            <Field id='email' name='email' placeholder='esimerkki@esimerkki.fi' type='email' />
            {errors.email && touched.email ? (
              <p className='validation-error'>{errors.email}</p>
            ) : null}

            <label htmlFor='website'>{intl.formatMessage({ id: 'contact_page.website' })} </label>
            <Field id='website' name='website' placeholder='...' />
            {errors.website && touched.website ? (
              <p className='validation-error'>{errors.website}</p>
            ) : null}

            <label htmlFor='message'>{intl.formatMessage({ id: 'contact_page.message' })} *</label>
            <Field as='textarea' rows='5' id='message' name='message' placeholder='...' />
            {errors.message && touched.message ? (
              <p className='validation-error'>{errors.message}</p>
            ) : null}

            <p>{intl.formatMessage({ id: 'contact_page.required_fields' })}</p>

            <Button type='submit' variant='dark'>
              {intl.formatMessage({ id: 'contact_page.send' })}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Form2
