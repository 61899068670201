// React & Routing
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'
import { actionAlert } from '../../redux/actions/action.alert'
import { actionCartUpdate } from '../../redux/actions/action.cart'
import {
  actionOrderSelect,
  actionOrderGetOne,
  actionOrderCopyData,
  actionOrderCopyReset,
} from '../../redux/actions/action.order'

// Bootstrap
import { Button, Spinner, Container, Col } from 'react-bootstrap'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Styling
import { ReactComponent as CopyIcon } from '../../images/copy.svg'
import './Order.scss'

// Utils
import moment from 'moment'
import proxy from '../../utils/proxy'
import { specifyProduct, specifyColor, specifySize } from '../../utils/getFunctions'

const Order = ({
  getSize,
  cart,
  user,
  order,
  actionAlert,
  actionCartUpdate,
  actionOrderSelect,
  actionOrderGetOne,
  actionOrderCopyData,
  actionOrderCopyReset,
}) => {
  const { orderNumber } = useParams()
  const intl = useIntl()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState(undefined)

  // Tarkistetaan että url:n tilausnumero löytyy kirjautuneen käyttäjän tilauksista
  // Jos löytyy --> valitaan kyseisen tilauksen objekti reduxiin order.selected
  // Jos ei löydy --> yritetään hakea url:n tilausnumero suoraan Novasta
  useEffect(() => {
    const validOrder = user.orders && user.orders.some((e) => e === orderNumber)
    if (validOrder) {
      if (order.all && order.all.length) {
        const orderData = order.all.filter((e) => e.number === orderNumber)
        actionOrderSelect(orderData[0])
      } else {
        actionOrderGetOne(orderNumber)
      }
    } else {
      setData({})
    }

    // eslint-disable-next-line
  }, [orderNumber, user, order.all])

  // Reduxiin valitun tilauksen tiedot haetaan Reactin stateen
  useEffect(() => {
    if (order.selected) {
      setData(order.selected)
      setProducts(order.selected.products)
      setLoading(false)
    }
  }, [order.selected])

  useEffect(() => {
    if (order.copySuccess) {
      const jobs = order.copyProducts.map((p) => ({
        action: 'add',
        item: { id: p.id, amount: p.amount },
      }))
      actionCartUpdate(jobs, cart.updated)
      if (order.copyProducts.length !== products.length) {
        actionAlert(intl.formatMessage({ id: 'account_page.some_products_changed' }), 'danger')
      }
      actionOrderCopyReset()
    }
    // eslint-disable-next-line
  }, [order.copySuccess])

  const statusKasittelyssa = <p style={{ color: '#ffa500', fontWeight: '600' }}><FormattedMessage id='account_page.status_processing' /></p>
  const statusOsatoimitettu = <p style={{ color: '#ffa500', fontWeight: '600' }}><FormattedMessage id='account_page.status_partial_delivery' /></p>
  const statusOsalaskutettu = <p style={{ color: '#ffa500', fontWeight: '600' }}><FormattedMessage id='account_page.status_partial_invoice' /></p>
  const statusLaskutettu = <p style={{ color: '#ffa500', fontWeight: '600' }}><FormattedMessage id='account_page.status_invoiced' /></p>
  const statusToimitettu = <p style={{ color: '#74c515', fontWeight: '600' }}><FormattedMessage id='account_page.status_delivered' /></p>

  const addToCart = () => {
    const allProducts = products.map((el) => ({ id: el.id, amount: el.amount }))
    actionOrderCopyData(allProducts)
  }

  return (
    <div className='Order'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'account_page.order' })} | dc-collection</title>
      </Helmet>
      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}>
          <Spinner animation='border' id='customSpinner' />
        </div>
      ) : (
        <div className='orderContainer'>
          <div className='orderCol1'>
            <p>
              <strong>{intl.formatMessage({ id: 'account_page.order_number' })}:</strong> {data.number}
            </p>
            <p>
              <strong>{intl.formatMessage({ id: 'account_page.order_received' })}:</strong> {moment(data.date).format('DD.MM.YYYY')}
            </p>
          </div>
          <div className='orderCol1'>
            <p>
              <strong>{intl.formatMessage({ id: 'account_page.order_status' })}:</strong>
            </p>
            {parseInt(data.status) === 0 ? statusKasittelyssa : null}
            {parseInt(data.status) === 64 ? statusOsatoimitettu : null}
            {parseInt(data.status) === 16384 ? statusOsalaskutettu : null}
            {parseInt(data.status) === 2 ? statusLaskutettu : null}
            {parseInt(data.status) === 128 ? statusToimitettu : null}
          </div>
          <br />

          <div className='orderCol2'>
            <p>
              <strong>{intl.formatMessage({ id: 'forms.billing_address' })}:</strong>
            </p>
            <p>{data.invoiceName}</p>
            <p>{data.invoiceName2}</p>
            <p>{data.invoiceAddress}</p>
            <p>{data.invoiceCity}</p>
            <br />

            <p>
              <strong>{intl.formatMessage({ id: 'forms.delivery_address' })}:</strong>
            </p>
            <p>{data.deliveryName}</p>
            <p>{data.deliveryName2}</p>
            <p>{data.deliveryAddress}</p>
            <p>{data.deliveryCity}</p>
            <br />
          </div>
          <div className='orderCol2'>
            <p className='orderSubTitle'>
              <strong>{intl.formatMessage({ id: 'forms.subscriber' })}:</strong>
            </p>
            <p>{data.reference}</p>

            <p className='orderSubTitle'>
              <strong>{intl.formatMessage({ id: 'forms.mark' })}:</strong>
            </p>
            <p>{data.mark}</p>

            <p className='orderSubTitle'>
              <strong>{intl.formatMessage({ id: 'forms.customer_order_number' })}:</strong>
            </p>
            <p>{data.customersOrderNumber}</p>

            <p className='orderSubTitle'>
              <strong>{intl.formatMessage({ id: 'forms.message' })}:</strong>
            </p>
            <p>{data.message}</p>
          </div>

          <br />

          <div className='orderCol3'>
            <p>
              {intl.formatMessage({ id: 'account_page.taxless_sum' })}:{' '}
              <span style={{ minWidth: '100px' }}>
                {parseFloat(data.totalExcludeTax).toFixed(2).replace('.', ',')} €
              </span>
            </p>
            <p>{intl.formatMessage({ id: 'account_page.total_vat' })}: {parseFloat(data.totalTax).toFixed(2).replace('.', ',')} €</p>
            <p>
              <strong>{intl.formatMessage({ id: 'account_page.total_price' })}: {parseFloat(data.total).toFixed(2).replace('.', ',')} €</strong>
            </p>
          </div>

          <div className='orderCol4'>
            <div className='flex-row productsHeader'>
              <p>
                <strong>{intl.formatMessage({ id: 'account_page.products' })}</strong>
              </p>
              <Button variant='outline-primary' onClick={addToCart}>
                <span>{intl.formatMessage({ id: 'account_page.copy_order_to_cart' })}</span>
                <CopyIcon className='copy-icon' />
              </Button>
            </div>

            <Container className='orderProductsTitle'>
              <Col>
                <h6>
                  <strong>{intl.formatMessage({ id: 'account_page.color' })}</strong>
                </h6>
              </Col>
              <Col>
                <h6>
                  <strong>{intl.formatMessage({ id: 'account_page.product' })}</strong>
                </h6>
              </Col>
              <Col>
                <h6>
                  <strong>{intl.formatMessage({ id: 'account_page.quantity' })}</strong>
                </h6>
              </Col>
              <Col>
                <h6>
                  <strong>{intl.formatMessage({ id: 'account_page.unit_price' })}</strong>
                </h6>
              </Col>
              <Col>
                <h6>
                  <strong>{intl.formatMessage({ id: 'account_page.total_price' })}</strong>
                </h6>
              </Col>
            </Container>

            {products
              ? products.map((el, index) => (
                  <Container className='orderItem' key={index}>
                    <Col>
                      <img
                        src={`${proxy}/images/${specifyProduct(el.id)}/${specifyProduct(
                          el.id
                        )}-${specifyColor(el.id).replace('/', '_')}-thumbnail.jpg`}
                        alt={`${specifyProduct(el.id)}-${specifyColor(el.id)}`}
                        width='90'
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null //estä loop
                          currentTarget.src = `${proxy}/images/placeholder.jpg`
                        }}></img>
                    </Col>
                    <Col>
                      <Link to={`/product/${specifyProduct(el.id)}`}>
                        {specifyProduct(el.id)} ({getSize(specifySize(el.id))})
                      </Link>
                    </Col>
                    <Col>
                      <p>{el.amount} {intl.formatMessage({ id: 'checkout.piece' })}</p>
                    </Col>
                    <Col>
                      <p style={{ marginBottom: '0' }}>
                        {parseFloat((el.totalPrice / el.amount).toFixed(2))
                          .toFixed(2)
                          .replace('.', ',')}{' '}
                        €
                      </p>
                    </Col>
                    <Col>
                      <p style={{ marginBottom: '0' }}>
                        <strong>{parseFloat(el.totalPrice).toFixed(2).replace('.', ',')} €</strong>
                      </p>
                    </Col>
                  </Container>
                ))
              : null}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  user: state.auth.user,
  order: state.order,
})

const reduxActions = {
  actionAlert,
  actionCartUpdate,
  actionOrderSelect,
  actionOrderGetOne,
  actionOrderCopyData,
  actionOrderCopyReset,
}

export default connect(mapStateToProps, reduxActions)(Order)
