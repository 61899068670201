import React, { useEffect, useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from 'react-bootstrap'

// Localization
import { useIntl } from 'react-intl'

const FormAskAboutProduct = (props) => {
  const { submitForm, success } = props
  const formikRef = useRef()
  const intl = useIntl()
  useEffect(() => {
    if (success) {
      formikRef.current.resetForm()
    }
  }, [success])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, intl.formatMessage({ id: 'contact_page.name_too_short' }))
      .max(100, intl.formatMessage({ id: 'contact_page.name_too_long' }))
      .required(intl.formatMessage({ id: 'contact_page.name_required' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'contact_page.email_invalid' }))
      .required(intl.formatMessage({ id: 'contact_page.required' })),
    message: Yup.string()
      .min(5, intl.formatMessage({ id: 'contact_page.message_too_short' }))
      .max(1000, intl.formatMessage({ id: 'contact_page.message_too_long' }))
      .required(intl.formatMessage({ id: 'contact_page.message_required' })),
  })

  return (
    <div className='contactForm'>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          message: '',
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(values)
        }}>
        {({ errors, touched }) => (
          <Form>
            <label htmlFor='name'>{intl.formatMessage({ id: 'contact_page.name' })} *</label>
            <Field id='name' name='name' placeholder={intl.formatMessage({ id: 'contact_page.name_placeholder' })} />
            {errors.name && touched.name ? <p className='validation-error'>{errors.name}</p> : null}

            <label htmlFor='email'>{intl.formatMessage({ id: 'contact_page.email' })} *</label>
            <Field id='email' name='email' placeholder='esimerkki@esimerkki.fi' type='email' />
            {errors.email && touched.email ? (
              <p className='validation-error'>{errors.email}</p>
            ) : null}

            <label htmlFor='message'>{intl.formatMessage({ id: 'product.message' })} *</label>
            <Field
              as='textarea'
              rows='5'
              id='message'
              name='message'
              placeholder={intl.formatMessage({ id: 'product.message_placeholder' })}
            />
            {errors.message && touched.message ? (
              <p className='validation-error'>{errors.message}</p>
            ) : null}

            <p>{intl.formatMessage({ id: 'contact_page.required_fields' })}</p>

            <Button type='submit' variant='dark'>
              {intl.formatMessage({ id: 'contact_page.send' })}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FormAskAboutProduct
