// React & Routing
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import {
  actionCheckResetCodeValidity,
  actionChangePasswordWithCode,
} from '../../redux/actions/action.auth'

// Bootstrap
import { Form, Button, Spinner } from 'react-bootstrap'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Styling
import eye from '../../images/eye-regular.svg'
import eyeSlash from '../../images/eye-slash-regular.svg'
import './ResetPw.scss'

const ResetPw = ({
  isAuthenticated,
  pwResetCodeValid,
  actionCheckResetCodeValidity,
  actionChangePasswordWithCode,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { urlLang } = useParams()
  const resetCode = window.location.search.substring(1)
  const [codeIsValid, setCodeIsValid] = useState(undefined)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [hidePw1, setHidePw1] = useState(true)
  const [hidePw2, setHidePw2] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (isAuthenticated || !resetCode) {
      navigate(`/${urlLang}`, { replace: true })
    }
    actionCheckResetCodeValidity(resetCode)
    // eslint-disable-next-line
  }, [isAuthenticated])

  useEffect(() => {
    setCodeIsValid(pwResetCodeValid)
  }, [pwResetCodeValid])

  const onSubmit = (e) => {
    e.preventDefault()
    actionChangePasswordWithCode(resetCode, password, password2)
  }

  return (
    <div className='ResetPw'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'reset_pw_page.title2' })} | dc-collection</title>
      </Helmet>
      <h4>
        <strong>{intl.formatMessage({ id: 'reset_pw_page.title2' })}</strong>
      </h4>
      {codeIsValid === undefined ? (
        <Spinner animation='border' id='customSpinner' style={{ margin: '2rem auto' }} />
      ) : null}
      {codeIsValid === false ? (
        <p>
          <FormattedMessage id='reset_pw_page.link_invalid' />
        </p>
      ) : null}
      {codeIsValid === true ? (
        <>
          <p>
            <FormattedMessage id='reset_pw_page.hint2' />
          </p>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id='reset_pw_page.new_password' />
              </Form.Label>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Control
                  name='password'
                  type={hidePw1 ? 'password' : 'text'}
                  autoComplete='new-password'
                  placeholder='...'
                  onChange={(e) => setPassword(e.target.value)}
                />
                {hidePw1 ? (
                  <Button
                    tabIndex='-1'
                    variant='link'
                    className='pw-eye'
                    onClick={() => setHidePw1(false)}>
                    <img src={eye} alt={intl.formatMessage({ id: 'toolbar.show_password' })} />
                  </Button>
                ) : (
                  <Button
                    tabIndex='-1'
                    variant='link'
                    className='pw-eye'
                    onClick={() => setHidePw1(true)}>
                    <img src={eyeSlash} alt={intl.formatMessage({ id: 'toolbar.hide_password' })} />
                  </Button>
                )}
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id='reset_pw_page.new_password_again' />
              </Form.Label>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Control
                  name='password2'
                  type={hidePw2 ? 'password' : 'text'}
                  autoComplete='new-password'
                  placeholder='...'
                  onChange={(e) => setPassword2(e.target.value)}
                />
                {hidePw2 ? (
                  <Button
                    tabIndex='-1'
                    variant='link'
                    className='pw-eye'
                    onClick={() => setHidePw2(false)}>
                    <img src={eye} alt={intl.formatMessage({ id: 'toolbar.show_password' })} />
                  </Button>
                ) : (
                  <Button
                    tabIndex='-1'
                    variant='link'
                    className='pw-eye'
                    onClick={() => setHidePw2(true)}>
                    <img src={eyeSlash} alt={intl.formatMessage({ id: 'toolbar.hide_password' })} />
                  </Button>
                )}
              </div>
            </Form.Group>
            <Button variant='black' type='submit' onSubmit={onSubmit}>
              <FormattedMessage id='contact_page.send' />
            </Button>
          </Form>
        </>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  pwResetCodeValid: state.auth.pwResetCodeValid,
})

const reduxActions = {
  actionCheckResetCodeValidity,
  actionChangePasswordWithCode,
}

export default connect(mapStateToProps, reduxActions)(ResetPw)
