// React & Routing
import React, { useContext, useState, useEffect } from 'react'
import LangCtx from '../../LangCtx'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'
import { actionFavoritesAdd, actionRecentsGet } from '../../redux/actions/action.auth'
import { actionProductGetOutlet } from '../../redux/actions/action.product'

// Components
import Filter from '../../components/Filter/Filter'
import PaginationComponent from '../../components/Pagination/Pagination'

// Bootstrap
import { Breadcrumb, Container, Form, Button, Spinner } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Utils
import proxy from '../../utils/proxy'
import { filterDataWithInStockVariantsOnly, formatStormtechCode, parseQuery } from '../../utils/getFunctions'
import { createItemButtons } from '../../components/ItemButton/ItemButton'

// Styling
import bars from '../../images/bars-solid.svg'
import './ItemBrowse.scss'

const Outlet = ({
  getSize,
  product: { outletList, outletListLoadingDone },
  favorites,
  recents,
  utils,
  prevRecentsQuery,
  isAuthenticated,
  actionProductGetOutlet,
  actionRecentsGet,
  actionFavoritesAdd,
}) => {
  let wls = window.location.search
  const source = 'outlet'
  const intl = useIntl()
  const lang = useContext(LangCtx)

  const [loading, setLoading] = useState(true)
  const [sort, setSort] = useState(undefined)
  const [currentPage, setCurrentPage] = useState(undefined)
  const [defaultList, setDefaultList] = useState([])
  const [itemList, setItemList] = useState([])
  const [itemButtons, setItemButtons] = useState(undefined)
  const [pages, setPages] = useState(1)
  const [recentsButtons, setRecentsButtons] = useState(undefined)
  const [showFilter, setShowFilter] = useState(false)

  // isMobile mini component starts
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate()
  const { urlLang } = useParams()

  const updateWindowDimensions = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return function cleanUp() {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (screenWidth && screenWidth > 1024 && isMobile) {
      setIsMobile(false)
    } else if (screenWidth && screenWidth < 1025 && !isMobile) {
      setIsMobile(true)
    }
  }, [screenWidth, setScreenWidth, isMobile])
  // isMobile mini component ends

  useEffect(() => {
    setLoading(true)
    setItemButtons(undefined)
    actionProductGetOutlet()
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (outletListLoadingDone && outletList.length > 0) {
      setDefaultList(filterDataWithInStockVariantsOnly(outletList))
      setItemList(outletList)
    } else if (outletListLoadingDone && outletList.length === 0) {
      const noProducts = [<></>]
      setItemButtons(noProducts)
    }
    // eslint-disable-next-line
  }, [outletList])

  useEffect(() => {
    if (!isMobile) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
    // eslint-disable-next-line
  }, [currentPage])

  useEffect(() => {
    let lsRecents = localStorage.getItem('recently-viewed')
    let reduxAndLsMatch = false
    let prevQueryAndLsMatch = false

    if (lsRecents) {
      reduxAndLsMatch =
        recents
          .map((el) => el.tuoteKoodi)
          .sort()
          .join(',') === JSON.parse(lsRecents).sort().join(',')

      prevQueryAndLsMatch =
        prevRecentsQuery.sort().join(',') === JSON.parse(lsRecents).sort().join(',')
    }

    if (!lsRecents) {
      setRecentsButtons(null)
    } else if ((!recents || !recents.length) && lsRecents) {
      actionRecentsGet(JSON.parse(lsRecents))
    } else if ((recents && recents.length && lsRecents && reduxAndLsMatch) || prevQueryAndLsMatch) {
      const elements = recents.map((el) => (
        <Link
          className='recentBtnLink animated fadeInUp'
          to={`/product/${el.tuoteKoodi}`}
          key={el.tuoteKoodi}>
          <div className='recentContainer' key={el.tuoteKoodi}>
            <div className='recentColOne'>
              <img
                src={`${proxy}/images/${el.tuoteKoodi}/${el.tuoteKoodi}-paakuva-thumbnail.jpg`}
                alt=''
                height='80'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null //estä loop
                  currentTarget.src = `${proxy}/images/placeholder.jpg`
                }}
              />
            </div>
            <div className='recentColTwo'>
              <p>
                {el.brandi === 'Stormtech' ? formatStormtechCode(el.tuoteKoodi) : el.tuoteKoodi}
              </p>
              <p style={{ fontWeight: '500' }}>{el.tuoteNimi}</p>
              <p>
                {intl.formatMessage({ id: 'general.recent_price' })}{' '}
                <span style={{ color: '#0067E0', fontWeight: '600' }}>
                  {Math.min(...el.variaatiot.map((el2) => el2.ovh))
                    .toFixed(2)
                    .replace('.', ',')}{' '}
                  €
                </span>
              </p>
            </div>
          </div>
        </Link>
      ))
      setRecentsButtons(elements)
    } else if (!prevQueryAndLsMatch) {
      actionRecentsGet(JSON.parse(lsRecents))
    } else {
      setRecentsButtons(null)
    }
    // eslint-disable-next-line
  }, [recents])

  const compareName = (a, b) => {
    if (a.tuoteNimi < b.tuoteNimi) {
      return -1
    }
    if (a.tuoteNimi > b.tuoteNimi) {
      return 1
    }
    return 0
  }

  const comparePrice = (a, b) => {
    if (a.startingPrice < b.startingPrice) {
      return -1
    }
    if (a.startingPrice > b.startingPrice) {
      return 1
    }
    return 0
  }

  useEffect(() => {
    if (itemList && sort) {
      switch (sort) {
        case 'Nimi (A-Ö)':
          itemList.sort(compareName)
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated,
              lang
            )
          )
          break
        case 'Nimi (Ö-A)':
          itemList.sort(compareName).reverse()
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated,
              lang
            )
          )
          break
        case 'Hinta (halvin ensin)':
          itemList.sort(comparePrice)
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated,
              lang
            )
          )
          break
        case 'Hinta (kallein ensin)':
          itemList.sort(comparePrice).reverse()
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated,
              lang
            )
          )
          break
        default:
          itemList.sort(compareName)
          setItemButtons(
            createItemButtons(
              itemList,
              isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
              false,
              actionFavoritesAdd,
              null,
              isAuthenticated,
              lang
            )
          )
          break
      }
    }
    // eslint-disable-next-line
  }, [sort, itemList, favorites, actionFavoritesAdd, isAuthenticated, lang.id])

  useEffect(() => {
    if (itemButtons && itemButtons.length > 0) {
      setLoading(false)
    } else if (defaultList.length === 0) {
      setLoading(false)
    } else if (defaultList.length > 0) {
      setLoading(false)
    } else if (itemButtons === undefined) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [itemButtons, defaultList])

  useEffect(() => {
    const howManyPages = Math.ceil(itemList.length / 20)
    setPages(howManyPages)
    if (itemList.length > 0 && howManyPages < currentPage) {
      setCurrentPage(1)
    }
    // eslint-disable-next-line
  }, [itemList])

  const sortProducts = (e) => {
    const q = parseQuery(window.location.search)
    navigate(`/${urlLang}/${source}?page=${q.page}&sort=${e.target.value}&price=${q.price}&filter=${q.filter}`)
  }

  // Takaisin painikkeella skrollaus samaan y-koordinaattiin
  let scrollMemory = JSON.parse(localStorage.getItem('scroll-memory'))
  const [scrollDone, setScrollDone] = useState(false)

  useEffect(() => {
    if (
      scrollMemory &&
      !scrollDone &&
      scrollMemory.y <= document.body.scrollHeight &&
      scrollMemory.body === document.body.scrollHeight &&
      scrollMemory.prevPage === window.location.href
    ) {
      window.scrollTo(0, scrollMemory.y)
      setScrollDone(true)
      localStorage.removeItem('scroll-memory')
    }
    // eslint-disable-next-line
  }, [document.body.scrollHeight])
  // Päättyy

  const changePage = (n) => {
    const q = parseQuery(window.location.search)
    q.page = n
    navigate(`/${urlLang}/${source}?page=${q.page}&sort=${q.sort}&price=${q.price}&filter=${q.filter}`)
  }

  const title = 'Outlet'
  const [htmlTitle, setHtmlTitle] = useState('')

  useEffect(() => {
    if (window.location.search && title) {
      const q = parseQuery(window.location.search)
      const filterValues = Object.values(JSON.parse(q.filter)).filter((el) => el.length > 0)

      if (filterValues && filterValues.length) {
        setHtmlTitle(
          `${title} | ${filterValues.map((el) => el[0]).join(', ')} | ${intl.formatMessage({ id: 'general.page' })} ${q.page} | ${
            q.sort
          } | dc-collection`
        )
      } else {
        setHtmlTitle(`${title} | ${intl.formatMessage({ id: 'general.page' })} ${q.page} | ${q.sort} | dc-collection`)
      }
    }
  }, [wls, title, intl])

  return (
    <div className='ItemBrowse'>
      <Helmet>
        <title>{htmlTitle ? htmlTitle : 'dc-collection'}</title>
      </Helmet>
      <Breadcrumb>
        <LinkContainer to='/'>
          <Breadcrumb.Item>{intl.formatMessage({ id: 'general.homepage' })}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{intl.formatMessage({ id: 'categories.outlet' })}</Breadcrumb.Item>
      </Breadcrumb>

      <Container className='topBox'>
        <h1>{intl.formatMessage({ id: 'categories.outlet' })}</h1>
      </Container>

      <Container className='categoryContainer'>
        <div
          className={isMobile ? 'mobile-filter' : 'categoryCol1'}
          hidden={isMobile && !showFilter}>
          <Filter
            itemList={itemList}
            setItemList={setItemList}
            defaultList={defaultList}
            category={source}
            source={source}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sort={sort}
            setSort={setSort}
            setShowFilter={setShowFilter}
            title={'Outlet'}
            getSize={getSize}
            utils={utils}
          />

          <div className='recentlyViewedBox' hidden={isMobile || !recentsButtons}>
            <h6 style={{ textTransform: 'uppercase' }}>
              <FormattedMessage id='general.recently_viewed' />
            </h6>
            <div className='recentlyViewedButtons'>{recentsButtons}</div>
          </div>
        </div>

        <div className='categoryCol2'>
          <div className='topBox2'>
            <p>
              <FormattedMessage id='filter.products_shown' />:{' '}
              {itemButtons ? `${itemList.length}` : '-'}
              {/* {itemButtons ? `${itemList.length} / ${outletList.length}` : '- / -'} */}
            </p>
            <Button
              variant='light'
              className='mobile-only mobile-filter-toggle'
              style={{ textTransform: 'uppercase' }}
              onClick={() => setShowFilter(!showFilter)}>
              <FormattedMessage id='filter.title_mobile' />
              <img src={bars} alt='bars' width='13' height='13' style={{ marginLeft: '5px' }} />
            </Button>
            <Form.Group className='sortForm'>
              <Form.Label><FormattedMessage id='filter.order' />:</Form.Label>
              <Form.Control
                name='sort'
                type='text'
                as='select'
                value={sort}
                onChange={sortProducts}>
                <option value="Nimi (A-Ö)"><FormattedMessage id="filter.name_alphabet" /></option>
                <option value="Nimi (Ö-A)"><FormattedMessage id="filter.name_alphabet_reverse" /></option>
                <option value="Hinta (halvin ensin)"><FormattedMessage id="filter.price_low" /></option>
                <option value="Hinta (kallein ensin)"><FormattedMessage id="filter.price_high" /></option>
              </Form.Control>
            </Form.Group>
            <PaginationComponent
              source={source}
              id={source}
              pages={pages}
              currentPage={currentPage}
            />
          </div>

          <div className='productsBox'>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}>
                <Spinner animation='border' id='customSpinner' />
              </div>
            ) : null}

            {!loading && itemButtons && itemButtons.length > 0 && !isMobile
              ? itemButtons.slice(currentPage * 20 - 20, currentPage * 20)
              : null}

            {!loading && itemButtons && itemButtons.length > 0 && isMobile
              ? itemButtons.slice(0, currentPage * 20)
              : null}

            {!loading && itemButtons && defaultList.length === 0 ? (
              <p><FormattedMessage id='filter.no_results' /></p>
            ) : null}

            {!loading && !itemButtons ? (
              <p><FormattedMessage id='filter.no_results' /></p>
            ) : null}

            {itemButtons && pages > 1 ? (
              <PaginationComponent
                source={source}
                id={source}
                pages={pages}
                currentPage={currentPage}
              />
            ) : null}
          </div>
          {isMobile && itemButtons && pages > 1 && currentPage !== pages ? (
            <p
              style={{
                padding: '0 10px',
                margin: 'auto auto 15px auto',
                fontSize: '13px',
              }}
              className='mobile-only'>{`${currentPage * 20} / ${itemList.length}`}</p>
          ) : null}
          {isMobile && itemButtons && pages > 1 && currentPage !== pages ? (
            <Button
              variant='black'
              style={{ padding: '10px 25px', margin: 'auto', textTransform: 'uppercase' }}
              className='mobile-only'
              onClick={() => changePage(currentPage + 1)}>
              <FormattedMessage id='filter.show_more' />
            </Button>
          ) : null}
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => ({
  product: state.product,
  recents: state.auth.recents,
  prevRecentsQuery: state.auth.prevRecentsQuery,
  favorites: state.auth.user.favorites,
  isAuthenticated: state.auth.isAuthenticated,
  utils: state.utils
})

const reduxActions = {
  actionProductGetOutlet,
  actionRecentsGet,
  actionFavoritesAdd,
}

export default connect(mapStateToProps, reduxActions)(Outlet)
