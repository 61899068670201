// React & Routing
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { actionInitPwReset } from '../../redux/actions/action.auth'

// Bootstrap
import { Form, Button } from 'react-bootstrap'

// Localization
import { FormattedMessage, useIntl } from 'react-intl'

// Styling
import './ResetPw.scss'

const ResetPw = ({ isAuthenticated, actionInitPwReset }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { urlLang } = useParams()
  const [email, setEmail] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.click()
    if (isAuthenticated) {
      navigate(`/${urlLang}`, { replace: true })
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  const validateEmail = (email) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return true
    }
    alert('Syöttämäsi sähköpostiosoite ei kelpaa.')
    return false
  }

  const onSubmit = (e) => {
    e.preventDefault()
    let validInput = validateEmail(email)
    if (validInput) {
      actionInitPwReset(email)
    }
  }

  return (
    <div className='ResetPw'>
      <Helmet>
        <title>{intl.formatMessage({ id: 'reset_pw_page.title' })} | dc-collection</title>
      </Helmet>
      <h4>
        <strong>{intl.formatMessage({ id: 'reset_pw_page.title' })}</strong>
      </h4>
      <p>
        <FormattedMessage id='reset_pw_page.hint1' />
      </p>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>
            <FormattedMessage id='contact_page.email' />
          </Form.Label>
          <Form.Control
            name='email'
            type='email'
            placeholder='...'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Button variant='black' type='submit' onSubmit={onSubmit}>
          <FormattedMessage id='contact_page.send' />
        </Button>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

const reduxActions = {
  actionInitPwReset,
}

export default connect(mapStateToProps, reduxActions)(ResetPw)
