// React & Routing
import React, { useContext, useState, useEffect } from 'react'
import LangCtx from '../../LangCtx'
import { Link, useNavigate } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import { actionLogin, actionLogout } from '../../redux/actions/action.auth'
import { actionAlert } from '../../redux/actions/action.alert'
import { actionCartDelayedJob, actionCartUpdate } from '../../redux/actions/action.cart'

// Components
import MobileMenu from '../MobileMenu/MobileMenu'
import LanguageToggle from '../LanguageToggle/LanguageToggle'

// Bootstrap
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Spinner from 'react-bootstrap/Spinner'

// Localization
import { useIntl, FormattedMessage } from 'react-intl'

//Utils
import { specifyProduct, specifyColor, specifySize } from '../../utils/getFunctions'
import proxy from '../../utils/proxy'
import getTranslation from '../../utils/alertTranslations'
import bars from '../../images/bars-solid.svg'
import eye from '../../images/eye-regular.svg'
import eyeSlash from '../../images/eye-slash-regular.svg'

// Styling
import './ToolBar.scss'

const ToolBar = ({
  getSize,
  languages,
  setLang,
  auth: { isAuthenticated, loading },
  cart: { products, totalPlusVAT, total, VAT, updated, delayedJobs },
  actionAlert,
  actionLogin,
  actionLogout,
  actionCartUpdate,
  actionCartDelayedJob,
}) => {
  const navigate = useNavigate()
  const lang = useContext(LangCtx)
  const intl = useIntl()

  const [appState, setAppState] = useState({
    email: '',
    password: '',
    customPlaceholder: '',
    loginButton: intl.formatMessage({ id: "toolbar.login" }),
    keyword: '',
  })

  const [width, setWidth] = useState(window.innerWidth)
  const [isMobile, setIsMobile] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [showSearch, setShowSearch] = useState(false)

  const { email, password, loginButton, keyword } = appState

  const onChange = (e) => setAppState({ ...appState, [e.target.name]: e.target.value })

  const submitLogin = async (e) => {
    e.preventDefault()

    actionLogin({ email, password })
  }

  function handleResize() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (loading) {
      setAppState({
        ...appState,
        loginButton: <Spinner animation='border' variant='light' />,
      })
    } else {
      setAppState({ ...appState, loginButton: intl.formatMessage({ id: "toolbar.login" }) })
    }
    // eslint-disable-next-line
  }, [loading, lang.id])

  useEffect(() => {
    if (width > 1024 && isMobile) {
      setIsMobile(false)
    } else if (width < 1025 && !isMobile) {
      setIsMobile(true)
    }
    // eslint-disable-next-line
  }, [width])

  const submitSearch = (e) => {
    e.preventDefault()
    let validated = encodeURIComponent(keyword.replace(/%/g, '~~pct~~'))
    if (validated) {
      document.activeElement.blur()
      navigate(`/${lang.id}/search/${validated}`)
    } else {
      actionAlert(getTranslation('search', 'searchError', lang.id), 'desperado')
    }
  }

  const setSearchAndScroll = (value) => {
    if (value) setShowSearch(false)
    else {
      setShowSearch(true)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const minusOneOrRemove = (prod) => {
    if (prod.amount === 1 && !delayedJobs.length) {
      let confirm = window.confirm(getTranslation('cart', 'confirmRemoveProduct', lang.id, prod.name))
      if (confirm) actionCartUpdate([{ action: 'remove', item: { id: prod.id } }], updated)
    } else {
      actionCartDelayedJob({ action: 'minus', item: { id: prod.id } }, delayedJobs)
    }
  }

  const removeProduct = (prod) => {
    let confirm = window.confirm(getTranslation('cart', 'confirmRemoveProduct', lang.id, prod.name))
    if (confirm) actionCartUpdate([{ action: 'remove', item: { id: prod.id } }], updated)
  }

  const confirmResetPopover = (
    <Popover id='confirmResetPopover'>
      <Popover.Content>
        <p><FormattedMessage id='toolbar.cart_confirm_reset' /></p>
        <Button
          variant='danger'
          onClick={() => actionCartUpdate([{ action: 'removeAll' }], updated)}>
          <FormattedMessage id='general.confirm' />
        </Button>
      </Popover.Content>
    </Popover>
  )

  const countNewAmount = (id, amount) => {
    let plusJobs = delayedJobs.filter((job) => job.item.id === id && job.action === 'plus').length
    let minusJobs = delayedJobs.filter((job) => job.item.id === id && job.action === 'minus').length
    let newAmount = amount + plusJobs - minusJobs
    return newAmount > 0 ? newAmount : 1
  }

  const cartPopover = (
    <Popover id='cartPopover'>
      <Popover.Content>
        <div className='cartItemHeader'>
          <span><FormattedMessage id='checkout.product' /></span>
          <span><FormattedMessage id='checkout.quantity' /></span>
          <span><FormattedMessage id='checkout.price' /></span>
        </div>
        <div className='cartItemContainer'>
          {products
            ? products.map((el, index) => (
                <div className='cartItem' key={index}>
                  <div className='cartItemImage'>
                    <img
                      src={`${proxy}/images/${specifyProduct(el.id)}/${specifyProduct(
                        el.id
                      )}-${specifyColor(el.id).replace('/', '_')}-thumbnail.jpg`}
                      alt={`${specifyProduct(el.id)}-${specifyColor(el.id)}`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null //estä loop
                        currentTarget.src = `${proxy}/images/placeholder.jpg`
                      }}></img>
                  </div>
                  <div className='cartItemLink'>
                    <Link to={`/product/${specifyProduct(el.id)}`}>
                      {specifyProduct(el.id)} ({getSize(specifySize(el.id))})
                    </Link>
                  </div>
                  <div className='cartItemTexts'>
                    <Col>
                      <p>
                        {delayedJobs.length
                          ? countNewAmount(products[index].id, el.amount)
                          : el.amount}{' '}
                        <FormattedMessage id='checkout.piece' />
                      </p>
                    </Col>
                    <Col>
                      <div className='plusMinusContainer'>
                        <Button
                          variant='outline-primary'
                          className='plusMinusBtn'
                          onClick={() =>
                            actionCartDelayedJob(
                              { action: 'plus', item: { id: products[index].id } },
                              delayedJobs
                            )
                          }>
                          +
                        </Button>
                        <Button
                          variant='outline-primary'
                          className='plusMinusBtn'
                          onClick={() => minusOneOrRemove(products[index])}>
                          -
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      {' '}
                      <p>
                        {el.salePrice
                          ? `${parseFloat(el.salePrice * el.amount)
                              .toFixed(2)
                              .replace('.', ',')} €`
                          : `${parseFloat((el.price * el.amount).toFixed(2))
                              .toFixed(2)
                              .replace('.', ',')} €`}
                      </p>
                    </Col>
                    <Col>
                      <Button
                        className='cartDeleteBtn'
                        variant='outline-danger'
                        onClick={() => removeProduct(el)}>
                        X
                      </Button>
                    </Col>
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className='cartBottomContainer'>
          <div className='checkoutTotal'>
            <p><FormattedMessage id='checkout.without_tax' />: {total} €</p>
            <p><FormattedMessage id='checkout.vat' /> {VAT && VAT !== '0,00' ? '25,5%': '0%'}: {VAT} €</p>
            <p>
              <strong><FormattedMessage id='checkout.total' />: {totalPlusVAT} €</strong>
            </p>
          </div>
          <div className='toCheckoutContainer'>
            <Link to='/checkout'>
              <Button
                variant='secondary'
                disabled={delayedJobs.length}
                onClick={() => document.body.click()}>
                <FormattedMessage id='toolbar.to_checkout' />
              </Button>
            </Link>
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={confirmResetPopover}
              rootClose>
              <Button variant='outline-danger'>
                <FormattedMessage id='checkout.empty' />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  )

  const [hidePw, setHidePw] = useState(true)

  const popoverLogin = (
    <Popover id='popoverLogin'>
      <Popover.Content>
        <Form onSubmit={submitLogin}>
          <Form.Group>
            <Form.Label><FormattedMessage id='toolbar.email' /></Form.Label>
            <Form.Control
              name='email'
              type='text'
              placeholder='...'
              onChange={onChange}
              autoComplete='username'
            />
          </Form.Group>

          <Form.Group>
            <Form.Label><FormattedMessage id='toolbar.password' /></Form.Label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Control
                name='password'
                type={hidePw ? 'password' : 'text'}
                placeholder='...'
                onChange={onChange}
                autoComplete='current-password'
              />
              {hidePw ? (
                <Button
                  variant='link'
                  className='pw-eye'
                  onClick={() => setHidePw(false)}
                  tabIndex='-1'>
                  <img src={eye} alt={intl.formatMessage({ id: "toolbar.show_password" })} />
                </Button>
              ) : (
                <Button
                  variant='link'
                  className='pw-eye'
                  onClick={() => setHidePw(true)}
                  tabIndex='-1'>
                  <img src={eyeSlash} alt={intl.formatMessage({ id: "toolbar.hide_password" })} />
                </Button>
              )}
            </div>
            <div style={{ marginTop: '8px' }}>
              <Link to='/reset'><FormattedMessage id='toolbar.forgot_password' /></Link>
            </div>
          </Form.Group>

          <Button variant='secondary' type='submit'>
            {loginButton}
          </Button>
        </Form>
      </Popover.Content>
    </Popover>
  )

  return (
    <>
      <MobileMenu
        isAuthenticated={isAuthenticated}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        isMobile={isMobile}
        popoverLogin={popoverLogin}
        actionLogout={actionLogout}
        languages={languages}
        lang={lang}
        setLang={setLang}
      />
      <Container className='ToolBar'>
        <Container className='titleSection'>
          <div className='titleRowCol'>
            <Button
              className='barsButton'
              variant='link'
              hidden={!isMobile}
              onClick={() => setMenuOpen(!menuOpen)}>
              <img src={bars} alt='' width='24' height='24' />
            </Button>
            <Button
              className='barsButton'
              variant='link'
              hidden={!isMobile}
              onClick={() => setSearchAndScroll(showSearch)}>
              <img
                src={`${proxy}/images/icons/search-solid-black.svg`}
                alt=''
                width='20'
                height='20'
              />
            </Button>
            <LanguageToggle languages={languages} lang={lang} setLang={setLang} />
          </div>
          <div className='titleRowCol'>
            <Link to='/'>
              <h1>
                <strong>dc-collection</strong>
              </h1>
            </Link>
          </div>
          <div className='titleRowCol'>
            <div hidden={!isAuthenticated || !isMobile}>
              <OverlayTrigger rootClose trigger='click' placement='bottom' overlay={cartPopover}>
                <Button variant='link'>
                  <img
                    src={`${proxy}/images/icons/shopping-cart-solid.svg`}
                    alt=''
                    className='toolbarIcon'
                  />
                  (
                  {products
                    ? products.reduce((accumulator, current) => accumulator + current.amount, 0)
                    : 0}
                  )
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        </Container>
        <Container id='searchBar' className='userSection' hidden={isMobile && !showSearch}>
          <Col className='col1'>
            <Form onSubmit={submitSearch}>
              <Form.Group>
                <Form.Control
                  name='keyword'
                  type='text'
                  placeholder={intl.formatMessage({ id: "toolbar.search_products" })}
                  onChange={onChange}
                />
              </Form.Group>

              <Button variant='secondary' type='submit'>
                <img
                  src={`${proxy}/images/icons/search-solid.svg`}
                  alt=''
                  className='toolbarIcon searchIcon'
                />
              </Button>
            </Form>
          </Col>

          <Col className='col2'>
            {isAuthenticated ? (
              <>
                <OverlayTrigger rootClose trigger='click' placement='bottom' overlay={cartPopover}>
                  <Button variant='link'>
                    <img
                      src={`${proxy}/images/icons/shopping-cart-solid.svg`}
                      alt=''
                      className='toolbarIcon'
                    />
                    <FormattedMessage id='toolbar.cart' /> (
                    {products
                      ? products.reduce((accumulator, current) => accumulator + current.amount, 0)
                      : 0}
                    )
                  </Button>
                </OverlayTrigger>
                <Link to='/favorites'>
                  <Button variant='link'>
                    <img
                      src={`${proxy}/images/icons/heart-solid.svg`}
                      alt=''
                      className='toolbarIcon'
                    />
                    {intl.formatMessage({ id: "toolbar.favorites" })}
                  </Button>
                </Link>
                <Link to='/account'>
                  <Button variant='link'>
                    <img
                      src={`${proxy}/images/icons/user-circle-solid.svg`}
                      alt=''
                      className='toolbarIcon'
                    />
                    <FormattedMessage id='toolbar.my_account' />
                  </Button>
                </Link>
                <Button variant='link' onClick={actionLogout}>
                  <img
                    src={`${proxy}/images/icons/sign-out-alt-solid.svg`}
                    alt=''
                    className='toolbarIcon'
                  />
                  <FormattedMessage id='toolbar.logout' />
                </Button>
              </>
            ) : (
              <>
                <Link to='/favorites'>
                  <Button variant='link'>
                    <img
                      src={`${proxy}/images/icons/heart-solid.svg`}
                      alt=''
                      className='toolbarIcon'
                    />
                    <FormattedMessage id='toolbar.favorites' />
                  </Button>
                </Link>
                <OverlayTrigger trigger='click' placement='bottom' overlay={popoverLogin}>
                  <Button variant='link'>
                    <img
                      src={`${proxy}/images/icons/sign-in-alt-solid.svg`}
                      alt=''
                      className='toolbarIcon'
                    />
                    <FormattedMessage id='toolbar.login' />
                  </Button>
                </OverlayTrigger>
              </>
            )}
          </Col>
        </Container>
      </Container>
      <div className={showSearch ? 'mobileMargin extraMargin' : 'mobileMargin'} />
    </>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
})

const reduxActions = {
  actionAlert,
  actionLogin,
  actionLogout,
  actionCartUpdate,
  actionCartDelayedJob,
}

export default connect(mapStateToProps, reduxActions)(ToolBar)
